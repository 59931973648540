import React from "react";
import { Modal, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../Redux/common/actions";
import userActions from "../../Redux/user/actions";
import { DecryptFunction } from "../../utils/cryptoFunction";
import { useTranslation } from 'react-i18next';
import { LogEvent } from "../../utils/firebase";

export default function LogoutModal() {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { showLogoutModal } = useSelector(state => state.commonReducer);

    const closeModal = () => {
        dispatch({ type: commonActions.SHOW_LOGOUT_MODAL, payload: false });
    };

    const handleLogout = () => {
        LogEvent('Logout_Clicked')
        const id = DecryptFunction(localStorage.getItem('auth_user'));
        if (id) {
            dispatch({ type: userActions.LOG_OUT_PROFILE, payload: id });
        };
    }

    return (
        <Modal
            show={showLogoutModal}
            onHide={closeModal}
            centered
            size="md"
        >
            <div className="modal_close_icons"
                onClick={closeModal}
            >
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
            </div>
            <Container className="my-5 text-center">
                <h5>{t("Do you want to Logout")}?</h5>
                <div className="d-flex justify-content-center mt-4">
                    <Button className="me-2" onClick={closeModal}>{t("No")}</Button>
                    <Button onClick={handleLogout}>{t("Yes")}</Button>
                </div>
            </Container>
        </Modal>
    )
}