import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import market_Action from "../../Redux/marketPlace/action";
import Slider from "react-slider";

export default function SidebarFilter(props) {

    const { handleCloseModal } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { servicecategory, marketPlaceSelectedLocation } = useSelector((state) => state.MarketPlaceReducer);

    const { currentLanguage } = useSelector(state => state.commonReducer);

    const [range, setRange] = useState(30);

    const parentCategories = servicecategory?.data;

    const handleCategoryClick = (categoryId, category) => {
        let marketplace = { ...marketPlaceSelectedLocation };
        marketplace['Category'] = category;
        dispatch({ type: market_Action.MARKETPLACE_SERVICES_SELECTED_LOCATION, payload: marketplace });
        dispatch({
            type: market_Action.GET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID
        });
    };

    const handleSliderChange = (e) => {
        setRange(e);
        let marketplace = { ...marketPlaceSelectedLocation };
        marketplace['Distance'] = e;
        dispatch({ type: market_Action.MARKETPLACE_SERVICES_SELECTED_LOCATION, payload: marketplace });
        dispatch({ type: market_Action.GET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID });
    };

    const handleResetFilter = () => {
        let marketplace = { ...marketPlaceSelectedLocation };
        marketplace['Distance'] = 30;
        setRange(30);
        dispatch({ type: market_Action.MARKETPLACE_SERVICES_SELECTED_LOCATION, payload: marketplace });
        dispatch({ type: market_Action.GET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID });
    };

    return (
        <div>
              <div className="market-animals-cate-filter-main-div mb-4">
                <div className="d-flex justify-content-between market-animals-cate-filter-header">
                    <div>{t("Filter By")}</div>
                    <div className="marketplace-filter-clear-div cursor-pointer" onClick={handleResetFilter}>{t("Clear all")}</div>
                </div>
                <div className="marketplace_filters_main_div">
                    <h5 className="products_list_page_filter_by_text d-flex my-4">{t("Location")} <div className="products_list_page_filter_by_text_sub_div">{t("In Km")}</div></h5>
                    <Slider
                        className="slider mt-3"
                        min={30}
                        max={500}
                        value={range}
                        onChange={handleSliderChange}
                    />
                    <div className="d-flex justify-content-between mt-3">
                        <span className="range-minandmax">{range}</span>
                        <span className="range-minandmax">500</span>
                    </div>
                </div>
            </div>
            <div className="market-animals-cate-filter-main-div">
                <div className="market-animals-cate-filter-header">{t("Categories")}</div>
                {/* eslint-disable-next-line */}
                {parentCategories?.filter((val) => {
                    if (val?.category?.toLowerCase() !== 'dairy cattle') {
                        return val;
                    }
                })?.map((category, i) =>
                    <div
                        className={`market-animals-cate-filter-category cursor-pointer ${marketPlaceSelectedLocation?.Category === category?.category && "market-place-cate-filter-active-div"}`}
                        onClick={() => handleCategoryClick(category?.id, category?.category)}
                    >
                        <div className={`market-animals-cate-filter-div`} key={i}>
                            <img
                                src={category?.image_url}
                                alt=""
                                className="market-animals-cate-filter-img"
                            />
                            <div className="market-animals-cate-filter-title">
                                {category?.language?.find(lang => lang?.language_code === currentLanguage)?.category || category?.category}
                            </div>
                        </div>
                    </div>
                )}
            </div>
          
            <div className="text-center mt-3 product-filter-submi-btn">
                <Button onClick={handleCloseModal}>{t("Submit")}</Button>
            </div>
        </div>
    )
}