import React from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export default function DisclaimerModal(props) {

    const { showModal, closeModal, agreeClick } = props;

    const { t } = useTranslation();

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            size="md"
        >
            <div className="modal_close_icons"
                onClick={closeModal}
            >
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
            </div>
            <Container className="my-4">
                <h4 className="mb-4 text-center">{t("Disclaimer")}</h4>
                <div className="disclaimer-modal-content-div">
                    <div>{t("1 :YourFarm is merely a platform to connect buyers and sellers. We are not responsible for any false claims made by the seller")}</div>
                    <div>{t("2: Ownership of the information belongs to the trader, and it is the responsibility of the buyer to cross-check the information before making a purchasing decision")}</div>
                    <div>{t("3 : YourFarm is not responsible for any mismatch in the animal details")}</div>
                    <div>{t("4 : YourFarm is not responsible for any of the following activities. If the owner of the animal(seller) didn't respond to your call. not allowing you to visit the animal. any other misbehavior.")}</div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <Button className="me-2" onClick={agreeClick}>{t("I Agree")}</Button>
                </div>
            </Container>
        </Modal>
    )
}