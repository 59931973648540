import actions from './action';
import { takeEvery, all, call, put, select } from 'redux-saga/effects'
import axios from 'axios'
import { API_URL } from '../../utils/constant';
import { DecryptFunction, EncryptFunction } from '../../utils/cryptoFunction';
import commonActions from '../common/actions';
import { toast } from 'react-toastify';


const ProductListSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_PRODUCTS_LIST, ProductList),
        yield takeEvery(actions.GET_PRODUCT_LIST_BY_CATEGORY, getProductListByCategory),
        yield takeEvery(actions.PRODUCT_FILTER, productFilter),
        yield takeEvery(actions.GET_PRODUCT_DETAILS, getProductDetails),
        yield takeEvery(actions.POST_RATEING_PRODUCTS, rateings),
        yield takeEvery(actions.GET_PRODUCTS_RATEING, GetProductsRateing),
        yield takeEvery(actions.GET_PRODUCT_FILTER, ProductFilterList),
    ]);

}

const ProductList = function* () {

    yield put({ type: commonActions.PAGE_LOADER, payload: true });
    try {

        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/products`)
        )
        yield put({
            type: actions.SET_PRODUCTS_LIST,
            payload: result?.data
        });

    } catch (error) {
        console.log("Error in catch", error)
    };
    yield put({ type: commonActions.PAGE_LOADER, payload: false });
}
const ProductFilterList = function* () {

    yield put({ type: commonActions.PAGE_LOADER, payload: true });
    try {

        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/productcategory/0?language_code=tm&state_code=tn`)
        )
        const { productFilterList } = yield select((state) => state.ProductListReducer);
        yield put({
            type: actions.SET_PRODUCT_FILTER,
            payload: result?.data
        });
        let finalList = result?.data?.data?.map((val) => {
            return {
                cattle_type: val?.category,
                language: val?.language
            }
        })
        yield put({
            type: actions.SET_PRODUCT_FILTER_LIST, payload: {
                ...productFilterList,
                Category: [...finalList],
                // PriceFilter: {
                //     Min: Math.min(...amountArray),
                //     Max: Math.max(...amountArray),
                // }
            }
        });

    } catch (error) {
        console.log("Error in catch", error)
    };
    yield put({ type: commonActions.PAGE_LOADER, payload: false });
}

const getProductListByCategory = function* (data) {
    const { payload } = data;
    yield put({ type: commonActions.PAGE_LOADER, payload: true });
    try {
        yield put({ type: actions.SET_PRODUCT_LIST_BY_CATEGORY, payload: [] });
        yield put({ type: actions.SET_PRODUCT_FILTER_LIST, payload: null });
        yield put({
            type: actions.SELECTED_PRODUCT_FILTER,
            payload: { Category: [], Price: null }
        });
        if (payload === 'feed') {
            const category = ['feed', 'supplement'];
            let Products = [];
            for (let i = 0; i < category?.length; i++) {
                const result = yield call(() =>
                    axios.get(`${API_URL}/v1/user/products/category/${category[i]}`)
                )
                Products = [...Products, ...result?.data?.data];
                // const { ProductList } = yield select((state) => state.ProductListReducer);
            }
            yield put({ type: actions.SET_PRODUCT_LIST_BY_CATEGORY, payload: [...Products] });
            yield put({ type: actions.SET_ALL_PRODUCT_LIST, payload: [...Products] });
        } else {
            const result = yield call(() =>
                axios.get(`${API_URL}/v1/user/products/category/${payload}`)
            )
            yield put({ type: actions.SET_PRODUCT_LIST_BY_CATEGORY, payload: result?.data?.data });
            yield put({ type: actions.SET_ALL_PRODUCT_LIST, payload: result?.data?.data });
        };
        const { products, productFilterList } = yield select((state) => state.ProductListReducer);
        if (products?.length > 0) {
            let cattleType = [];
            if (payload !== 'market') {
                //eslint-disable-next-line
                products?.map(val => {
                    if (cattleType?.length === 0 || !cattleType?.find(c => c?.cattle_type?.toLowerCase() === val?.cattle_type?.toLowerCase())) {
                        return cattleType.push({ cattle_type: val?.cattle_type, language: val?.language });
                    }
                });
            }
            let amountArray = products?.map(val => { return val?.amount });
            yield put({
                type: actions.SET_PRODUCT_FILTER_LIST, payload: {
                    ...productFilterList,
                    // Category: [...new Set(cattleType)],
                    PriceFilter: {
                        Min: Math.min(...amountArray),
                        Max: Math.max(...amountArray),
                    }
                }
            });
        }
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.PAGE_LOADER, payload: false });
};

const productFilter = function* (data) {

    const { payload } = data;
    yield put({ type: commonActions.PAGE_LOADER, payload: true });
    try {
        const { allProducts } = yield select((state) => state.ProductListReducer);
        if (allProducts?.length > 0) {
            let FinalArray = [];
            if (payload?.type && payload?.type === 'reset') {
                if (payload?.searchedText) {
                    //eslint-disable-next-line
                    let searchFilter = allProducts?.filter(val => {
                        if (val?.medicine_name?.toLowerCase()?.includes(payload?.searchedText?.toLowerCase()) ||
                            val?.discription?.toLowerCase()?.includes(payload?.searchedText?.toLowerCase())) {
                            return val;
                        }
                    });
                    FinalArray = [...searchFilter];
                } else {
                    FinalArray = [...allProducts];
                }
            } else {
                if (payload?.Category?.length > 0) {
                    let filteredArr = [];
                    //eslint-disable-next-line
                    allProducts?.forEach((val) => {
                        if (payload?.Category?.find(c => c?.toLowerCase() === val?.cattle_type?.toLowerCase())) {
                            filteredArr.push(val);
                        }
                    });
                    FinalArray = filteredArr.filter(value => value !== undefined);
                } else {
                    FinalArray = [...allProducts];
                }
                if (payload?.Price?.length > 0) {
                    //eslint-disable-next-line
                    FinalArray = FinalArray?.filter((val) => {
                        if (parseInt(val?.amount) >= parseInt(payload?.Price[0]) && parseInt(val?.amount) <= parseInt(payload?.Price[1])) {
                            return val;
                        }
                    });
                }
            }
            if (FinalArray?.length > 0 && payload?.searchedText) {
                //eslint-disable-next-line
                let searchFilter = FinalArray?.filter(val => {
                    if (val?.medicine_name?.toLowerCase()?.includes(payload?.searchedText?.toLowerCase()) ||
                        val?.discription?.toLowerCase()?.includes(payload?.searchedText?.toLowerCase())) {
                        return val;
                    }
                });
                FinalArray = [...searchFilter];
            };
            yield put({ type: actions.SET_PRODUCT_LIST_BY_CATEGORY, payload: FinalArray });
        };
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.PAGE_LOADER, payload: false });
};

const getProductDetails = function* (data) {

    const { payload } = data;

    yield put({ type: actions.SET_PRODUCT_DETAILS, payload: null });
    yield put({ type: commonActions.PAGE_LOADER, payload: true });

    try {
        const decryptedId = DecryptFunction(payload);
       
        if (decryptedId) {
            const result = yield call(() =>
                axios.get(`${API_URL}/v1/user/products/${decryptedId}`)
            );
            if (result?.data?.code === 200) {
                let details = { ...result?.data?.data[0] };
                let mainImg = [{ url: details?.image_url, type: "image" }];
                details['sliderImages'] = [...mainImg, ...details?.resources];
                yield put({ type: actions.SET_PRODUCT_DETAILS, payload: details });
            }
        }
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.PAGE_LOADER, payload: false });
};

const rateings = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/user/ratings`, payload));
        toast.success(result.data.message)

    } catch (error) {
        console.log("Error in catch", error);
        toast.error('Error');
    }
}
const GetProductsRateing = function* (data) {
    const { payload } = data;
    try {
        const id = DecryptFunction(payload);
        if (id) {
            const result = yield call(() =>
                axios.get(`${API_URL}/v1/user/ratings/product?ref_id=${id}`));

            yield put({
                type: actions.SET_PRODUCTS_RATEING,
                payload: result?.data
            });
        }
    } catch (error) {
        console.log("Error in catch", error);

    }
}


export default ProductListSaga;
