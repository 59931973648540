import React from "react";
import Footer from "../components/commonComponents/footer";
import Navbar from '../components/commonComponents/Navbar/Navbar';
import FaqAccordionConsulation from "../components/consultation";
export default function ConsultationPage() {

    return(
        <>
            <Navbar />
            <FaqAccordionConsulation />
            <Footer />
        </>
    )
}