import React from 'react';
import centercard2image from "../../assets/images/bannercardimages/Treatmentbanner.webp"
import centercard1image from "../../assets/images/bannercardimages/treatmentbanner-2.webp"
import centercard3image from "../../assets/images/bannercardimages/treatmentbanner-3.webp"
import treatmentcardimage1 from "../../assets/images/image 41.png"
import { Carousel, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt } from "react-icons/fa";
import { DecryptFunction } from '../../utils/cryptoFunction';
import { LogEvent } from '../../utils/firebase';


const TreatmentCard = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Carousel controls={false} style={{ background: 'none' }} interval={3000}>
                <Carousel.Item>
                    <div className="container">
                        <div className="treatment-center-card bg-light-subtle mt-4">
                            <div className="center-card-body d-flex align-items-center">
                                <div className="position-relative w-100">
                                    <div className="positioned-content">
                                        <h5 className="card-title fw-semi-bold center-card-title custom-color">{t("Increase Milk Production")}</h5>
                                        <p className="white-text responsive-text mt-2">{t('Know your animals feed requirement with our expert veterinary doctor and increase milk production upto 200 liters per lactation')}</p>
                                        <div >
                                            <button className='Team-center-card-button d-flex justify-content-center'>
                                                <div className="Team-Icon">
                                                    <FaPhoneAlt alt="Custom Icon" />
                                                </div>
                                                <span onClick={() => {
                                                    const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                                                    LogEvent('Floating_Call_Button_Clicked');
                                                    window.location.href = `tel:${phoneNumber}`;
                                                }}>Call Doctor</span>
                                            </button>
                                        </div>


                                    </div>
                                    <img src={centercard1image} className="banner-img-fluid" alt="Veterinary Service Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    {/* <div className="container">
                        <div className="treatment-center-card bg-light-subtle mt-4">
                            <div className="center-card-body d-flex align-items-center">
                                <div className="position-relative w-100">
                                    <div className="treatment-heading-content">
                                        <h5 className="card-title fw-semi-bold treatment-card-title">{t("Download YourFarm app")}</h5>
                                        <p class="white-text responsive-text">Introducing our revolutionary app designed for your cattle. Discover a curated selection of farming essentials, animal listings, treatments, and free consultations</p>
                                        <div>
                                            <button className='Team-center-card-button'><FaPhoneAlt alt="Custom Icon" className="Team-Icon" />Call Now</button>
                                        </div>
                                    </div>
                                    <img src={centercard2image} className="banner-img-fluid" alt="Veterinary Service Image" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="container">
                        <div className="treatment-center-card bg-light-subtle mt-4">
                            <div className="center-card-body d-flex align-items-center">
                                <div className="position-relative w-100">
                                    <div className="positioned-content">
                                        <h5 className="card-title fw-semi-bold center-card-title custom-color">{t("Download YourFarm app")}</h5>
                                        <p className="white-text responsive-text mt-2">{t("Introducing world's first end to end animal healthcare platform. Disease diagnosis, ethnoveterinary treatment solutions and followup till recovery from illness")}</p>
                                        <div >
                                            <button className='Team-center-card-button d-flex justify-content-center'>
                                                <div className="Team-Icon">
                                                    <FaPhoneAlt alt="Custom Icon" />
                                                </div>
                                                <span onClick={() => {
                                                    const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                                                    LogEvent('Floating_Call_Button_Clicked');
                                                    window.location.href = `tel:${phoneNumber}`;
                                                }}>Call Doctor</span>
                                            </button>
                                        </div>


                                    </div>
                                    <img src={centercard2image} className="banner-img-fluid" alt="Veterinary Service Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    {/* <div className="container">
                        <div className="treatment-center-card bg-light-subtle mt-4">
                            <div className="center-card-body d-flex align-items-center">
                                <div className="position-relative w-100">
                                    <div className="treatment-heading-content">
                                        <h5 className="card-title fw-semi-bold treatment-card-title">{t("Free Doctor consultation")}</h5>
                                        <p class="white-text responsive-text">Discover our comprehensive healthcare services for your animals: Connect with professional veterinary doctors, diagnose your animal diseases and get immediate solution for quick recovery from illness</p>
                                        <div>
                                            <button className='Team-center-card-button '><FaPhoneAlt alt="Custom Icon" className="Team-Icon" />Call Now</button>
                                        </div>
                                    </div>
                                    <img src={centercard3image} className="banner-img-fluid" alt="Veterinary Service Image" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="container">
                        <div className="treatment-center-card bg-light-subtle mt-4">
                            <div className="center-card-body d-flex align-items-center">
                                <div className="position-relative w-100">
                                    <div className="positioned-content">
                                        <h5 className="card-title fw-semi-bold center-card-title custom-color">{t("Free Doctor consultation")}</h5>
                                        <p className="white-text responsive-text mt-2">{t('Discover our comprehensive healthcare services for your animals: Connect with professional veterinary doctors, diagnose your animal diseases and get immediate solution for quick recovery from illness')}</p>
                                        <div >
                                            <button className='Team-center-card-button d-flex justify-content-center'>
                                                <div className="Team-Icon">
                                                    <FaPhoneAlt alt="Custom Icon" />
                                                </div>
                                                <span onClick={() => {
                                                    const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                                                    LogEvent('Floating_Call_Button_Clicked');
                                                    window.location.href = `tel:${phoneNumber}`;
                                                }}>Call Doctor</span>
                                            </button>
                                        </div>


                                    </div>
                                    <img src={centercard3image} className="banner-img-fluid" alt="Veterinary Service Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </Container>
    )
}

export default TreatmentCard
