import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import marketActions from '../../Redux/marketPlace/action';
import userActions from "../../Redux/user/actions";
import homeActions from "../../Redux/Home/action";
import commonActions from "../../Redux/common/actions";
import market_Action from '../../Redux/marketPlace/action'
import { CgSearch } from "react-icons/cg";
import { DecryptFunction } from "../../utils/cryptoFunction";
import SidebarFilter from "./sidebarFilter";
import List from "./list";

export default function MarketplaceLiveStockIndex() {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { marketPlaceSelectedLocation, cattleListingsSelectedFilters } = useSelector((state) => state?.MarketPlaceReducer);

    const { UserProfile } = useSelector((state) => state.AuthReducer);

    useEffect(() => {
        if (localStorage.getItem("token") && localStorage.getItem("auth_user")) {
            const decryptedId = DecryptFunction(localStorage.getItem("auth_user"));
            if (decryptedId) {
                dispatch({ type: userActions.GET_USER_ADDRESS, payload: { id: decryptedId } });
                dispatch({ type: homeActions.GET_USER_PROFILE, payload: { id: decryptedId } });
            }
        }
 
    }, []);

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    dispatch({
                        type: commonActions.GET_CURRENT_LOCATION,
                        payload: { latitude: latitude, longitude: longitude },
                    });
                },
                (error) => {
                    if (error.code === 1) {
                    } else {
                        console.error("Error getting location:", error.message);
                    }
                }
            );
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch({ type: marketActions.GET_DIARY_CATTLE_CATEGORY });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (UserProfile?.provider_id > 0) {
            dispatch({ type: marketActions.GET_SELLER_PROFILE, payload: UserProfile.provider_id });
            dispatch({ type: marketActions.GET_LISTING_DETAILS, payload: UserProfile.provider_id });
        }
        //eslint-disable-next-line
    }, [UserProfile]);

    useEffect(() => {
        callBackfunction()
        //eslint-disable-next-line
    }, [marketPlaceSelectedLocation?.Category, marketPlaceSelectedLocation?.Address]);

    const callBackfunction = () => {
        if (marketPlaceSelectedLocation?.Category && marketPlaceSelectedLocation?.Address) {
            dispatch({ type: marketActions.GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE });
        }
    };

    useEffect(() => {
        return (() => {
            dispatch({
                type: marketActions.MARKETPLACE_SERVICES_SELECTED_LOCATION,
                payload: { Address: null, Coordinates: null, type: null, Category: null, Distance: 30 }
            });
        });
        //eslint-disable-next-line
    }, []);

    const handleInputSearch = (e) => {
        let marketplace = { ...cattleListingsSelectedFilters };
        marketplace['searchedText'] = e.target.value;
        dispatch({ type: market_Action.CATTLE_LISTINGS_SELECTED_FILTERS, payload: marketplace });
        dispatch({ type: market_Action.CATTLE_LISTINGS_FILTER });
    }

    return (
        <Container className="my-5">
            <Row>
                <Col md={12} className="market_place_title_main_col">
                    <div className="page_heading_div_cls">
                        {t("Livestock Buy/Sell")}
                    </div>
                    <div className="home-search-div">
                        <input
                            type="search"
                            className="form-control home-search"
                            placeholder="Search"
                            defaultValue={cattleListingsSelectedFilters?.searchedText || ''}
                            onChange={handleInputSearch}
                        />
                        <CgSearch className="home-search-icon" />
                    </div>
                </Col>
                <Col lg={4} md={12} sm={12} className="market_place_bottom_content_col">
                    <SidebarFilter />
                </Col>
                <Col lg={8} md={12} sm={12} className="market_place_bottom_content_col">
                    <List />
                </Col>
            </Row>
        </Container>
    )
};