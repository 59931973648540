import actions from './action';
import { takeEvery, all, call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_URL } from '../../utils/constant';
import commonActions from '../common/actions';


const FarmEquipmentsSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_FARM_EQUIPMENTS, FarmEquipments)

    ]);
}


const FarmEquipments = function* () {
    // const { FarmEquipments } = yield select(state => state.FarmEquipmentsReducer);
    // if (FarmEquipments?.data?.length === 0) {
        yield put({ type: commonActions.PAGE_LOADER, payload: true });
        try {
            const result = yield call(() =>
                axios.get(`${API_URL}/v1/user/products`)
            )
            yield put({
                type: actions.SET_FARM_EQUIPMENTS,
                payload: result?.data
            });

        } catch (error) {
            console.log("Error in catch", error)
        }
    // }
    yield put({ type: commonActions.PAGE_LOADER, payload: false });
}



export default FarmEquipmentsSaga;