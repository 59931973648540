import actions from "./action";

const initialState = {
    ListAddress: [],
};

const AddressReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SET_ADDRESS_LIST:
            {
                return {
                    ...state,
                    ListAddress: action.payload
                }
            }
        default:
            return state;
    }
};

export default AddressReducer;
