import { all } from "redux-saga/effects";
import HomeSaga from "./Home/sagas";
import LiveStockSaga from "./LiveStock/sagas";
import MarketPlaceSaga from "./marketPlace/sagas";
import ProductListSaga from "./Products/sagas";
import cartSagas from "./cart/sagas";
import commonSagas from "./common/sagas";
import userSagas from "./user/sagas";
import couponSagas from "./coupon/sagas";
import checkoutSagas from "./checkout/sagas";
import FarmEquipmentsSaga from './farmEquipments/sagas'
// import CouponSaga from './coupon/sagas'
import OrderSaga from './order/sagas'
import addressSagas from "./address/sagas";
import farmSagas from "./sellerProfile/sagas";
import subscriptionSagas from "./subscription/sagas";

export default function* rootSaga() {
  yield all([
    HomeSaga(),
    LiveStockSaga(),
    ProductListSaga(),
    commonSagas(),
    cartSagas(),
    userSagas(),
    MarketPlaceSaga(),
    couponSagas(),
    checkoutSagas(),
    FarmEquipmentsSaga(),
    OrderSaga(),
    addressSagas(),
    farmSagas(),
    subscriptionSagas(),
  ]);
}
