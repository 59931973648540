import farmActions from "./action";
import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import commonActions from "../common/actions";
import marketActions from '../marketPlace/action';

const farmSagas = function* () {

    yield all([
        yield takeEvery(farmActions.GET_FARM_TYPES, getFarmList),
        yield takeEvery(farmActions.GET_BREAD_TYPES, getBreedList),
        yield takeEvery(farmActions.CREATE_SELLER_USER, createUserFarm),
        yield takeEvery(farmActions.GET_CATTLE_DISEASE, recentDisease),
        yield takeEvery(farmActions.CREATE_ANIMAL_POST, createAnimalPost),
        yield takeEvery(farmActions.UPDATE_ANIMAL_POST, updateAnimalPost),
        yield takeEvery(farmActions.GET_TO_ANIMAL_IMAGE_POST, imageFileToLink),
        yield takeEvery(farmActions.GET_ANIMAL_SHOWN, getAnimalShown),
    ])
};


const getFarmList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/farm_types`)
        );
        if (result?.data?.code === 200) {
            yield put({
                type: farmActions.SET_FARM_TYPES,
                payload: result?.data?.data
            });

        };
    } catch (err) {
        console.log(err);
    };
};

const getBreedList = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/breeds`, { params: payload })
        );

        if (result?.data?.code === 200) {
            yield put({
                type: farmActions.SET_BREAD_TYPES,
                payload: result?.data?.data
            });
        }
    } catch (err) {
        console.log(err);
    }
};

const recentDisease = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/cattle_diseases/cattle_type/${payload}`)
        );

        if (result?.data?.code === 200) {
            yield put({
                type: farmActions.SET_CATTLE_DISEASE,
                payload: result?.data?.data
            });
        }
    } catch (err) {
        console.log(err);
    }
};

function* imageFileToLink(data) {
    const { payload } = data;
    try {
        const formData = new FormData();
        formData.append('files', payload?.file);
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/user/uploadimage`, formData)
        );
        if (result?.data?.code === 200) {
            var arrayImg = payload?.image_data;

            if (arrayImg?.findIndex((e) => e?.img_type === payload?.type) === -1) {
                const imagePayload = {
                    img_url: result?.data?.data[0]?.path,
                    img_type: payload.type,
                };
                arrayImg?.push(imagePayload);
            }

            yield put({
                type: farmActions.SET_TO_ANIMAL_IMAGE_POST,
                payload: { data: arrayImg },
            });

        } else {
            console.error('Error in API response:', result?.data);
        }
    } catch (error) {
        console.error('Error during image upload:', error);
    }
}


const createUserFarm = function* (data) {
    const { payload } = data;
    const { navigate } = payload;
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/admin/serviceprovider`, payload?.data)
        );
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                show: true,
                status: result?.data?.code === 200 ? "success" : "failed",
                message: result?.data?.message,
            },
        });
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        if (payload?.page === 'AnimalPost') {
            navigate('/Animal-add');
        } else {
            navigate('/user-profile?page=Seller')
        }
        yield put({ type: marketActions.SHOW_SELLER_REGISTRATION_MODAL, payload: { isOpen: false, page: null } });
        yield put({
            type: marketActions.GET_SELLER_PROFILE,
            payload: result?.data?.data?.id,
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, message: null, status: null },
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const createAnimalPost = function* (data) {
    const { payload, images, navigate } = data;
    try {
        let imagesUrls = [...images];
        for (let i = 0; i < images?.length; i++) {
            let formData = new FormData();
            formData.append('files', images[i]?.file);
            const result = yield call(() =>
                axios.post(`${API_URL}/v1/user/uploadimage`, formData)
            );
            imagesUrls[images[i].type] = result?.data?.data[0]?.path;
        }
        let tempPayload = { ...payload, cattle: { ...payload?.cattle, ...imagesUrls } };
        const response = yield call(() =>
            axios.post(`${API_URL}/v1/admin/cattle_listings`, tempPayload)
        );
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                show: true,
                status: response?.data?.code === 200 ? "success" : "failed",
                message: response?.data?.message,
            },
        });
        if (response?.data?.code === 200) {
            navigate('/user-profile?page=Seller')
        };
    } catch (err) {
        console.log(err);
    }
};

const updateAnimalPost = function* (data) {
    const { payload, images, id, navigate } = data;
    try {
        // let imagesUrls = [];
        // for (const el of images) {
        //     if (el?.file?.name) {
        //         let formData = new FormData();
        //         formData.append('files', el?.file);

        //         // Make a POST request to the API endpoint for uploading images
        //         const result = yield call(() =>
        //             axios.post(`${API_URL}/v1/user/uploadimage`, formData)
        //         );
        //         imagesUrls[el.type] = result?.data?.data[0]?.path;
        //     } else {
        //         imagesUrls[el.type] = el.url    
        //     }               
        // }
        let imagesUrls = [...images];
        for (let i = 0; i < images?.length; i++) {
            let formData = new FormData();
            formData.append('files', images[i]?.file);
            const result = yield call(() =>
                axios.post(`${API_URL}/v1/user/uploadimage`, formData)
            );
            imagesUrls[images[i].type] = result?.data?.data[0]?.path;
        }

        let tempPayload = { ...payload, cattle: { ...payload.cattle, ...imagesUrls } };

        const response = yield call(() =>
            axios.put(`${API_URL}/v1/admin/cattle_listings/${id}`, tempPayload)
        );
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                show: true,
                status: response?.data?.code === 200 ? "success" : "failed",
                message: response?.data?.message,
            },
        });
        if (response?.data?.code === 200) {
            navigate('/user-profile?page=Seller')
        };
    } catch (err) {
        console.log(err);
    }
};


const getAnimalShown = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/admin/cattle_listings/${payload}`)
        );
        if (result?.data?.code === 200) {
            yield put({
                type: farmActions.SET_ANIMAL_SHOWN,
                payload: result?.data?.data[0]
            });
        }
    } catch (err) {
        console.log(err);
    }
};




export default farmSagas;