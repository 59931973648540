import farmActions from "./action";

const initialState = {
    farmList: [],
    breadList: [],
    CurrentselectedAddress: [],
    recentDisease: [],
    createAnimal: [],
    setanimalimage:[],
    editanimalpost:[],
};
const farmTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case farmActions.SET_FARM_TYPES:
            return {
                ...state,
                farmList: action.payload
            }
        case farmActions.SET_BREAD_TYPES:
            return {
                ...state,
                breadList: action.payload
            }
            case farmActions.SET_SELECTED_ADDRESS:
                return {
                    ...state,
                    CurrentselectedAddress:action.payload
                }
            case farmActions.SET_CATTLE_DISEASE:
                return {
                    ...state,
                    recentDisease:action.payload
                }
                case farmActions.SET_ANIMAL_ADD_POST:
                    return{
                        ...state,
                        createAnimal:action.payload
                    }
                case farmActions.SET_TO_ANIMAL_IMAGE_POST:
                    return{
                        ...state,
                        setanimalimage:action.payload
                    }
                case farmActions.SET_EDIT_ANIMAL:
                    return{
                        ...state,
                        editanimalpost:action.payload
                    }
                case farmActions.SET_ANIMAL_SHOWN:
                    return{
                        ...state,
                        animallistShow:action.payload
                    }
           
        default:
            return state;
    }
};

export default farmTypeReducer;