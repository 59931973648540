import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import homeActions from '../../Redux/Home/action';
import NoImage from '../../assets/images/noImage.jpg';
import { LogEvent } from '../../utils/firebase';

const BlogList = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const { homeContent } = useSelector(state => state.AuthReducer);

  let [blogList, setBlogList] = useState([]);
  // console.log("check-01",blogList)
  const { currentLanguage } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    dispatch({ type: homeActions.GET_HOME_CONTENTS });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (homeContent?.length > 0) {
      //eslint-disable-next-line
      setBlogList(homeContent.filter(val => val?.type === 'blog')?.slice(0, 10));
    }
  }, [homeContent]);

  return (
    <>
      <Container className='my-5'>
        <div className="mb-4">
          <div className="page_heading_div_cls">
            {t("Blogs")}
          </div>
        </div>
     
<div className='container'>
  {blogList?.length > 0 ? (
    <Row className="justify-content-center">
      {blogList.map((blog) => (
        <Col key={blog.id} md={10} className="mb-4">
          <Card className='blog-card-section'>
            <Row noGutters>
              <Col md={4}>
                <Card.Img variant="top" src={blog.img_url || NoImage} alt={`Blog ${blog.id}`} className="blog-image" />
              </Col>
              <Col md={8}>
                <Card.Body className='blog-page-card-body'>
                  <a href={blog.link} target="_blank" rel="noopener noreferrer">
                    <h5 className="mb-3 blog-title">
                      {blog.language.find(lang => lang.language_code === currentLanguage)?.title}
                    </h5>
                    <p className="text-center mt-3" style={{color:'#232c63'}}>
                    {t("Posted On")}: {formatDate(blog.created_at)}
                    </p>
                  </a>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  ) : (
    <div className="h5 my-5 text-center">{t("No Data Found")}</div>
  )}
</div>
       
      </Container>
    </>
  );
};

export default BlogList;
