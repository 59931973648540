import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from "react-router-dom";
import { SlClose } from "react-icons/sl";
import TopNavbar from "../commonComponents/Navbar/Navbar";
import Footer from "../commonComponents/footer";
import StepperComponent from "./stepper";

export default function PaymentFailed() {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    return (
        <div>
            <TopNavbar />
            <Container className="my-5">
                <Row className="my-4">
                    <Col md={12} className="center-text">
                        <StepperComponent currentStep={3} />
                        <SlClose className="failed-icon" style={{ marginTop: "60px" }} />
                        <p className="failed-message">{t("Order Failed")}!</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Sit ipsum<br />
                            et ullamcorper dolor placerat sapien risus curabitur</p>
                        <button className="checkout-response-page-btn-cls" onClick={() => {
                            if (page && page === 'subscription') {
                                navigate('/user-profile?page=Seller')
                            } else {
                                navigate('/cart')
                            }
                        }}>{t("Try again")}</button>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}