import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
// import TreatmentDocPage from './doctortreatment';
import TreatmentCard from './consultationtopcard';
import TeamListPage from './CommonDiseases'
import { PiChatText } from "react-icons/pi";
import homeActions from '../../../Redux/Home/action';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TopNavbar from '../../commonComponents/Navbar/Navbar';
import Footer from '../../commonComponents/footer';
const FaqAccordionConsulation = () => {

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();



  useEffect(() => {
    dispatch({ type: homeActions.GET_HOME_CONTENTS });
  }, []);

 

  return (
    <>
    <TopNavbar/>
      <div className="treatment-page-container">
        <TreatmentCard />
        <div style={{ backgroundColor: 'white' }}>
        <TeamListPage />
        {/* <TreatmentDocPage/> */}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default FaqAccordionConsulation;
