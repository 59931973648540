import React from 'react';
import treatmentcardimage1 from "../../../assets/images/bannercardimages/teambanner.webp"
import { Carousel, Container } from 'react-bootstrap';
import { IoCall } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt } from "react-icons/fa";
import { DecryptFunction } from '../../../utils/cryptoFunction';
import { LogEvent } from '../../../utils/firebase';
const TreatmentCard = () => {
    const { t } = useTranslation();

    return (
        
        <div className="background-custom-color">
            <Container>
                <div className="container">
                        <div className="center-card-body d-flex align-items-center">
                            <div className="position-relative w-100">
                                <div className="positioned-content">
                                    <h5 className="card-title fw-semi-bold center-card-title custom-color">YourFarm Vision & Mission</h5>
                                    <p className="white-text responsive-text mt-2">{t('Empowering dairy farmers with accessible and affordable animal healthcare and nutrition')}</p>
                                </div>
                                <img src={treatmentcardimage1} className="banner-img-fluid-ourteam" alt="Veterinary Service Image" />
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </Container>
        </div>

    )
}

export default TreatmentCard
