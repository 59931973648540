import couponActions from "./actions";
import { takeEvery, put, call, all, select } from "redux-saga/effects";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import { DecryptFunction } from "../../utils/cryptoFunction";
import moment from "moment-timezone";

const couponSagas = function* () {
    yield all([
        yield takeEvery(couponActions.GET_COUPON_BY_MOBILENUMBER, getCouponByUserID),
        yield takeEvery(couponActions.GET_COUPON_SHOW,ShowCoupon)
    ])
};

const getCouponByUserID = function* (data) {

    const { payload } = data;

    try {
        const mobile_no = DecryptFunction(payload);
        if (mobile_no) {
            const result = yield call(() =>
                axios.get(`${API_URL}/v1/user/coupon_redemptions/users/${mobile_no}`)
            );
            yield put({
                type: couponActions.SET_COUPON_BY_MOBILENUMBER,
                payload: result?.data?.data
            });
            const { cartProducts } = yield select((state) => state.cartReducer);
            if (cartProducts?.order_items?.length > 0) {
                let priceObj = { amount: 0, discount: 0, total: 0 }
                //eslint-disable-next-line
                cartProducts?.order_items?.map((val) => {
                    let product_rate = parseFloat(val?.rate) * parseInt(val?.quantity)
                    priceObj['amount'] = parseFloat(priceObj['amount']) + parseFloat(product_rate);
                    let product_dis = parseFloat(val?.discount) * parseInt(val?.quantity)
                    priceObj['discount'] = parseFloat(priceObj['discount']) + parseFloat(product_dis);
                });
                priceObj['total'] = priceObj['amount'] - priceObj['discount'];
                //eslint-disable-next-line
                const validCoupons = result?.data?.data?.filter((val) => {
                    if (moment(val?.expires_at).diff(moment()) > 0 && parseInt(priceObj?.total) > parseInt(val?.minimum_amount)) {
                        return val;
                    }
                });
                yield put({ type: couponActions.SET_AVAILABLE_COUPON_LIST, payload: validCoupons || [] });
            }
        }
    } catch (err) {
        console.log(err);
    };
};

const ShowCoupon = function* (data) {
    const { payload } = data;
    try {

        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/coupon_redemptions/users/${payload}`)
        )
        yield put({
            type: couponActions.SET_COUPON_SHOW,
            payload: result?.data
        });

    } catch (error) {
        console.log("Error in catch", error)
    }
}

export default couponSagas;
