import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBh8DhklyjBFW0w8Bdk_SA9KiAmhU2gZ0M",
  authDomain: "yourfarm-website.firebaseapp.com",   
  databaseURL: "https://yourfarm-website.firebaseio.com",
  projectId: "yourfarm-website",
  storageBucket: "yourfarm-website.appspot.com",
  messagingSenderId: "473050824681",
  appId: "1:473050824681:web:1d0b038fb2cb687e469578",
  measurementId: "G-ZC2EW5RBDT"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const LogEvent = (eventName) => {
  logEvent(analytics, eventName)
};

export default analytics;