import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaEdit, FaUserAlt, FaUserEdit } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import homeActions from "../../Redux/Home/action";
import market_Action from "../../Redux/marketPlace/action";
import image1 from "../../assets/images/sellingprofile1.jpg";
import { DecryptFunction } from "../../utils/cryptoFunction";
import TopNavbar from "../commonComponents/Navbar/Navbar";
import Footer from "../commonComponents/footer";
import NOimage from "../../assets/images/noImage.jpg"
import { useNavigate } from 'react-router-dom';
import SellerRegistrationModal from "./sellerRegistrationModal";

const Sellingprofile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showEditListingModal, setShowEditListingModal] = useState(false);

  const { sellerprofile, listingdetails, getlistingstatus } = useSelector(
    (state) => state.MarketPlaceReducer
  );
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
  };

  const { UserProfile } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    if (localStorage.getItem("auth_user")) {
      const user = DecryptFunction(localStorage.getItem("auth_user"));
      dispatch({
        type: homeActions.GET_USER_PROFILE,
        payload: {id: user},
      });
    }
    dispatch({
      type: market_Action.GET_LISTING_STATUS,
    });
  }, []);


  const handleAnimalEditClick = (listingId) => {
    navigate('/Animal-edit', {
      state: listingId
      // state: { listingId: listingId }
    });
  };


  const adstatus = getlistingstatus?.data;

  useEffect(() => {
    if (UserProfile.provider_id > 0) {
      dispatch({
        type: market_Action.GET_SELLER_PROFILE,
        payload: UserProfile.provider_id,
      });
      dispatch({
        type: market_Action.GET_LISTING_DETAILS,
        payload: UserProfile.provider_id,
      });
    }
  }, [UserProfile]);

  const sellers = sellerprofile?.data;

  const handleEditProfileClick = () => {
    setShowEditProfileModal(true);
  };

  const handleCloseEditProfileModal = () => {
    setShowEditProfileModal(false);
  };

  const handleEditListingClick = () => {
    setShowEditListingModal(true);
  };

  const handleCloseEditListingModal = () => {
    setShowEditListingModal(false);
  };

  const listings = listingdetails?.data;

  return (
    <>
      <TopNavbar />
      <div className="container mt-3 mb-3">
        <div>
          <h4 style={{ color: "#dc7f92" }}>Seller Details</h4>
        </div>
        {sellers && sellers.map((seller, i) =>
          <Row xs={12} md={12} lg={12} className="mb-3 card flex flex-row p-3">
            <Col md={3} sm={12}>
              <div className="mb-2 mt-2 cursor-pointer ">
                <Card.Img
                  src={seller.logo ? seller.logo : NOimage}
                  className="w-50 img-thumbnail"
                />
              </div>
            </Col>
            <Col md={9} sm={12}>
              <div className="mb-2 cursor-pointer ">
                <div className="d-flex">
                  <div className="col-md-8 col-sm-8 col-8">
                    <div>
                      <Card.Text style={{ fontSize: "20px" }}>
                        <FaUserAlt />
                        <strong style={{ color: "#232c63" }}>
                          &nbsp;{seller.provider_name}
                        </strong>
                      </Card.Text>
                      <Card.Text style={{ fontSize: "20px" }}>
                        <FaLocationDot />
                        <strong style={{ color: "#232c63" }}>
                          &nbsp;{seller.address.city}, &nbsp; {seller.address.state}
                        </strong>
                      </Card.Text>
                      <Card.Text style={{ fontSize: "20px" }}>
                        <HiDevicePhoneMobile />
                        <strong style={{ color: "#232c63" }}>
                          &nbsp;{seller.mobile_no}
                        </strong>
                      </Card.Text>
                      <Card.Text style={{ fontSize: "20px" }}>
                        <BsFillEnvelopeFill />
                        <strong style={{ color: "#232c63" }}>
                          &nbsp;{seller.email ? seller.email : "Email Id doesn't exist"}
                        </strong>
                      </Card.Text>
                    </div>
                  </div>
                  <Col sm={3}>
                    <FaUserEdit
                      size={40}
                      title="Edit Profile"
                      className="position-absolute top-0 end-0 cursor-pointer"
                      style={{ color: "#232c63" }}
                      onClick={handleEditProfileClick}
                    />
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        )}



        <Modal show={showEditListingModal} onHide={handleCloseEditListingModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Listing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form className="custom-form">
                <Row md={12}>
                  {/* Image Upload */}
                  <Col md={4}>
                    <Card.Img
                      src="https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?size=626&ext=jpg&ga=GA1.1.624859554.1704430351&semt=ais"
                      className="w-100 img-thumbnail"
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    />
                  </Col>
                  <Col md={8}>
                    <div className="mb-3 mt-4">
                      <label htmlFor="formFile" className="form-label">
                        Default file input example
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                      />
                    </div>
                  </Col>
                </Row>
              </Form>

              <Row md={12}>
                <Form className="custom-form">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Location"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword2">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputPassword2"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword3">Email Address</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputPassword3"
                      placeholder="Email Address"
                    />
                  </div>
                </Form>
              </Row>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditListingModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCloseEditListingModal}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Row className="seller-tabs-container">

          <Tab.Container id="seller-tabs" defaultActiveKey="tab1">
            <Row>
              <Col sm={12}>
                <Nav fill variant="tabs" className="flex-row seller-tabs">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab1"
                      onClick={() => handleTabClick("tab1")}
                    >
                      Draft
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab2"
                      onClick={() => handleTabClick("tab2")}
                    >
                      Review
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab3"
                      onClick={() => handleTabClick("tab3")}
                    >
                      Published
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab4"
                      onClick={() => handleTabClick("tab4")}
                    >
                      Sold Out
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="tab5"
                      onClick={() => handleTabClick("tab5")}
                    >
                      Expired
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              <Col sm={12} md={4} className="mt-3 p-2">
                <Tab.Content>
                  {listings &&
                    listings.map((listing, i) => (
                      <Tab.Pane
                        key={i}
                        eventKey={`tab${listing.listing.listing_status}`}
                      >
                        <Card>
                          <FaEdit
                            className="position-absolute top-0 end-0 cursor-pointer"
                            size={30}
                            style={{ color: "#232c63" }}
                            title="Edit Listing Details"
                            onClick={handleEditListingClick}
                          />
                          <button onClick={() => handleAnimalEditClick(listing.listing.id)}>

                            <FaEdit
                              size={30}
                              style={{ color: "#232c63" }}
                              title="Edit Listing Details"
                            />
                          </button>
                          <Card.Img
                            src={listing.cattle.image_front_url ? listing.cattle.image_front_url : NOimage}
                            className="img-thumbnail w-50 seller-cattle-image"
                          />
                          <Card.Title className="seller-card-title">{listing.listing.title}</Card.Title>
                          <Card.Text className="seller-card-text">{listing.listing.description}</Card.Text>
                          <Card.Text className="seller-card-text"><strong>Price</strong>:₹{listing.listing.rate}</Card.Text>
                        </Card>
                      </Tab.Pane>
                    ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default Sellingprofile;
