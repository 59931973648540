import checkoutActions from "./actions";

const initialState = {
    deliveryType: [],
    paymentType: [],
    checkoutValues: {},
    orderSuccessModal: {
        isOpen: false,
        status: null
    },
    htmlPayload: null,
    decoderResponse: null,
};

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case checkoutActions.SET_DELIVERY_TYPE_LIST:
            return {
                ...state,
                deliveryType: action.payload
            }
        case checkoutActions.CHECKOUT_VALUES:
            return {
                ...state,
                checkoutValues: action.payload
            }
        case checkoutActions.SET_PAYMENT_TYPE_LIST:
            return {
                ...state,
                paymentType: action.payload
            }
        case checkoutActions.SHOW_ORDER_SUCCESS_MODAL:
            return {
                ...state,
                orderSuccessModal: action.payload
            }
        case checkoutActions.INITIATE_CCAVENUE_TRANSACTION_SUCCESS:
            return {
                ...state,
                htmlPayload: action.payload
            }
        case checkoutActions.DECODER_RESPONSE:
            return {
                ...state,
                decoderResponse: action.payload
            }
        default:
            return state;
    }
};

export default checkoutReducer;