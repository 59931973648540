import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import market_Action from "../../../Redux/marketPlace/action";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import homeActions from '../../../Redux/Home/action';
import PageLoader from "../../../common/pageLoader";

const CommonDiseasesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { homeContent } = useSelector(state => state.AuthReducer);
  const { currentLanguage, pageLoader } = useSelector(state => state.commonReducer);

  let [doctors, setDoctors] = useState([]);
  useEffect(() => {
    dispatch({
      type: market_Action.GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE, payload: 1
    });
    dispatch({ type: homeActions.GET_HOME_CONTENTS });
  }, [dispatch]);

  useEffect(() => {
    if (homeContent?.length > 0) {
      setDoctors(homeContent?.filter(val => {
        if (val?.type === 'doctor' && (val?.img_url && val?.title)) {
          return val;
        }
      }))
    }
  }, [homeContent]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className="container-fluid doc-equipments mt-5 ms-0">
        <Container>
          <div className="page_heading_div_cls">
            {t("Our Expert Doctor's")}
          </div>
        </Container>
      </div>
      {pageLoader && <PageLoader />}
      <div className="container">
        {doctors?.length > 0 ? (
          <Slider {...settings}>
            {doctors?.map((doctor, i) => (
              <div key={i}>
                <div className="our-team-card-cls mx-auto my-auto mt-2">
                  <a href={doctor.link} target="_blank" rel="noopener noreferrer" className="d-block">
                    <div className="d-flex">
                      <div className="w-50 img-box">
                        <img
                          src={doctor.img_url}
                          alt={`Doctor ${doctor.title}`}
                          className="out-doctor-card-img-cls my-auto"
                        />
                      </div>
                      <div className="w-50 d-flex flex-column justify-content-center">
                        <div className="out_doctors_name_designation_experience_div">
                          <div className="our-team-card-name">
                            {doctor.language.find(lang => lang.language_code === currentLanguage)?.title}
                          </div>
                          <div className="out-doctor-designation-cls">
                            {doctor.language.find(lang => lang.language_code === currentLanguage)?.contents[0]?.designation}
                          </div>
                          <div className="out-doctor-content-col">
                            {doctor.language.find(lang => lang.language_code === currentLanguage)?.contents[0]?.experience}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="h5 my-5 text-center">{t("No Data Found")}</div>
        )}
      </div>
    </>
  );
};

export default CommonDiseasesPage;
