import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "mdb-react-ui-kit/dist/css/mdb.min.css";
import CustomRouters from "./router";
import { Provider } from "react-redux";

import setAuthToken from "./utils/setAuthToken";
import { store } from "./Redux/Store";
// import LoginModal from "./components/modal/LoginModal";
// import { useEffect } from "react";

import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyAwwnElb-6Y54JcBt8drureB30SL-LqCYU",
  // authDomain: "yourfarm-website-new.firebaseapp.com",
  // projectId: "yourfarm-website-new",
  // storageBucket: "yourfarm-website-new.appspot.com",
  // messagingSenderId: "650154840448",
  // appId: "1:650154840448:web:fda9d7b81d0352f9423165",
  // measurementId: "G-QQY878H6EW",

  apiKey: "AIzaSyBh8DhklyjBFW0w8Bdk_SA9KiAmhU2gZ0M",
  authDomain: "yourfarm-website.firebaseapp.com",
  databaseURL: "https://yourfarm-website.firebaseio.com",
  projectId: "yourfarm-website",
  storageBucket: "yourfarm-website.appspot.com",
  messagingSenderId: "473050824681",
  appId: "1:473050824681:web:1d0b038fb2cb687e469578",
  measurementId: "G-ZC2EW5RBDT",
};

// Initialize Firebase
// const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
// const analytics = getAnalytics(app);

const alreadyCreatedAps = getApps();
const app =
  alreadyCreatedAps.length === 0
    ? initializeApp(firebaseConfig, "yourfarm-website")
    : alreadyCreatedAps[0];

const analytics = getAnalytics(app);

if (localStorage.getItem("token")) {
  setAuthToken(`Bearer ${localStorage.getItem("token")}`);
} else {
  setAuthToken(`Bearer ${sessionStorage.getItem("temp_Token")}`);
}

function App() {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!localStorage.getItem("loginModal")) {
  //     localStorage.setItem("loginModal", "false");
  //     dispatch({ type: commonActions.SET_SHOW_LOGIN_MODAL, payload: true });
  //   };
  //   //eslint-disable-next-line
  // }, []);

  return (
    <Provider store={store}>
      {/* <LoginModal /> */}
      <CustomRouters />
    </Provider>
  );
}

export default App;
