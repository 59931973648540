import React, { useEffect, useState } from 'react';
import TreatmentDocPage from './doctortreatment';
import TreatmentCard from './consultationtopcard';
import TeamListPage from './CommonDiseases'
import Farmertag from './Yourfarmfarmer';
import Partnertag from './Our_Partners';
import homeActions from '../../../Redux/Home/action';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TopNavbar from '../../commonComponents/Navbar/Navbar';
import Footer from '../../commonComponents/footer';
import DocListPage from '../../../components/AboutUs/OurDoctor/CommonDiseases'
const FaqAccordionConsulation = () => {

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();



  useEffect(() => {
    dispatch({ type: homeActions.GET_HOME_CONTENTS });
  }, []);



  return (
    <>
      <TopNavbar />
      <div className="treatment-page-container">
        <TreatmentCard />
        <TeamListPage />
        {/* <DocListPage/> */}
        <Partnertag />
        <div style={{ margin: '2rem 0' }}></div>
        <Farmertag />
        <div style={{ margin: '2rem 0' }}></div>
        <TreatmentDocPage />
      </div>
      <div style={{ margin: '2rem 0' }}></div>
      <Footer />
    </>
  );
};

export default FaqAccordionConsulation;
