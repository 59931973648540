import React from 'react';
import { Carousel } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import productimage1 from "../../../assets/images/new_Farmer_images/Image 1.png";
import productimage2 from "../../../assets/images/new_Farmer_images/image 2.png";
import productimage3 from "../../../assets/images/new_Farmer_images/Image 3.png";
import productimage4 from "../../../assets/images/new_Farmer_images/Image 4.png";
import productimage5 from "../../../assets/images/new_Farmer_images/Image 5.png";
import productimage6 from "../../../assets/images/new_Farmer_images/Image 7.png";
import productimage7 from "../../../assets/images/new_Farmer_images/Image 10.png";
import productimage8 from "../../../assets/images/new_Farmer_images/Image 11.png";
import productimage9 from "../../../assets/images/new_Farmer_images/Image 13.png";

const Farmertag = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); 

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    rtl: true, // Enable right-to-left sliding
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  const products = [
    { id: 1, image_url: productimage1 },
    { id: 2, image_url: productimage2 },
    { id: 3, image_url: productimage3 },
    { id: 4, image_url: productimage4 },
    { id: 5, image_url: productimage5 },
    { id: 6, image_url: productimage6 },
    { id: 7, image_url: productimage7 },
    { id: 8, image_url: productimage8 },
    { id: 9, image_url: productimage9 }
  ];

  return (
    <Carousel controls={false} interval={null}>
      <Carousel.Item>
        <div className='container-fluid' style={{ backgroundColor: '#FFF3F5', padding: '2rem 0' }}>
          <div className='container'>
            <div className="New_achievement_div_cls">
              {t("Our Field Activities")}
            </div>
            <div className='treatment-page-container'>
              <Slider {...settings}>
                {products.map((product) => (
                  <div key={product.id}>
                    <img className='Team-Grid-img' src={product.image_url} alt={`Product ${product.id}`} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default Farmertag;
