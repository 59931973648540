//dev server backend url
export const API_URL = "https://api2.yourfarm.co.in";
// export const API_URL = "https://test.yourfarm.co.in";

export const GOOGLE_API = "https://maps.googleapis.com/";
export const GOOGLE_MAPS_API_KEY = "AIzaSyBbmnBQN9_tONY35MVvLRGeFS6GJQWgY0A";
// export const CCAVANUE_TEST_ACCESS_CODE = 'AVGP05LA45AS07PGSA'; //localhost server access code

//test server access code
// export const CCAVANUE_TEST_ACCESS_CODE = "AVFP05LA45AS06PFSA";

//live server access code
export const CCAVANUE_TEST_ACCESS_CODE = "AVKH72KE13CN83HKNC";

//test
// export const CCAVANUE_PAYMENT_GATEWAY_REDIRECT_URL = 'https://test.ccavenue.com/transaction/transaction.do';

//live
export const CCAVANUE_PAYMENT_GATEWAY_REDIRECT_URL =
  "https://secure.ccavenue.com/transaction/transaction.do";
// export const FRONTEND_URL='http://test.yourfarm.co.in:3005';
export const FRONTEND_URL = "https://yourfarm.co.in";
// export const BILLING_EMAIL='pavithran.s@praathee.com';
export const BILLING_EMAIL = "admin@yourfarm.co.in";
