import actions from "./action";

const initState = {
    Orderlist: [],
    orderDetails: null,
}

const OrderReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_ORDER_LIST:
            {
                return {
                    ...state,
                    Orderlist: action.payload
                }
            }
        case actions.SET_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: action.payload
            }
        default: return state;
    }
}
export default OrderReducer;