import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import NoImage from '../../assets/images/noImage.jpg';
import { LogEvent } from "../../utils/firebase";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";

const BlogPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { homeContent } = useSelector(state => state.AuthReducer);
  const [blogList, setBlogList] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  useEffect(() => {
    if (homeContent?.length > 0) {
      const filteredBlogs = homeContent.filter(val => val?.type === 'blog').slice(3, 10);
      setBlogList(filteredBlogs);
      setTotalSlides(filteredBlogs.length);
    }
  }, [homeContent]);

  const handleViewMore = () => {
    LogEvent('Home_Blogs_ViewAll');
    navigate('/blog');
  };

  const handleAfterChange = (current) => {
    setCurrentSlide(current);
  };

  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    autoplay: true, // Enable auto-slide
    autoplaySpeed: 4000,
    speed: 500,
    dots: true,
    focusOnSelect: true,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: currentSlide < totalSlides - 1 ? <MdOutlineArrowForwardIos /> : null,
    prevArrow: <MdOutlineArrowBackIos />,
  };

  return (
    <div className="container-fluid farm-equipments" style={{ backgroundColor: "#F6F7FF" }}>
      <div className="container">
        <div style={{ textAlign: 'center', fontSize: '22px', color: '#232c63', margin: '20px 0' }}>
          {t("Blogs")}
        </div>
        <div className="mt-3">
          {blogList.length > 0 ? (
            <Slider {...settings} className="center-slider">
              {blogList.map((blog, index) => (
                <div key={blog.id} className="blog-card-wrapper">
                  <a href={blog.link} target="_blank" rel="noopener noreferrer" className="mt-2" onClick={() => LogEvent('Home_Blogs_Details')}>
                    <div className="blog-card">
                      <img
                        src={blog.img_url || NoImage}
                        alt="Blog"
                        className="blogpageimg"
                      />
                    </div>
                  </a>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="h5 my-3 text-center">{t('No Data Found')}</div>
          )}
        </div>
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <div>
            <button
              className="btn btn-primary"
              style={{ backgroundColor: '#007bff', borderColor: '#007bff', color: '#fff', position: 'relative' }}
              onClick={handleViewMore}
            >
             {t('View More')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
