import couponActions from "./actions";

const initialState = {
    couponList: [],
    available_Coupons: [],
    ShowCoupon: [],
};

const couponReducer = (state = initialState, action) => {

    switch (action.type) {
        case couponActions.SET_COUPON_BY_MOBILENUMBER:
            return {
                ...state,
                couponList: action.payload
            }
        case couponActions.SET_AVAILABLE_COUPON_LIST:
            return {
                ...state,
                available_Coupons: action.payload
            }
        case couponActions.SET_COUPON_SHOW:
            {
                return {
                    ...state,
                    ShowCoupon: action.payload
                }
            }
        default:
            return state;
    }
};

export default couponReducer;
