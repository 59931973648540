const checkoutActions = {
    GET_DELIVERY_TYPE_LIST: 'GET_DELIVERY_TYPE_LIST',
    SET_DELIVERY_TYPE_LIST: 'SET_DELIVERY_TYPE_LIST',
    SET_PAYMENT_TYPE_LIST: 'SET_PAYMENT_TYPE_LIST',
    CALCUATE_GST: 'CALCUATE_GST',
    CHECKOUT_VALUES: 'CHECKOUT_VALUES',
    CALCULATE_SHIPPING: 'CALCULATE_SHIPPING',
    CREATE_NEW_ORDER: 'CREATE_NEW_ORDER',
    CREATE_ORDER_NUMBER: 'CREATE_ORDER_NUMBER',
    PAYMENT_ENCODER: 'PAYMENT_ENCODER',
    PAYMENT_DECODER: 'PAYMENT_DECODER',
    PAYMENT_VERIFY_COD: 'PAYMENT_VERIFY_COD',
    UPDATE_USER_COUPON: 'UPDATE_USER_COUPON',
    APPLY_ORDER_COUPON: 'APPLY_ORDER_COUPON',
    PAYMENT_VERIFY_ONLINE: 'PAYMENT_VERIFY_ONLINE',
    CCAVENUE_PAYMENT_GATEWAY: 'CCAVENUE_PAYMENT_GATEWAY',
    SHOW_ORDER_SUCCESS_MODAL: 'SHOW_ORDER_SUCCESS_MODAL',

    INITIATE_CCAVENUE_TRANSACTION_SUCCESS: 'INITIATE_CCAVENUE_TRANSACTION_SUCCESS',
    DECODER_RESPONSE: "DECODER_RESPONSE"
};

export default checkoutActions;