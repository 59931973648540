// import React, { useState } from "react";
// import { Button, Container, Modal } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import commonActions from "../../Redux/common/actions";
// import farmActions from "../../Redux/sellerProfile/action";
// import { useTranslation } from 'react-i18next';

// export default function SellerSelectAddressModal(props) {

//     const { t } = useTranslation();

//     const dispatch = useDispatch();

//     const { showModal, closeModal, handleAddress } = props;

//     const { userAddress } = useSelector(state => state.userReducer);

//     const [selected, setSelected] = useState();
//     const [error, setError] = useState(false);

//     const handleAddAddress = () => {
//         dispatch({ type: commonActions.SET_SHOW_USER_ADDRESS_MODAL, payload: true });
//     };

//         const handleSubmitAddress = () => { 
//             if (selected?.value) {
//                 setError(false);
//                 handleAddress(selected?.value);
//             } else {
//                 setError(true);
//             }

           
//         };

//     return (
//         <Modal
//             show={showModal}
//             onHide={closeModal}
//             centered
//         >
//             <div className="modal_close_icons"
//                 onClick={closeModal}
//             >
//                 <svg
//                     stroke="currentColor"
//                     fill="currentColor"
//                     stroke-width="0"
//                     viewBox="0 0 24 24"
//                     height="1em"
//                     width="1em"
//                     xmlns="http://www.w3.org/2000/svg"
//                 >
//                     <path fill="none" d="M0 0h24v24H0z"></path>
//                     <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
//                 </svg>
//             </div>
//             <Container className="my-4">
//                 <div className="d-flex justify-content-between">
//                     <h5 className="mb-4 text-center">{t("Select your address")}</h5>
//                     {userAddress?.length > 0 &&
//                         <Button className="select-address-add-btn" size="sm" onClick={handleAddAddress}>{t("Add new address")}</Button>
//                     }
//                 </div>
//                 {userAddress?.length > 0 ?
//                     <>
//                         {userAddress?.map((val, i) =>
//                             <div key={i} className={`user-profile-address-div cursor-pointer ${(JSON.stringify(selected?.value) === JSON.stringify(val)) && 'user-profile-default-address'}`} onClick={() => setSelected({ index: i, value: val })}>
//                                 <div className="d-flex justify-content-between">
//                                     <div className="d-flex">
//                                         <input
//                                             type="radio"
//                                             className="me-2 mb-1"
//                                             // checked={selected?.index === i ? true : false}
//                                             checked={JSON.stringify(selected?.value) === JSON.stringify(val) ? true : false}
//                                         // checked={(defaultAddress?.id === val?.id || val?.default_address === 1) ? true : false}
//                                         />
//                                         <div className="fw-600">{val?.address_type}</div>
//                                     </div>
//                                 </div>
//                                 <div className="address-book-address mb-2">
//                                     <div>{`${val?.address_line1}, ${val?.address_line2}, ${val?.address_line3},`}</div>
//                                     <div>{`${val?.city} - ${val?.pincode}.`}</div>
//                                     <div>{`${val?.state}, ${val?.country}`}</div>
//                                 </div>
//                             </div>
//                         )}
//                     </> :
//                     <div className="nocoupons">
//                         <Button onClick={handleAddAddress}>{t("Add Address")}</Button>
//                     </div>
//                 }
//                 {error &&
//                     <div className="mt-3">
//                         <p className="text-danger">Select farm address</p>
//                     </div>
//                 }
//                 <div className="text-center mt-3">
//                     <Button onClick={handleSubmitAddress}>{t("Submit")}</Button>
//                 </div>
//             </Container>
//         </Modal>
//     )
// }


import React, { useState } from "react";
import { Button, Container, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../Redux/common/actions";
import farmActions from "../../Redux/sellerProfile/action";
import { useTranslation } from 'react-i18next';

export default function SellerSelectAddressModal(props) {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { showModal, closeModal, handleAddress } = props;

    const { userAddress } = useSelector(state => state.userReducer);

    const [selected, setSelected] = useState();
    const [error, setError] = useState(false);

    const handleAddAddress = () => {
        dispatch({ type: commonActions.SET_SHOW_USER_ADDRESS_MODAL, payload: true });
    };

    const handleSubmitAddress = (event) => {
        event.preventDefault();
        if (selected?.value) {
            setError(false);
            handleAddress(selected?.value);
            closeModal();
        } else {
            setError(true);
        }
    };

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
        >
            <div className="modal_close_icons"
                onClick={closeModal}
            >
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
            </div>
            <Container className="my-4">
                <div className="d-flex justify-content-between">
                    <h5 className="mb-4 text-center">{t("Select your address")}</h5>
                    {userAddress?.length > 0 &&
                        <Button className="select-address-add-btn" size="sm" onClick={handleAddAddress}>{t("Add new address")}</Button>
                    }
                </div>
                {userAddress?.length > 0 ?
                    <>
                        {userAddress?.map((val, i) =>
                            <div key={i} className={`user-profile-address-div cursor-pointer ${(JSON.stringify(selected?.value) === JSON.stringify(val)) && 'user-profile-default-address'}`}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <Form.Check
                                            type="radio"
                                            className="me-2 mb-1"
                                            checked={JSON.stringify(selected?.value) === JSON.stringify(val)}
                                            onChange={() => setSelected({ index: i, value: val })}
                                        />
                                        <div className="fw-600">{val?.address_type}</div>
                                    </div>
                                </div>
                                <div className="address-book-address mb-2">
                                    <div>{`${val?.address_line1}, ${val?.address_line2}, ${val?.address_line3},`}</div>
                                    <div>{`${val?.city} - ${val?.pincode}.`}</div>
                                    <div>{`${val?.state}, ${val?.country}`}</div>
                                </div>
                            </div>
                        )}
                    </> :
                    <div className="nocoupons">
                        <Button onClick={handleAddAddress}>{t("Add Address")}</Button>
                    </div>
                }
                {error &&
                    <div className="mt-3">
                        <p className="text-danger">Select farm address</p>
                    </div>
                }
                <div className="text-center mt-3">
                    <Button onClick={handleSubmitAddress}>{t("Submit")}</Button>
                </div>
            </Container>
        </Modal>
    )
}