export const MobileNumberValidation = (e) => {
  if (e.target.value.length < 10) {
    const cleanedValue = e.target.value.replace(!/^\d{10}$/, '');
    // const cleanedValue = e.target.value.replace(/\D/g, '');
    const formattedValue = cleanedValue.slice(0, 10);

    e.target.value = formattedValue;
  }
};

export const AlphabetsValidation = (e) => {
  if (e.target.value.length < 10) {
    let value = e.target.value.replace(/[^a-z\s]/gi, '');
    if (value[0] === ' ') {
      value = value.slice(1);
    }
    return e.target.value = value;
  }
}

export const handleEmailValidation = email => {
  // eslint-disable-next-line no-useless-escape
  const isValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
  let error;

  if (!isValid) {
    error = 'Enter valid Email id';
  }

  return error;
};

export const handleEmailValidationWithoutRequired = email => {
  if (email) {
    // eslint-disable-next-line no-useless-escape
    const isValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
    let error;

    if (!isValid) {
      error = 'Enter valid Email id';
    }

    return error;
  } else {
    return;
  }
};

export const priceRoundOff = (price) => {
  if (Number(price) > 0) {
    return parseInt(Math.round(price));
  } else {
    return 0;
  }
};

export const MobileNumberFirstNumberValidation = (number) => {
  if (number?.length > 0) {
    let value = number;
    if (parseInt(value?.slice(0, 1)) < 6) {
      return 'Enter valid mobile number';
    }
  }
};
