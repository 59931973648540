import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { EncryptFunction } from "../../utils/cryptoFunction";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { MdOutlineCurrencyRupee, MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { LogEvent } from "../../utils/firebase";
import { priceRoundOff } from "../../utils/validation";

export default function RelatedProducts() {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { relatedProducts } = useSelector(state => state.ProductListReducer);
    const { currentLanguage } = useSelector(state => state.commonReducer);

    const handleViewDetails = (e, id, value) => {
        e.preventDefault();
        LogEvent('Product_Details_SimilarProducts_ViewProduct')
        const encrypt = EncryptFunction(id);
        navigate(`/product-details?id=${encrypt}`, { state: value });
        window.scrollTo({ top: 0 })
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                },
            },
            {   
                breakpoint: 768,
                settings: { 
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                },
            },
        ],
        prevArrow: <MdOutlineArrowBackIos />,
        nextArrow: <MdOutlineArrowForwardIos />,
    };


    return (
        <Container>
            <Row className="row-padding-remover">
                <Col md={12}>
                    <div className="product-details-related-product-label">
                        {t("You might also like")}
                    </div>
                </Col>
                <Col md={12}>
                    <Slider {...settings} className="slider-arrow-custom-style-for-related-products">
                        {relatedProducts?.map((product, i) =>
                            <div key={i} className="relate_product_bg_color_div" onClick={(e) => handleViewDetails(e, product?.id, product)}>
                                <div className="product-details-related-product-img-div">
                                    <img src={product?.image_url} className="product-details-related-product-img" alt="..." />
                                </div>
                                <div className="related_product_card_body">
                                    <div className="related-product-name cursor-pointer" onClick={(e) => { e.stopPropagation(); handleViewDetails(e, product?.id, product); }}>
                                        {product?.language?.find(lang => lang?.language_code === currentLanguage)?.medicine_name || product?.medicine_name}
                                    </div>
                                    <div
                        className="related_product_pack_weight_div cursor-pointer description-container"
                        onClick={(e) => handleViewDetails(e, product?.id, product)}
                      >
                        {product?.language?.find((lang) => lang?.language_code === currentLanguage)?.discription ||
                          product?.discription}
                      </div>
                                    <div
                                        className="cursor-pointer"
                                        onClick={(e) => handleViewDetails(e, product?.id, product)}
                                    >
                                        {product?.discount_status === 1 ? (
                                            <div>
                                                <div className="related_product_card_price_Del">
                                                    <div>{t('MRP')}</div>
                                                    <del>
                                                        <MdOutlineCurrencyRupee />
                                                        {product.amount}
                                                    </del>
                                                    {product.discount_amount > 0 && (
                                                        <span className="related_product_dis_value">
                                                            <>
                                                                {product?.discount_type === 1
                                                                    ? `${product.discount_amount}% off`
                                                                    : `- ₹${product.discount_amount}`}
                                                            </>
                                                        </span>
                                                    )}
                                                </div>
                                                {product?.discount_type === 1 ? (
                                                    <span className="related-product-final-price-span mt-2">
                                                        {' '}
                                                        <MdOutlineCurrencyRupee />
                                                        {priceRoundOff(
                                                            product?.amount - (product?.amount * product?.discount_amount) / 100
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span className="related-product-final-price-span mt-2">
                                                        <MdOutlineCurrencyRupee />
                                                        {priceRoundOff(product?.amount - product?.discount_amount)}
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <>
                                                <div className="related-product-final-price-span d-flex justify-content-between">
                                                    <div className="price-container d-flex">
                                                        <span>₹{product?.amount}</span>
                                                    </div>
                                                    <Button
                                                        className="relatedproduct-viewproduct-btn"
                                                        onClick={(e) => { e.stopPropagation(); handleViewDetails(e, product?.id, product); }}
                                                    >
                                                        {t('View Product')}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                    </Slider>
                </Col>
            </Row>
        </Container>
    )
}