import React from 'react';
import { PiPhoneCallFill } from "react-icons/pi";
import { DecryptFunction } from '../utils/cryptoFunction';
import { LogEvent } from '../utils/firebase';

const CallFloatingBtn = () => {

    return (
        <div className="call-floating-btn-cls">
            <a href={`tel:${DecryptFunction(sessionStorage.getItem('pilot_no'))}`} onClick={() => LogEvent('Floating_Call_Buton_Clicked')}>
                <PiPhoneCallFill />
            </a>
        </div>
    );
};

export default CallFloatingBtn;
