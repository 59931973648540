import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function ToastMessage() {
  const { alert } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (alert?.show) {
      if (alert?.status === "success") {
        toast.success(alert?.message);
      } else {
        toast.error(alert?.message);
      }
    }
  }, [alert]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
}
