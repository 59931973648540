import React from "react";
import { BiSolidNotepad } from "react-icons/bi";
import { FaUser, FaUserTag } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { RiCouponFill } from "react-icons/ri";
import { GiFarmer } from "react-icons/gi";
import { useTranslation } from 'react-i18next';
import { FiLogOut } from "react-icons/fi";

export default function ProfileAccordion(props) {

    const { t } = useTranslation();

    const { handleAccordion, profileSection } = props;

    const sidebarList = [
        {
            icon: <FaUser />,
            title: `${t("Profile")}`,
            path: "Profile"
        },
        {
            icon: <FaUserTag />,
            title: `${t("Seller Profile")}`,
            path: "Seller"
        },
        {
            icon: <FaLocationDot />,
            title: `${t("Address Book")}`,
            path: "Address"
        },
        {
            icon: <GiFarmer />,
            title: `${t("Farm Details")}`,
            path: "Farm"
        },
        {
            icon: <BiSolidNotepad />,
            title: `${t("My Orders")}`,
            path: "Order"
        },
        {
            icon: <RiCouponFill />,
            title: `${t("My Coupons")}`,
            path: "Coupon"
        },
        {
            icon: <FiLogOut />,
            title: `${t("Logout")}`,
            path: "Logout"
        },
    ]

    return (
        <div>
            <div className="seller_profile_page_main_div">
                <div className="market-animals-cate-filter-header">{t("Your Profile")}</div>
                {sidebarList?.map((value, i) =>
                    <div key={i}
                        className={`market-animals-cate-filter-category cursor-pointer
                         ${profileSection === value?.path && "market-place-cate-filter-active-div"}
                        `}
                        onClick={() => handleAccordion(value?.path)}
                    >
                        <div className={`market-animals-cate-filter-div`} key={i}>
                            <div className={`profile_page_icon_cls ${profileSection === value?.path && "profile_page_icon_active"}`}>{value?.icon}</div>
                            <div className={`market-animals-cate-filter-title  ${profileSection === value?.path && "profile_page_icon_active"}`}>{value?.title}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="seller_profile_page_main_div_small">
                {sidebarList?.map((value, i) =>
                    <div key={i}
                        className={`market-animals-cate-filter-category_small cursor-pointer
                         ${profileSection === value?.path && "market-place-cate-filter-active-div_small"}
                        `}
                        onClick={() => handleAccordion(value?.path)}
                    >
                        <div className={`market-animals-cate-filter-div`} key={i}>
                            <div className={`profile_page_icon_cls ${profileSection === value?.path && "profile_page_icon_active"}`}>{value?.icon}</div>
                            <div className={`market-animals-cate-filter-title  ${profileSection === value?.path && "profile_page_icon_active"}`}>{value?.title}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}