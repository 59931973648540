const userActions = {
    GET_USER_ADDRESS: 'GET_USER_ADDRESS',
    SET_USER_ADDRESS: 'SET_USER_ADDRESS',
    CREATE_USER_ADDRESS: 'CREATE_USER_ADDRESS',
    GET_CATTLE_FARM_DEATILS: 'GET_CATTLE_FARM_DEATILS',
    SET_CATTLE_FARM_DEATILS: 'SET_CATTLE_FARM_DEATILS',
    LOG_OUT_PROFILE: 'LOG_OUT_PROFILE',
    // CLEAR_AUTH_DATA
    CREATE_FARM_DEATILS: 'CREATE_FARM_DEATILS',
    UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
    UPDATE_USER_ADDRESS: 'UPDATE_USER_ADDRESS',
    REMOVE_USER_ADDRESS: 'REMOVE_USER_ADDRESS',
    CREATE_USER_FARM: 'CREATE_USER_FARM',
    UPDATE_USER_FARM: 'UPDATE_USER_FARM',
    UPLOAD_IMAGE_TO_AWS: 'UPLOAD_IMAGE_TO_AWS',
    SET_UPLOADED_IMAGE_URL: 'SET_UPLOADED_IMAGE_URL',
    GET_PAYMENT_PLAN: 'GET_PAYMENT_PLAN',
    SET_PAYMENT_PLAN: 'SET_PAYMENT_PLAN',
    
};

export default userActions;