import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import moment from "moment-timezone";
import { FaCheck, FaCopy } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { GoCopy } from "react-icons/go";


export default function MyCoupons() {

    const { t } = useTranslation();

    const { couponList } = useSelector(state => state.couponReducer);

    const [activeTab, setActiveTab] = useState('Active');
    const [coupons, setCoupons] = useState({
        active: [],
        history: []
    });
    const [copiedCoupon, setCopiedCoupon] = useState({
        code: null, index: null
    });

    useEffect(() => {
        if (couponList?.length > 0) {
            //eslint-disable-next-line
            const activeCoupons = couponList?.filter((val) => {
                if (moment(val?.expires_at).diff(moment()) > 0) {
                    return val;
                }
            });
            //eslint-disable-next-line
            const historyCoupons = couponList?.filter((val) => {
                if (moment(val?.expires_at).diff(moment()) < 0) {
                    return val;
                }
            });
            setCoupons({ active: activeCoupons, history: historyCoupons });
        }
    }, [couponList]);

    const handleCouponCodeCopy = (e, code, i) => {
        e.preventDefault();
        setCopiedCoupon({ code: code, index: i });
        navigator.clipboard.writeText(code);
    };

    return (
        <>
            {/* <div className="page_heading_div_cls">{t("My Coupons")}</div> */}
            <Row className="row-padding-remover">
                <Col md={12} className="d-flex justify-content-center mb-3">
                    <div
                        className={`user-profile-coupons-tab ${activeTab === 'Active' && 'user-profile-coupons-tab-active'}`}
                        onClick={() => setActiveTab('Active')}
                    >
                        {t("Active")}
                    </div>
                    <div
                        className={`user-profile-coupons-tab ${activeTab === 'History' && 'user-profile-coupons-tab-active'}`}
                        onClick={() => setActiveTab('History')}
                    >
                        {t("History")}
                    </div>
                </Col>
                {activeTab === 'Active' &&
                    <>
                        {coupons?.active?.length > 0 ?
                            <>
                                {coupons?.active?.map((val, i) =>
                                    <Col xl={5} lg={12} md={12} sm={12} className="user-profile-coupon-card-col" key={i}>
                                        <div className="user-profile-coupon-card-div">
                                            <div>
                                                <h6 className="user-profile-coupon-percentage active-coupon-percentage">{val?.discount_type === 0 && '₹'}{val?.discount}{val?.discount_type === 1 ? '%' : '/-'} OFF</h6>
                                                <div className="user-profile-coupon-description" style={{ overflowWrap: 'break-word' }}>{val?.description}</div>
                                            </div>
                                        </div>
                                        <div>
                                        <hr className="dashed" />
                                        </div>
                                        <div className="Position">
                                            <div className="d-flex justify-content-between align-items-center active-coupon-code-copy">
                                                <h6 className="mb-0 coupon-code">{val?.code}</h6>
                                                {i === copiedCoupon?.index ? (
                                                    <FaCheck className="checkout-coupon-copied-check" />
                                                ) : (
                                                    <div>
                                                        <GoCopy className="coupon-active-copy-icon" onClick={(e) => handleCouponCodeCopy(e, val?.code, i)} />
                                                        <span className="coupon-copy-text" onClick={(e) => handleCouponCodeCopy(e, val?.code, i)}>
                                                            &nbsp;
                                                            copy
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </Col>
                                )}
                            </> :
                            <div className="nocoupons">
                                {t("No Coupons")}
                            </div>
                        }
                    </>
                }
                {activeTab === 'History' &&
                    <>
                        {coupons?.history?.length > 0 ?
                            <>
                                {coupons?.history?.map((val, i) =>
                                    <Col xl={5} lg={12} md={12} sm={12} className="user-profile-coupon-card-col" key={i}>
                                        <div className="user-profile-coupon-card-div">
                                            <div className="d-flex justify-content-between">
                                                <h6 className="user-profile-coupon-percentage">{val?.discount_type === 0 && '₹'}{val?.discount}{val?.discount_type === 1 ? '%' : '/-'} OFF</h6>
                                                <small className="user-profile-expiry">{t("Expired")}</small>
                                            </div>
                                            <div className="user-profile-coupon-description">{val?.description}</div>
                                        </div>
                                    </Col>
                                )}
                            </> :
                            <div className="nocoupons">
                                {t("No Coupons")}
                            </div>
                        }
                    </>
                }
            </Row>
        </>
    )
};