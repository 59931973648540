import { put, all, call, takeEvery, select } from 'redux-saga/effects';
import { API_URL, BILLING_EMAIL } from '../../utils/constant';
import axios from "axios";
import { DecryptFunction } from "../../utils/cryptoFunction";
import subscriptionActions from './actions';
import checkoutActions from '../checkout/actions';

const subscriptionSagas = function* () {
    yield all([
        yield takeEvery(subscriptionActions.UPDATE_SUBSCRIPTION_PLAN, updateSubscriptionPlan),
        yield takeEvery(subscriptionActions.UPDATE_ORDER_FOR_SUBSCRIPTION, updateOrderForSubscription),
        yield takeEvery(subscriptionActions.UPDATE_CATTLE_LISTINGS_SUBSCRIPTION, updateCattleListingsSubscription),
        yield takeEvery(subscriptionActions.GET_USER_SUBSCRIPTIONS, getUserSubscriptions),
    ])
};

const updateSubscriptionPlan = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/user/subscriptions`, payload?.data)
        );
        const { subscriptionValues } = yield select(state => state?.subscriptionReducer);
        sessionStorage.setItem('response_page', 'subscription');
        if (parseInt(subscriptionValues?.total) > 0) {
            if (result?.data?.code === 200) {
                if (subscriptionValues?.applied_Coupon?.discount_type === 1) {
                    yield put({
                        type: checkoutActions.APPLY_ORDER_COUPON, payload: {
                            id: payload?.data?.id,
                            coupon_percentage: subscriptionValues?.applied_Coupon?.discount
                        }
                    });
                }
                const user_id = DecryptFunction(localStorage.getItem('auth_user'));
                if (user_id) {
                    if (subscriptionValues?.applied_Coupon) {
                        yield put({ type: checkoutActions.UPDATE_USER_COUPON });
                    }
                    const { UserProfile } = yield select(state => state.AuthReducer);
                    yield put({
                        type: checkoutActions.PAYMENT_ENCODER, payload: {
                            data: {
                                amount: parseInt(parseFloat(subscriptionValues?.total)?.toFixed(0)),
                                billing_name: UserProfile?.name,
                                billing_address: subscriptionValues?.DeliveryAddress?.address_line3,
                                billing_city: subscriptionValues?.DeliveryAddress?.city,
                                billing_state: subscriptionValues?.DeliveryAddress?.state,
                                billing_zip: subscriptionValues?.DeliveryAddress?.pincode,
                                billing_country: subscriptionValues?.DeliveryAddress?.country,
                                billing_tel: UserProfile?.mobile_no,
                                billing_email: UserProfile?.email ? UserProfile?.email : BILLING_EMAIL,
                                // billing_email: UserProfile?.email ? UserProfile?.email : 'admin@yourfarm.co.in',
                                delivery_name: UserProfile?.name,
                                delivery_tel: UserProfile?.mobile_no,
                                delivery_address: subscriptionValues?.DeliveryAddress?.address_line3,
                                delivery_city: subscriptionValues?.DeliveryAddress?.city,
                                delivery_state: subscriptionValues?.DeliveryAddress?.state,
                                delivery_zip: subscriptionValues?.DeliveryAddress?.pincode,
                                delivery_country: subscriptionValues?.DeliveryAddress?.country,
                                currency: 'INR',
                                language: 'EN',
                                order_id: result?.data?.data[0]?.order_id,
                                merchant_param1: 'YOURFARM',
                                // merchant_param1: encodeURI(`${FRONTEND_URL}/checkout-response`),
                                // redirect_url: `http://localhost:3005/redirectpost`,
                                // cancel_url: `http://localhost:3005/redirectpost`
                                redirect_url: `${API_URL}/v1/payment/verify`,
                                cancel_url: `${API_URL}/v1/payment/verify`
                            },
                            navigate: navigate
                        }
                    });
                }
            }
        } else {
            // let cattle = { ...subscriptionValues?.cattlelistings };
            // cattle.listing['subscription_id'] = result?.data?.data[0]?.id;
            const cattle = {
                listing: {
                    subscription_id: result?.data?.data[0]?.id
                }
            };
            yield put({
                type: subscriptionActions.UPDATE_ORDER_FOR_SUBSCRIPTION,
                payload: {
                    order_id: result?.data?.data[0]?.order_id,
                    data: { "order_status": 3, "is_cart": 0 },
                    cattle: cattle,
                    cattle_id: subscriptionValues?.cattlelistings?.listing?.id,
                    navigate: navigate
                }
            })
            // navigate('/PaymentSuccess');
        };
    } catch (err) {
        console.log(err);
    };
};

const updateOrderForSubscription = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    try {
        yield call(() =>
            axios.put(`${API_URL}/v1/admin/updateorder/${payload?.order_id}`, payload?.data)
        );
        yield put({
            type: subscriptionActions.UPDATE_CATTLE_LISTINGS_SUBSCRIPTION,
            payload: { cattle: payload?.cattle, cattle_id: payload?.cattle_id, navigate: navigate }
        });
    } catch (err) {
        console.log(err);
    };
};

const updateCattleListingsSubscription = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    try {
        yield call(() =>
            axios.put(`${API_URL}/v1/user/cattle_listings/${payload?.cattle_id}`, payload?.cattle)
        );
        navigate("/PaymentSuccess?page=subscription");
        sessionStorage.removeItem('response_page');
    } catch (err) {
        console.log(err);
    };
}

const getUserSubscriptions = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/subscriptions`, {
                params: payload
            })
        );
        if (result?.data?.code === 200) {
            yield put({ type: subscriptionActions.SET_USER_SUBSCRIPTIONS, payload: result?.data?.data });
        };
    } catch (err) {
        console.log(err);
    };
};

export default subscriptionSagas;