import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import market_Action from "../../Redux/marketPlace/action";
import farmActions from "../../Redux/sellerProfile/action";
import { DecryptFunction } from "../../utils/cryptoFunction";
import homeActions from "../../Redux/Home/action";
import { useNavigate } from 'react-router-dom';
import TopNavbar from "../commonComponents/Navbar/Navbar";
import Footer from "../commonComponents/footer";
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'react-bootstrap';
import CattleAgreeModal from '../modal/cattleAgreeModal';
import { IoMdCloseCircleOutline } from "react-icons/io";

const AddAnimalForm = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { cattlecategory, sellerprofile } = useSelector((state) => state.MarketPlaceReducer);

    const { breadList, recentDisease } = useSelector((state) => state.farmTypeReducer);

    const { UserProfile } = useSelector((state) => state.AuthReducer);

    const { register, handleSubmit, setValue, reset, watch, clearErrors, formState: { errors } } = useForm();

    let [selectedValues, setSelectedValues] = useState({});
    let [showTermsModal, setShowTermsModal] = useState(false);

    const imageArray = [
        { label: "Front", name: 'image_front_url' },
        { label: "Back", name: 'image_side_url' },
        { label: "Side", name: 'image_body_url' },
        { label: "Video", name: 'video_url' }
    ]

    useEffect(() => {
        dispatch({ type: market_Action.GET_DIARY_CATTLE_CATEGORY });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (localStorage.getItem("auth_user")) {
            const user = DecryptFunction(localStorage.getItem("auth_user"))
            dispatch({
                type: homeActions.GET_USER_PROFILE,
                payload: { id: user },
            })
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch({
            type: market_Action.GET_SELLER_PROFILE,
            payload: UserProfile?.provider_id,
        });
        //eslint-disable-next-line
    }, [UserProfile?.provider_id]);

    const handleDiseasesChange = (value) => {
        let selected = [];
        (selectedValues?.disease_history || selectedValues?.disease_history?.length > 0) ?
            selected = [...selectedValues?.disease_history] : selected = [];
        if (selected?.length === 0) {
            selected.push(value);
        } else {
            let validate = selected?.findIndex(val => val === value);
            if (validate === -1) {
                selected.push(value);
            } else {
                selected.splice(validate, 1);
            };
        };
        setSelectedValues({ ...selectedValues, disease_history: selected });
    };

    const handleBackClick = () => {
        navigate('/Animals');
    };

    const handleCattleChange = (event) => {
        if (event.target.selectedOptions[0].getAttribute('data-name')) {
            const value = JSON.parse(event.target.selectedOptions[0].getAttribute('data-name'));
            if (value) {
                let cattle = {};
                if (value?.parent_id === 0) {
                    cattle['cattle_type_id'] = value?.id;
                    cattle['cattle_parent_id'] = value?.id;
                } else {
                    cattle['cattle_type_id'] = value?.id;
                    cattle['cattle_parent_id'] = value?.parent_id;
                }
                setValue('cattle_type_id', cattle?.cattle_type_id);
                clearErrors('cattle_type_id');
                reset({
                    breed_id: '', gender: '', age: '', lactation_num: '', last_calving_date: '', milk_production_litres: ''
                })
                setSelectedValues({ cattle_type_id: cattle?.cattle_type_id, cattle_parent_id: cattle?.cattle_parent_id });
                dispatch({ type: farmActions.GET_BREAD_TYPES, payload: { cattle_type_id: cattle?.cattle_parent_id } });
                dispatch({ type: farmActions.GET_CATTLE_DISEASE, payload: cattle?.cattle_type_id });
            }
        }
    };

    const handleNumOfCalvesChange = (event) => {
        const value = parseInt(event.target.value);
        setSelectedValues({ ...selectedValues, lactation_num: value });
        setValue('lactation_num', value);
        clearErrors('lactation_num');
        reset({ last_calving_date: '', milk_production_litres: '' });
    }

    const onSubmit = (data) => {
        const cattlecategoryTitle = cattlecategory?.data?.find(el => el.id === data?.cattle_type_id)?.title;
        const breedListName = breadList?.find(el => el.id === data?.breed_id)?.breed_name;
        const images = [
            { file: data?.cattle?.image_front_url[0], type: 'image_front_url' },
            { file: data?.cattle?.image_side_url[0], type: 'image_side_url' },
            { file: data?.cattle?.image_body_url[0], type: 'image_body_url' },
            { file: data?.cattle?.video_url[0], type: 'video_url' }
        ];
        let cattle = {
            gender: data?.gender,
            breed_id: parseInt(data?.breed_id),
            age: parseInt(data?.age),
            cattle_type_id: parseInt(data?.cattle_type_id),
            lactation_num: parseInt(data?.lactation_num),
            last_calving_date: data?.last_calving_date,
            farm_id: sellerprofile.data[0].farm_type_id,
            disease_history: selectedValues?.disease_history || [],
            milk_production_litres: parseInt(data?.milk_production_litres),
        }
        let finalCattleObj = Object.entries(cattle).reduce((acc, [k, v]) => v ? { ...acc, [k]: v } : acc, {});

        const payload = {
            listing: {
                provider_id: UserProfile?.provider_id,
                rate: parseInt(data?.rate),
                title: `${breedListName} - ${cattlecategoryTitle}`,
                description: data?.description,
                place: sellerprofile?.data[0]?.address?.city,
            },
            cattle: finalCattleObj
        };
        dispatch({
            type: farmActions.CREATE_ANIMAL_POST,
            images: images?.filter(val => { return val?.file }) || [],
            payload: payload,
            navigate: navigate
        });
    };

    return (
        <>
            <TopNavbar />
            <Container className='my-5'>
                <div className="page_heading_div_cls">
                    {t('AnimalPost')}
                </div>
                <Row className="my-3 user-profile-tab1-details row-padding-remover">
                    <Col md={6} sm={12} className="mb-4">
                        <label htmlFor="chooseCattle" className="form-label form-label-custom-style">
                            {t('Select category')}
                        </label>
                        <select
                            className="form-select form-control input-bg-none ms-0"
                            id="chooseCattle"
                            {...register("cattle_type_id", {
                                required: '*Category is required',
                            })}
                            defaultValue={""}
                            onChange={handleCattleChange}
                        >
                            <option value="" disabled> ---{t('Select category')}--- </option>
                            {cattlecategory?.data?.map((cattle) => (
                                <option key={cattle.id} data-name={JSON.stringify(cattle)} value={cattle?.id}>{cattle.title}</option>
                            ))}
                        </select>
                        {errors?.cattle_type_id &&
                            <small className="text-danger">{errors?.cattle_type_id?.message}</small>
                        }
                    </Col>
                    {selectedValues?.cattle_type_id &&
                        <Col md={6} sm={12} className="mb-4">
                            <label htmlFor="chooseBread" className="form-label form-label-custom-style">
                                {t('Select Breed')}
                            </label>
                            <select
                                className="form-select form-control input-bg-none ms-0"
                                id="chooseBread"
                                {...register("breed_id", {
                                    required: '*Breed is required'
                                })}
                                defaultValue={""}
                            >
                                <option value="" disabled>---{t('Select Breed')}---</option>
                                {breadList.map((breed) => (
                                    <option key={breed.id} value={breed.id}>
                                        {breed.breed_name}
                                    </option>
                                ))}
                            </select>
                            {errors?.breed_id &&
                                <small className="text-danger">{errors?.breed_id?.message}</small>
                            }
                        </Col>
                    }
                    {(selectedValues?.cattle_type_id && (selectedValues?.cattle_type_id === 3 || selectedValues?.cattle_type_id === 6 || selectedValues?.cattle_type_id === 8 || selectedValues?.cattle_type_id === 13 || selectedValues?.cattle_type_id === 15)) &&
                        <Col md={6} sm={12} className="mb-4">
                            <label htmlFor="gender" className="form-label form-label-custom-style">{t("Gender")}</label>
                            <select
                                className="form-select form-control input-bg-none ms-0"
                                id="gender"
                                {...register('gender', {
                                    required: selectedValues?.cattle_type_id === 3 || selectedValues?.cattle_type_id === 6 || selectedValues?.cattle_type_id === 8 || selectedValues?.cattle_type_id === 13 || selectedValues?.cattle_type_id === 15 ? '*Gender is required' : ''
                                })}
                                defaultValue={""}
                            >
                                <option value="" disabled>--Select--</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            {errors?.gender &&
                                <small className="text-danger">{errors?.gender?.message}</small>
                            }
                        </Col>
                    }
                    <Col md={6} sm={12} className="mb-4">
                        <label htmlFor="form4" className="form-label form-label-custom-style">
                            {(selectedValues?.cattle_type_id === 1 || selectedValues?.cattle_type_id === 2) ? t("Teeth") :
                                (selectedValues?.cattle_type_id === 17) ? `${t("Age")}(Weeks)` :
                                    (selectedValues?.cattle_type_id === 3 || selectedValues?.cattle_type_id === 6 || selectedValues?.cattle_type_id === 13 || selectedValues?.cattle_type_id === 15 || selectedValues?.cattle_type_id === 16) ? `${t("Age")}(Months)` :
                                        `${t("Age")}(Year)`}
                        </label>
                        <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className={`form-control input-bg-none`}
                            id="age"
                            {...register('age', {
                                required: (selectedValues?.cattle_type_id === 1 || selectedValues?.cattle_type_id === 2) ? '*Teeth is required' : '*Age is required',
                                maxLength: {
                                    value: 3,
                                    message: "Enter a valid number",
                                },
                            })}
                        />
                        {errors?.age &&
                            <small className="text-danger">{errors?.age?.message}</small>
                        }
                    </Col>
                    {(selectedValues?.cattle_type_id === 1 || selectedValues?.cattle_type_id === 2 || selectedValues?.cattle_type_id === 9 || selectedValues?.cattle_type_id === 4 || selectedValues?.cattle_type_id === 7 || selectedValues?.cattle_type_id === 10) &&
                        <Col md={6} sm={12} className="mb-4">
                            <label htmlFor='numOfCalves' className='form-label form-label-custom-style'>
                                {t("Number of calf delivered")}
                            </label>
                            <select
                                className='form-select form-control input-bg-none ms-0'
                                id='lactation_num'
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                {...register('lactation_num', {
                                    required: (selectedValues?.cattle_type_id === 1 || selectedValues?.cattle_type_id === 2 || selectedValues?.cattle_type_id === 9 || selectedValues?.cattle_type_id === 4 || selectedValues?.cattle_type_id === 7 || selectedValues?.cattle_type_id === 10) ? '*required' : '',
                                })}
                                onChange={handleNumOfCalvesChange}
                                defaultValue={''}
                            >
                                <option value=''>--Select--</option>
                                {[...Array(11).keys()].map((num) => (
                                    <option key={num} value={num}>{num}</option>
                                ))}
                            </select>
                            {errors?.lactation_num &&
                                <small className="text-danger">{errors?.lactation_num?.message}</small>
                            }
                        </Col>
                    }
                    {selectedValues?.lactation_num > 0 &&
                        <Col md={6} sm={12} className="mb-4">
                            <label htmlFor='lastCalving' className='form-label form-label-custom-style'>
                                {t("Last Calving (in months)")}
                            </label>
                            <input
                                type='number'
                                className='form-control input-bg-none'
                                id='lastCalving'
                                onWheel={(e) => e.target.blur()}
                                {...register('last_calving_date', {
                                    required: (selectedValues?.lactation_num > 0) ? '*required' : '',
                                    maxLength: {
                                        value: 3,
                                        message: "Enter a valid number",
                                    },
                                })}
                            />
                            {errors?.last_calving_date &&
                                <small className="text-danger">{errors?.last_calving_date?.message}</small>
                            }
                        </Col>
                    }
                    {selectedValues?.lactation_num > 0 &&
                        <Col md={6} sm={12} className="mb-4">
                            <label htmlFor='milk_production_litres' className='form-label form-label-custom-style'>
                                {t("Milk Production (litre/Day)")}
                            </label>
                            <input
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                className='form-control input-bg-none'
                                id='milk_production_litres'
                                {...register('milk_production_litres', {
                                    required: (selectedValues?.lactation_num > 0) ? '*required' : '',
                                    maxLength: {
                                        value: 3,
                                        message: "Enter a valid number",
                                    },
                                })}
                            />
                            {errors?.milk_production_litres &&
                                <small className="text-danger">{errors?.milk_production_litres?.message}</small>
                            }
                        </Col>
                    }
                    <Col md={6} sm={12} className="mb-4">
                        <label htmlFor="form4" className="form-label form-label-custom-style">
                            {t("Rate")} (₹)
                        </label>
                        <input
                            type="number"
                            min={0}
                            onWheel={(e) => e.target.blur()}
                            className={`form-control input-bg-none`}
                            id="rate"
                            {...register('rate', {
                                required: '*Rate is required',
                                maxLength: {
                                    value: 6,
                                    message: "Enter a valid rate",
                                },
                                min: {
                                    value: 1,
                                    message: "Enter a valid rate",
                                }
                            })}
                        />
                        {errors?.rate &&
                            <small className="text-danger">{errors?.rate?.message}</small>
                        }
                    </Col>
                    <Col lg={12} className="mb-4">
                        <label htmlFor="form4" className="form-label form-label-custom-style">
                            {t("Salient feature of the animal (example-calm, good producer etc)")}
                        </label>
                        <textarea
                            className="form-control custom-textarea input-bg-none"
                            id="description"
                            {...register('description')}

                        />
                    </Col>
                    {selectedValues?.cattle_type_id &&
                        <Col lg={12} className="mb-4">
                            <label htmlFor="form4" className="form-label form-label-custom-style">
                                {t("Recent Diseases")}
                            </label>
                            <div className='animalpost-disease-list-div'>
                                {recentDisease?.map((disease) =>
                                    <div key={disease?.id} className={`recent-disease-in-add-animal custom-checkbox`} onClick={() => handleDiseasesChange(disease?.disease)}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input mt-2"
                                            checked={selectedValues?.disease_history?.find(val => val === disease?.disease) ? true : false}
                                        />
                                        <label
                                            className={`form-check-label`}
                                            htmlFor={disease?.disease}
                                        >
                                            {disease?.disease}
                                        </label>
                                    </div>
                                )}
                            </div>
                        </Col>
                    }
                    <Col lg={12} className="mb-4">
                        <Row>
                            <Col md={12} className='mb-2'>
                                <label htmlFor="form4" className="form-label form-label-custom-style">
                                    {t("Upload Images")}
                                </label>
                            </Col>
                            {imageArray?.map(el =>
                                <Col md={6} className='mb-2'>
                                    <label htmlFor="frontImageUpload" className="form-label fw-bold">
                                        {el.label}
                                    </label>
                                    <div className="d-flex">
                                        <div className="file-input-wrapper">
                                            <input
                                                type="file"
                                                className="form-control file-input"
                                                id="frontImageUpload"
                                                {...register(`cattle.${el.name}`)}
                                            />
                                            <button className="browse-button">Browse</button>
                                        </div>
                                        {watch(`cattle.${el.name}`) && (watch(`cattle.${el.name}`)[0]?.name || (watch(`cattle.${el.name}`)[0]?.url)) && (
                                            <div className="custom-image-preview-container">
                                                <img src={(watch(`cattle.${el.name}`)[0]?.url) ? (watch(`cattle.${el.name}`)[0].url) : (URL.createObjectURL(watch(`cattle.${el.name}`)[0]))} alt="Preview" className="image-preview small-preview" />
                                                <button className="clear-button" onClick={() => setValue(`cattle.${el.name}`, "")}>
                                                    <span role="img" aria-label="clear-icon" className="close-icon"><IoMdCloseCircleOutline size={20} color="red" /></span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col lg={12} className="mt-4">
                        <div className="form-check form-switch d-flex">
                            <input
                                type="checkbox"
                                className="form-check-input mt-2"
                                id="agree"
                                {...register('agree', { required: 'Accept terms and conditions' })}
                            />
                            <label className="form-check-label animalpost-agree-label ms-2 cursor-pointer" htmlFor="agree" onClick={() => setShowTermsModal(true)}>
                                {t("I agree to the terms and conditions")}
                            </label>
                        </div>
                        {errors?.agree &&
                            <small className="text-danger">{errors?.agree?.message}</small>
                        }
                    </Col>
                </Row>
                <div className='checkout-page-btns-div'>
                    <Button
                        className="checkout-page-cancel-btn"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {t("Submit")}
                    </Button>
                    <Button
                        className="checkout-page-ok-btn"
                        type="button"
                        onClick={handleBackClick}
                    >
                        {t("Cancel")}
                    </Button>
                </div>
                <CattleAgreeModal showModal={showTermsModal} closeModal={() => setShowTermsModal(false)} />
            </Container >
            <Footer />
        </>
    );
}

export default AddAnimalForm;
