import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProfileAccordion from "./profileAccordion";
import homeActions from '../../Redux/Home/action';
import userActions from "../../Redux/user/actions";
import OrderAction from '../../Redux/order/action';
import couponActions from "../../Redux/coupon/actions";
import marketActions from '../../Redux/marketPlace/action';
import { DecryptFunction } from "../../utils/cryptoFunction";
import { useDispatch, useSelector } from "react-redux";
import Profile from "./profile";
import AddressBook from "./addressBook";
import MyOrders from "./myOrders";
import MyCoupons from "./myCoupons";
import FarmDetails from "./farmDetails";
import commonActions from "../../Redux/common/actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import SellerProfile from "./sellerProfile";
import subscriptionActions from "../../Redux/subscription/actions";
import { LogEvent } from "../../utils/firebase";

export default function UserProfileIndex() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const Page = searchParams.get('page');

    const { UserProfile } = useSelector(state => state.AuthReducer);

    const [profileSection, setProfileSection] = useState('Profile');

    useEffect(() => {
        setProfileSection(Page);
    }, [Page]);

    useEffect(() => {
        if (localStorage.getItem("auth_user")) {
            const user = DecryptFunction(localStorage.getItem("auth_user"));
            dispatch({
                type: homeActions.GET_USER_PROFILE,
                payload: { id: user },
            });
            dispatch({ type: userActions.GET_USER_ADDRESS, payload: { id: user } });
            dispatch({
                type: OrderAction.GET_ORDER_LIST,
                // payload: "57318",
                payload: user,
            });
            dispatch({
                type: userActions.GET_CATTLE_FARM_DEATILS,
                payload: user,
                // payload: "57318",
            });
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (localStorage.getItem('auth_user_no')) {
            dispatch({
                type: couponActions.GET_COUPON_BY_MOBILENUMBER,
                payload: localStorage.getItem('auth_user_no')
            });
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (UserProfile?.provider_id > 0) {
            dispatch({
                type: marketActions.GET_SELLER_PROFILE,
                payload: UserProfile?.provider_id,
            });
            dispatch({
                type: marketActions.GET_LISTING_DETAILS,
                payload: UserProfile?.provider_id,
            });
            dispatch({
                type: subscriptionActions.GET_USER_SUBSCRIPTIONS,
                payload: { provider_id: UserProfile?.provider_id },
            });
        }
        //eslint-disable-next-line
    }, [UserProfile]);

    const handleAccordion = (section) => {
        if (section === 'Logout') {
            // dispatch({ type: commonActions.SHOW_LOGOUT_MODAL, payload: true });
            LogEvent('Logout_Clicked')
        const id = DecryptFunction(localStorage.getItem('auth_user'));
        if (id) {
            dispatch({ type: userActions.LOG_OUT_PROFILE, payload: id });
        };
        } else {
            setProfileSection(section);
            navigate(`/user-profile?page=${section}`);
        };
    };

    const handleProfileImgUpdate = (e) => {
        LogEvent('Profile_EditProfileImage_Clicked')
        const id = DecryptFunction(localStorage.getItem('auth_user'));
        if (e.target.files && id) {
            dispatch({
                type: userActions.UPLOAD_IMAGE_TO_AWS,
                payload: { files: e.target.files[0], type: 'Profile', data: { id: id } }
            });
        }
    }

    // const handleLogout = () => {
    //     dispatch({ type: commonActions.SHOW_LOGOUT_MODAL, payload: true });
    // };

    const handleLogout = () => {
        LogEvent('Logout_Clicked')
        const id = DecryptFunction(localStorage.getItem('auth_user'));
        if (id) {
            dispatch({ type: userActions.LOG_OUT_PROFILE, payload: id });
        };
    }


    return (
        <Container className="my-5">
        {/* <Container fluid className="my-5 root-container"> */}
            <Row>
                <Col lg={3} md={4} className="user-profile-accordion">
                    <ProfileAccordion
                        handleAccordion={handleAccordion}
                        profileSection={profileSection}
                    />
                </Col>
                <Col lg={9} md={8} className="user-profile-accordion">
                    {profileSection === 'Profile' &&
                        <Profile handleProfileImgUpdate={handleProfileImgUpdate} handleLogout={handleLogout} />
                    }
                    {profileSection === 'Address' &&
                        <AddressBook />
                    }
                    {profileSection === 'Order' &&
                        <MyOrders />
                    }
                    {profileSection === 'Coupon' &&
                        <MyCoupons />
                    }
                    {profileSection === 'Farm' &&
                        <FarmDetails />
                    }
                    {profileSection === 'Seller' &&
                        <SellerProfile />
                    }
                </Col>
            </Row>
        </Container>
    )
}