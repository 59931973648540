import actions from "./action";

const initState = {
    ProductList: [],
    products: [],
    productFilterList: null,
    productfilter:[],
    selectedProductFilter: {
        Category: [],
        Price: [],
        searchedText: null
    },
    allProducts: [],
    productDetails: null,
    relatedProducts: [],
    productRateing:[],
}

const ProductListReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_PRODUCTS_LIST:
            {
                return {
                    ...state,
                    ProductList: action.payload
                }
            }
        case actions.SET_PRODUCT_FILTER:
            {
                return {
                    ...state,
                    productfilter: action.payload
                }
            }
        case actions.SET_PRODUCT_LIST_BY_CATEGORY:
            return {
                ...state,
                products: action.payload
            }
        case actions.SET_PRODUCT_FILTER_LIST:
            return {
                ...state,
                productFilterList: action.payload
            }
        case actions.SELECTED_PRODUCT_FILTER:
            return {
                ...state,
                selectedProductFilter: action.payload
            }
        case actions.SET_ALL_PRODUCT_LIST:
            return {
                ...state,
                allProducts: action.payload
            }
        case actions.SET_PRODUCT_DETAILS:
            return {
                ...state,
                productDetails: action.payload
            }
        case actions.SET_RELATED_PRODUCTS:
            return {
                ...state,
                relatedProducts: action.payload
            }
        case actions.SET_PRODUCTS_RATEING:
            return {
                ...state,
                productRateing: action.payload
            }
        default: return state;
    }
}
export default ProductListReducer;