import React from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

export default function OrderDetailsModal(props) {

    const { t } = useTranslation();

    const { showModal, closeModal } = props;

    const { orderDetails } = useSelector(state => state.OrderReducer);

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            size="md"
        >
            <div className="modal_close_icons"
                onClick={closeModal}
            >
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
            </div>
            <Container className="my-4">
                <h4 className="mb-4 text-center">{t("Your Order Details")}</h4>
                {orderDetails?.length > 0 &&
                    <Row>
                        <Col md={12} className="ps-0">
                            <h6>{t("Total Items")}({orderDetails[0]?.order_items?.length})</h6>
                        </Col>
                        <Row className="order-details-modal-item-row">
                            {orderDetails[0]?.order_items?.map((val, i) =>
                                <>
                                    <Col lg={3} md={3} xs={4} className="d-flex cursor-pointer">
                                        <img
                                            src={val?.image_url}
                                            className="checkout-product-img"
                                            alt="..."
                                        />
                                    </Col>
                                    <Col lg={9} md={9} xs={8} className="my-3 cursor-pointer">
                                        <div className="checkout-product-name">{val?.name}</div>
                                        <small className="checkout-product-qty">{t("Qty")}: {val?.quantity}</small>
                                        <div className="checkout-product-rate">₹{parseFloat(val?.rate) * parseInt(val?.quantity)}</div>
                                    </Col>
                                    <hr />
                                </>
                            )}
                        </Row>
                        <Col md={12} className="mt-4">
                            <h6>{t("Price Details")}</h6>
                            <dl className="row border-bottom">
                                <dt className="col-6 fw-light">{t("Subtotal")}:</dt>
                                <dd className="col-6 text-end">₹{parseFloat(orderDetails[0]?.subtotal)?.toFixed(2)}</dd>

                                <dt className="col-6 fw-light">{t("Discount")}:</dt>
                                <dd className="col-6 text-success text-end">-₹{parseFloat(orderDetails[0]?.discount)?.toFixed(2)}</dd>
                                {(orderDetails[0]?.coupon_discount > 0) &&
                                    <>
                                        <dt className="col-6 fw-light">{t("Coupon Discount")}:</dt>
                                        <dd className="col-6 text-success text-end">-₹{parseFloat(orderDetails[0]?.coupon_discount)?.toFixed(2)}</dd>
                                    </>
                                }
                                {(orderDetails[0]?.shipping_charges > 0) &&
                                    <>
                                        <dt className="col-6 fw-light">{t("Shipping Charges")}:</dt>
                                        <dd className="col-6 text-end">₹{parseFloat(orderDetails[0]?.shipping_charges)?.toFixed(2)}</dd>
                                    </>
                                }
                            </dl>
                            <dl className="row">
                                <dt className="col-6">{t("Total price")}:</dt>
                                <dd className="col-6 text-end">
                                    <strong>₹{parseFloat(orderDetails[0]?.total_amount)?.toFixed(2)}</strong>
                                </dd>
                            </dl>
                            <hr />
                        </Col>
                        <Col md={12} className="mt-1 mb-2">
                            <h6>{t("Delivery Address")}: </h6>
                            <div className="address-book-address mb-2 ms-3 order-details-delivery-address">
                                <div>{`${orderDetails[0]?.address_line1}, ${orderDetails[0]?.address_line2}, ${orderDetails[0]?.address_line3},`}</div>
                                <div>{`${orderDetails[0]?.city} - ${orderDetails[0]?.pincode}.`}</div>
                                <div>{`${orderDetails[0]?.state}, ${orderDetails[0]?.country}`}</div>
                            </div>
                        </Col>
                        <hr />
                        <Col md={12} className="mt-1">
                            <h6>{t("Please contact if any questions")}</h6>
                            <div className="text-center">
                                <Button className="order-details-call-btn"><FaPhoneAlt /> +916383717150</Button>
                            </div>
                        </Col>
                    </Row>
                }
            </Container>
        </Modal>
    )
}