import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/styles/styles.css';
import './assets/styles/responsive.css';
import './assets/styles/styles2.css';
import './assets/styles/styles3.css';
import './assets/styles/Navbar.css';
import './assets/styles/Chatbot.css';
import './assets/styles/MarketPlaceCategory.css';
import './assets/styles/Animals.css'
import './assets/styles/Bloglist.css'
import './assets/styles/bestseller.css'
import './assets/styles/stepper.css'

import './assets/styles/newStyles.css';
import './assets/styles/fonts.css';

import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';

i18n.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: sessionStorage.getItem("YFLanguage") ?? 'en',  // Default language
  resources: {
    en: {
      translation: require('./langugage/en.json'),
    },
    mr: {
      translation: require('./langugage/mr.json'),
    },
    tm: {
      translation: require('./langugage/tm.json'),
    },
  },
});




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

