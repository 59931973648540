import React,{ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import marketActions from '../../Redux/marketPlace/action';
import { FaPlus } from 'react-icons/fa';
import { DecryptFunction } from "../../utils/cryptoFunction";
import { LogEvent } from '../../utils/firebase';
// import SellerProfileUpdateModal from "../modal/sellerProfileUpdateModal";
import AnimalAddModal from "../modal/animalpostmodal";
import { useTranslation } from 'react-i18next';

const SellerIcon = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAnimalAddModal, setShowAnimalAddModal] = useState(false);
  const { t } = useTranslation();
  const { UserProfile } = useSelector(state => state.AuthReducer);

  const handleIconClick = () => {
    LogEvent('LiveStock_Floating_AddCattle_Button_Clicked')
    if (UserProfile?.provider_id > 0) {
      navigate('/Animal-add');
    } else {
      dispatch({ type: marketActions.SHOW_SELLER_REGISTRATION_MODAL, payload: { isOpen: true, page: 'AnimalPost' } })
      // navigate('/Seller-Reg');
    }
  };

  const user = DecryptFunction(localStorage.getItem("auth_user"));
  if (!user) {
    return null;
  };

  return (
    <div className="customFixedIconContainer">
      <div className="botIconContainer">
        <div className="iconInner" onClick={() => setShowAnimalAddModal(true)}>
          <FaPlus />
          <label htmlFor="contained-button-file">
                            <div className="animal-edit-icon">{t("Sell Your Animal")}</div>
                        </label>
        </div>
      </div>
      <AnimalAddModal
        showModal={showAnimalAddModal}
        closeModal={() => setShowAnimalAddModal(false)}
      />
    </div>
  );
};

export default SellerIcon;
