
import React from 'react';
import { Container } from 'react-bootstrap';
import playstore from '../../assets/images/playstore.png';
import mobile from '../../../src/assets/images/new_home_page/Static banner for app install in landing page.png'; // Update the path accordingly
import { t } from 'i18next';
import { LogEvent } from '../../utils/firebase';
import QrCode from "../../assets/images/static YourfarmQR.png"


const AppDownloadSection = () => {
  return (
    <div className='container-fluid position-relative'>
      <img src={mobile} className="mobile-app-image position-absolute" alt="Mobile App" />
      <Container className="position-relative">
        <div className='home-app-download-section-main'>
          <div className="d-flex align-items-center flex-column flex-md-row">
            <div className="home_app_text-container mt-3 mt-md-0 me-md-3" style={{ color: '#232C63' }}>
              <h3>{t("Download Our App")}</h3>
              <p className='mt-3' style={{ fontSize: '18px', color: '#0D1560', lineHeight: '1.5', textAlign: 'justify' }}>
  {t("Introducing our innovative app where you can seamlessly schedule veterinary appointments for your cattle with just a few taps. Additionally, explore a wide range of cattle-related products and farming essentials available for purchase, all in one convenient platform tailored for your livestock needs.")}
</p>

            </div>
            <div className='d-flex justify-content-end align-items-center mt-3'>
              <ul className="footer-col-content footer-col2-section d-flex flex-column align-items-end ml-auto">
                <li
                  className="text-decoration-none cursor-pointer mb-2 mt-5"
                  onClick={() => {
                    LogEvent('Footer_QRCode_Link');
                  }}
                >
                  <img src={QrCode} className='app-download-img-qr' alt="QR Code" />
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.yourfarm" target="_blank" rel="noopener noreferrer" onClick={() => {
                    LogEvent('Home_GooglePlay_Link');
                  }}>
                    <img src={playstore} className='app-download-img-playstore' alt="Google Playstore" />
                  </a>
                </li>
              </ul>
            </div>




          </div>
        </div>
      </Container>
    </div>
  );
};

export default AppDownloadSection;
