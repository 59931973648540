import React from "react";
import { useTranslation } from 'react-i18next';
import { PiCheck } from "react-icons/pi";

export default function StepperComponent({ currentStep }) {

    const { t } = useTranslation();

    const steps = [
        { label: `${t('Order Details')}` },
        { label: `${t('Order Summary')}` },
        { label: `${t('Place order')}` },
    ];

    return (
        <div className="checkout-stepper">
            <div className="checkout-stepper-main-div">
                {steps.map((step, index) => (
                    <div className="checkout-stepper-map-div" key={index}>
                        <div
                            key={index}
                            className={`checkout-step`}
                        >
                            <div className="checkout-step-index">
                                <div className={`checkout-steop-index-value checkout-step-index ${currentStep >= index ? 'checkout-step-active' : ''}`}>
                                    {currentStep >= index ? <PiCheck /> : index + 1}
                                </div>
                            </div>
                            <div className="checout-stepper-label">{step?.label}</div>
                        </div>
                        <div className="checkout-stepper-vertical-line"></div>
                    </div>
                ))}
            </div>
        </div>
    )
}