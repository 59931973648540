import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import market_Action from "../../../Redux/marketPlace/action";


//Founding Team

import Punniamurthy from "../../../assets/images/consulation/founding_team_crop/Frame 1410265094.png"
import Vijayakumar from "../../../assets/images/consulation/founding_team_crop/Frame 1410265090.png"
import kannan from "../../../assets/images/consulation/founding_team_crop/Frame 1410265093.png"
import Janani from "../../../assets/images/consulation/founding_team_crop/Jananii.png"

//Core team images
import Anwesha from "../../../assets/images/consulation/Core team/Anwesha sarkar - CRM Team lead.png"
import Kalaiyarasi from "../../../assets/images/consulation/Core team/Kalaiyarasi swaminathan - Digital marketing Manager.png"
import Maheswari from "../../../assets/images/consulation/Core team/Maheswari - finance and accounts.png"
import Praveen from "../../../assets/images/consulation/Core team/Praveen patil -.png"
import Ragunathan from "../../../assets/images/consulation/Core team/Ragunathan - Sales Manager.png"
import Aishwarya from "../../../assets/images/consulation/Core team/Aishwarya Desai (2).png"
import naveen from "../../../assets/images/consulation/Core team/naveen (1).png"

import grid1 from "../../../assets/images/grid-1.png"
import grid2 from "../../../assets/images/grid-2.png"
import grid3 from "../../../assets/images/grid-3 .png"

import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const CommonDiseasesPage = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { homeContent } = useSelector(state => state.AuthReducer);
    let [Team, setTeam] = useState([]);

    useEffect(() => {
        dispatch({
            type: market_Action.GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE, payload: 1
        });
    }, [dispatch]);

    useEffect(() => {
        if (homeContent?.length > 0) {
            setTeam(homeContent?.filter(val => {
                if (val?.type === 'Team' && (val?.img_url && val?.title)) {
                    return val;
                }
            }))
        }
    }, [homeContent]);


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const products = [
        { id: 1, image_url: Vijayakumar, name: 'Dr. Vijayakumar Ramalingam ', years: '14+ years field Veterinary experience', desginiation: 'CEO', link: "https://www.linkedin.com/in/dr-vijayakumar-ramalingam-13990596/" },
        { id: 3, image_url: Punniamurthy, name: 'Prof Dr N Punniamurthy ', years: '39 years of experience in Veterinary domain', desginiation: 'Chief Veterinary Officer' },
        { id: 4, image_url: kannan, name: 'Mr. Kannan ', years: '25+ years of experience in building Enterprise grade IT applications and bringing them to market', desginiation: 'CTO', link: "https://www.linkedin.com/in/shenbaga-kannan-262429a/" },
        { id: 5, image_url: Janani, name: 'Mrs.Janani Shanthamoorthy ', years: '14 years experience in family livestock farming. An Engineering Graduate with 5-years of teaching experience', desginiation: 'BDE', link: "https://www.linkedin.com/in/janani-santhamoorthy-741068204/" }
    ];
    const team = [
        { id: 1, img_url: Praveen, title: 'Mr. Praveen Patil', desginiation: 'VP - Sales and Marketing', link: "https://www.linkedin.com/in/dr-praveen-patil-705793b6/" },
        { id: 3, img_url: Ragunathan, title: 'Mr. Ragunathan', desginiation: 'Manager - Sales' },
        { id: 2, img_url: Kalaiyarasi, title: 'Mrs. Kalaiyarasi', desginiation: 'Manager - Marketing', link: "https://www.linkedin.com/in/kalaiyarasi-samynathan-34041477/" },
        { id: 4, img_url: Aishwarya, title: 'Ms. Aishwarya Desai ', desginiation: 'Manager - CRM', link: "https://www.linkedin.com/in/aishwarya-desai-73536315b/" },
        { id: 5, img_url: Maheswari, title: 'Mrs. Maheswari', desginiation: 'HR and Finance', link: "https://www.linkedin.com/in/maheswari-k-946b46291/" },
        { id: 6, img_url: Anwesha, title: 'Ms. Anwesha', desginiation: 'Team Lead - CRM', link: "https://www.linkedin.com/in/anwesha-sarkar1997/" },
        { id: 7, img_url: naveen, title: 'Mr. Naveen', desginiation: 'Ast Manager - Stores and Dispatch',link:"https://www.linkedin.com/in/naveen-kumar-722947188/" }
    ];


    return (
        <>
            <div className="container-fluid farm-equipments-team mt-5">
                <Container>
                    <div className="page_heading_div_cls">
                        {t("Founding Team")}
                    </div>
                    <Slider {...settings} className="slider-arrow-custom-style-for-related-products">
                        {products?.slice(0, 10)?.map((product, index) => (
                            <div key={index}>
                                <a href={product.link} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={product.image_url}
                                        className="Team-common-disease-img"
                                        alt={`Product ${index + 1}`}
                                    />
                                    <div className="blue-background">
                                        {product.name}
                                        <div className="pink-designation">{product.desginiation}</div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </Slider>
                </Container>

            </div>
            <div>
                <Container>
                    <div className="page_heading_div_cls mt-3 mb-3">
                        {t("Core team")}
                    </div>
                    <div className="related-products-container">
                        {team?.map((team, index) => (
                            <div key={index} className="related-product-card">
                                <a href={team.link} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={team.img_url}
                                        className="Core-Team-common-disease-img"
                                        alt={`Product ${index + 1}`}
                                    />
                                    <div className="core-team-blue-background">
                                        <div className="unique-team-name">{team.title}</div>
                                        <div className="pink-designation">{team.desginiation}</div>
                                        <div>{team.years}</div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>

        </>
    );
};

export default CommonDiseasesPage;
