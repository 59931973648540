import subscriptionActions from "./actions";

const initialState = {
    subscriptionValues: {},
    userSubscriptions: [],
};

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case subscriptionActions.SUBSCRIPTION_VALUES:
            return {
                ...state,
                subscriptionValues: action.payload
            }
        case subscriptionActions.SET_USER_SUBSCRIPTIONS:
            return {
                ...state,
                userSubscriptions: action.payload
            }
        default:
            return state;
    }
};

export default subscriptionReducer;