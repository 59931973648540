import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { PiPhoneFill } from "react-icons/pi";
import DisclaimerModal from "../modal/disclaimerModal";

export default function List({ fetchedData }) {

    const { t } = useTranslation();

    const { currentLanguage } = useSelector(state => state.commonReducer);

    const [contactViewable, setContactViewable] = useState(false);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState({ isOpen: false, data: null });

    return (
        <>
            {fetchedData?.length > 0 ?
                <>
                    {fetchedData?.map((provider, i) =>
                        <div className="d-flex marketplace_services_card_div" key={i}>
                            <img src={provider.logo} alt="" className="marketplace_services_img_cls" />
                            <div className="marketplace_services_content_div">
                                <div className="product-grid-view-name">{provider?.provider_lang?.find(lang => lang?.language_code === currentLanguage)?.provider_name || provider.provider_name}</div>
                                <div className="marketplace_services_location_div"><span className="marketplace_services_location_label_span">{t("Location")}:</span> {provider.address_line1}, {provider.address_line2}, {provider.city}</div>
                                {contactViewable ?
                                    <div className="marketplace_view_mobileno">
                                        <PiPhoneFill className="marketplace-cattle-btn-icons" /> {provider.mobile_no}
                                    </div> :
                                    <div className="service-provider-button">
                                    <Button 
                                        size="sm"
                                        onClick={() => setShowDisclaimerModal({ isOpen: true, data: null })}
                                    >
                                        <PiPhoneFill className="marketplace-cattle-btn-icons" /> {t("View Contact")}
                                    </Button>
                                </div>
                                }
                            </div>
                        </div>
                    )}
                </> :
                <div className="product-notfound-cls">
                    <h4>{t("No Services Available")}</h4>
                </div>
            }
            <DisclaimerModal
                showModal={showDisclaimerModal?.isOpen}
                closeModal={() => setShowDisclaimerModal({ isOpen: false, data: null })}
                agreeClick={() => {
                    setContactViewable(true);
                    setShowDisclaimerModal({ isOpen: false, data: null });
                }}
            />
        </>
    )
}