import React from "react";
import Navbar from '../components/commonComponents/Navbar/Navbar';
import Footer from "../components/commonComponents/footer";
import UserProfileIndex from "../components/user-Profile";
import { useSelector } from "react-redux";
import PageLoader from "../common/pageLoader";

export default function UserProfile() {

    const { pageLoader } = useSelector(state => state.commonReducer);

    return (
        <div className={`${pageLoader && "body-overflow-hide"}`}>
            {pageLoader &&
                <PageLoader />
            }
            <Navbar />
            <UserProfileIndex />
            <Footer />
        </div>
    )
}