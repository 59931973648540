import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIosNew } from "react-icons/md";
import { GiFarmer } from "react-icons/gi";
import { PiStethoscopeBold } from "react-icons/pi";
import { RiHandCoinFill } from "react-icons/ri";
import { SiHappycow } from "react-icons/si";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import inputimg from "../../assets/images/new_home_page/Input suppliers.png";
import doctorimg from "../../assets/images/new_home_page/Livestocks.png";
import treatmentimg from "../../assets/images/new_home_page/Consultations.png";
import farmerimg from "../../assets/images/new_home_page/Ourfarmers.png";

const StaticPage = () => {

  const { homeContent } = useSelector((state) => state.AuthReducer);

  const { currentLanguage } = useSelector((state) => state.commonReducer);

  let [count, setCount] = useState();

  const iconsArray = [
    {
      title: "Input Suppliers",
      // icon: (
      //   <RiHandCoinFill
      //     style={{
      //       backgroundColor: "#ffd4c4", borderRadius: "50%", color: "black", padding: "15px", fontSize: "95px",
      //     }}
      //   />
      // ),
      icon: (
        <img
          src={inputimg}
          style={{
            // backgroundColor: "#ffd4c4",
            borderRadius: "50%",
            padding: "10px",
            width: "125.39px",
            height: "116px",
          }}
          alt="Input Suppliers"
        />
      ),
    },
    {
      title: "Consultations",
      icon: (
        // <PiStethoscopeBold
        //   style={{
        //     backgroundColor: "#ffeca4", borderRadius: "50%", color: "black", padding: "15px", fontSize: "95px",
        //   }}
        // />
        <img
        src={doctorimg}
        style={{
          // backgroundColor: "#ffd4c4",
          borderRadius: "50%",
          padding: "10px",
          width: "125.39px",
          height: "116px",
        }}
        alt="Input Suppliers"
      />
      ),
    },
    {
      title: "Livestock",
      icon: (
        // <SiHappycow
        //   style={{
        //     backgroundColor: "#d8ccec", borderRadius: "50%", color: "black", padding: "15px", fontSize: "95px",
        //   }}
        // />
        <img
        src={treatmentimg}
        style={{
          // backgroundColor: "#ffd4c4",
          borderRadius: "50%",
          padding: "10px",
          width: "125.39px",
          height: "116px",
        }}
        alt="Input Suppliers"
      />
      ),
    },
    {
      title: "Our Farmers",
      icon: (
       
        <img
        src={farmerimg}
        style={{
          // backgroundColor: "#ffd4c4",
          borderRadius: "50%",
          padding: "10px",
          width: "125.39px",
          height: "116px",
        }}
        alt="Input Suppliers"
      />
      ),
    },
  ];

  useEffect(() => {
    if (homeContent?.length > 0) {
      setCount(
        homeContent
          ?.filter((val) => val?.type === "statistics")
          .map((val, i) => {
            let finalLanguage = val?.language?.find(
              (lang) => lang?.language_code === currentLanguage
            );
            return { ...val, finalLanguage: finalLanguage };
          })
      );
    }
  }, [homeContent, currentLanguage]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <MdOutlineArrowBackIosNew />,
    nextArrow: <MdOutlineArrowForwardIos />,
  };

  return (
    <div
      className="container-fluid text-center farm-equipments"
      style={{ backgroundColor: "#fff",height:"257px" }}
    >
      <Container>
        <Slider {...settings} className="slider-arrow-custom-style-for-related-products">
          {count?.map((val, i) => (
            <div key={i}>
              <div style={{ fontSize: "80px", color: "#474747",marginLeft:"70px" }}>
                {
                  iconsArray?.find(
                    (icon) =>
                      icon?.title ===
                      val?.language?.find((lan) => lan?.language_code === "en")
                        ?.contents[0]?.designation
                  )?.icon
                }
              </div>
             
              <p
                style={{
                  color: "#0D1560",
                  fontWeight: "800",
                  fontSize: "26px",
                  // height:"100px",
                }}
              >
                {val?.finalLanguage?.contents[0]?.experience || val?.language[0]?.contents[0]?.experience}
              </p>
              <div className="home-count-design">
                {val?.finalLanguage?.contents[0]?.designation || val?.language[0]?.contents[0]?.designation}
              </div>
            </div>
          ))}
        </Slider>
      </Container>
    </div>

  );
};

export default StaticPage;
