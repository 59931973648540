import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ImageViewer({ showModal, onCancel, onSave, images,productName }) {

    return (
        <Modal show={showModal} onHide={onCancel} backdrop="static" size="md">
        <Modal.Header closeButton>
            <Modal.Title>{productName}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {images?.length > 0 ? 
                <img src={images[0].url} alt="First Image" style={{ maxWidth: '100%', maxHeight: '360px' }} />
                : <></>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onCancel}>
                Close 
            </Button>
        </Modal.Footer>
    </Modal>
    
        
    );
}

export default ImageViewer;
