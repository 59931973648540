const farmActions = {
    GET_FARM_TYPES: 'GET_FARM_TYPES',
    SET_FARM_TYPES: 'SET_FARM_TYPES',
    GET_BREAD_TYPES: 'GET_BREAD_TYPES',
    SET_BREAD_TYPES: 'SET_BREAD_TYPES',
    CREATE_SELLER_USER: 'CREATE_SELLER_USER',
    SET_SELECTED_ADDRESS :"SET_SELECTED_ADDRESS",
    GET_CATTLE_DISEASE:"GET_CATTLE_DISEASE",
    SET_CATTLE_DISEASE:"SET_CATTLE_DISEASE",
    CREATE_ANIMAL_POST:"CREATE_ANIMAL_POST",
    SET_ANIMAL_ADD_POST:"SET_ANIMAL_ADD_POST",
    ADD_TO_ANIMAL_IMAGE_POST: 'ADD_TO_ANIMAL_IMAGE_POST',
    GET_TO_ANIMAL_IMAGE_POST: 'GET_TO_ANIMAL_IMAGE_POST',
    SET_TO_ANIMAL_IMAGE_POST: 'SET_TO_ANIMAL_IMAGE_POST',
    ADD_TO_IMAGE_ARRAY: 'ADD_TO_IMAGE_ARRAY',
    GET_EDIT_ANIMAL: 'GET_EDIT_ANIMAL',
    SET_EDIT_ANIMAL: 'SET_EDIT_ANIMAL',
    GET_ANIMAL_SHOWN: 'GET_ANIMAL_SHOWN',
    SET_ANIMAL_SHOWN: 'SET_ANIMAL_SHOWN',
    UPDATE_ANIMAL_POST:"UPDATE_ANIMAL_POST"
    
};

export default farmActions;