import React from "react";
import { useSelector } from "react-redux";
import { FaCirclePlay } from "react-icons/fa6";
import noimg from '../../assets/images/noImage.jpg';
import cowimg from '../../assets/images/cow.jpg';
import buffaloimg from '../../assets/images/buffaloimg.jpg';
import goatimg from '../../assets/images/goat.jpg';
import sheepimg from '../../assets/images/sheep.jpg';
import poultryimg from '../../assets/images/poultryimg.jpg';
import videoicon from '../../assets/images/videoicon.jpg';



export default function AnimalImageGrid(props) {

    const { images, handleShowImagesModal } = props;

    const { cattleParentCatgory, marketPlaceSelectedLocation } = useSelector(state => state.MarketPlaceReducer);

    return (
        <div className={`marketplace-cattle-images-main-div ${images?.length > 0 && "cursor-pointer"}`} >
            {images?.length === 4 &&
                <div className="d-flex marketplace-4img-main-div">
                    <div className="marketplace-4img-col-1">
                        <div>
                            <img src={images.find(val => val?.type === 'front')?.url || noimg} alt="" className="marketplace-4img-front market_place_animals_imgs_borderradious"
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'front')])} />
                        </div>
                        <div className="d-flex">
                            <img src={images?.find(val => val?.type === "side")?.url || noimg} alt="" className="marketplace-4img-side market_place_animals_imgs_borderradious"
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'side')])} />
                            <img src={images?.find(val => val?.type === "body")?.url || noimg} alt="" className="marketplace-4img-body market_place_animals_imgs_borderradious"
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'body')])} />
                        </div>
                    </div>
                    <div className="marketplace-4img-col-2">
                        <div>
                            <img
                                src={(cattleParentCatgory?.find(val => val?.id === marketPlaceSelectedLocation?.Category)?.title?.toLowerCase() === 'cattle') ? cowimg :
                                    (cattleParentCatgory?.find(val => val?.id === marketPlaceSelectedLocation?.Category)?.title?.toLowerCase() === 'buffalo') ? buffaloimg :
                                        (cattleParentCatgory?.find(val => val?.id === marketPlaceSelectedLocation?.Category)?.title?.toLowerCase() === 'goat') ? goatimg :
                                            (cattleParentCatgory?.find(val => val?.id === marketPlaceSelectedLocation?.Category)?.title?.toLowerCase() === 'sheep') ? sheepimg :
                                                (cattleParentCatgory?.find(val => val?.id === marketPlaceSelectedLocation?.Category)?.title?.toLowerCase() === 'poultry') ? poultryimg :
                                                    videoicon}
                                alt=""
                                className="marketplace-4img-video market_place_animals_imgs_borderradious"
                                onClick={() => handleShowImagesModal([images.find(val => val?.type === 'video')])}
                            />
                            <FaCirclePlay className="marketplace-play-icon" />
                        </div>
                    </div>
                </div>
            }
            {images?.length === 3 &&
                <div className="d-flex marketplace-3img-main-div">
                    {images.find(val => val?.type === 'front')?.url &&
                        <img
                            src={images.find(val => val?.type === 'front')?.url || noimg}
                            alt=""
                            className={`marketplace-3img-front market_place_animals_imgs_borderradious ${images.findIndex(val => val?.type === 'front') === 0 ? "marketplace-cattle-img-first" : images.findIndex(val => val?.type === 'front') === images?.length - 1 ? "marketplace-cattle-img-last" : ""}`}
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'front')])}
                        />
                    }
                    {images?.find(val => val?.type === "side")?.url &&
                        <img
                            src={images?.find(val => val?.type === "side")?.url || noimg}
                            alt=""
                            className={`marketplace-3img-front market_place_animals_imgs_borderradious ${images?.findIndex(val => val?.type === "side") === 0 ? "marketplace-cattle-img-first" : images?.findIndex(val => val?.type === "side") === images?.length - 1 ? "marketplace-cattle-img-last" : ""}`}
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'side')])}
                        />
                    }
                    {images?.find(val => val?.type === "body")?.url &&
                        <img
                            src={images?.find(val => val?.type === "body")?.url || noimg}
                            alt=""
                            className={`marketplace-3img-front market_place_animals_imgs_borderradious ${images?.findIndex(val => val?.type === "body") === 0 ? "marketplace-cattle-img-first" : images?.findIndex(val => val?.type === "body") === images?.length - 1 ? "marketplace-cattle-img-last" : ""}`}
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'body')])}
                            />
                    }
                  
                     {images?.find(val => val?.type === "video")?.url && (
                        <div className="marketplace-3img-col-2">
                            <video
                                src={images.find(val => val?.type === 'video')?.url}
                                controls
                                className="marketplace-3img-video market_place_animals_imgs_borderradious"
                                onClick={() => handleShowImagesModal([images.find(val => val?.type === 'video')])}
                            />
                        </div>
                    )}
                </div>
            }
            {images?.length === 2 &&
                <div className="d-flex marketplace-2img-main-div">
                    {images.find(val => val?.type === 'front')?.url &&
                        <img
                            src={images.find(val => val?.type === 'front')?.url || noimg}
                            alt=""
                            className={`marketplace-2img-front market_place_animals_imgs_borderradious ${images.findIndex(val => val?.type === 'front') === 0 ? "marketplace-cattle-2img-first" : images.findIndex(val => val?.type === 'front') === images?.length - 1 ? "marketplace-cattle-2img-last" : ""}`}
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'front')])}
                        />
                    }
                    {images?.find(val => val?.type === "side")?.url &&
                        <img
                            src={images?.find(val => val?.type === "side")?.url || noimg}
                            alt=""
                            className={`marketplace-2img-front market_place_animals_imgs_borderradious ${images?.findIndex(val => val?.type === "side") === 0 ? "marketplace-cattle-2img-first" : images?.findIndex(val => val?.type === "side") === images?.length - 1 ? "marketplace-cattle-2img-last" : ""}`}
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'side')])}
                        />
                    }
                    {images?.find(val => val?.type === "body")?.url &&
                        <img
                            src={images?.find(val => val?.type === "body")?.url || noimg}
                            alt=""
                            className={`marketplace-2img-front market_place_animals_imgs_borderradious ${images?.findIndex(val => val?.type === "body") === 0 ? "marketplace-cattle-2img-first" : images?.findIndex(val => val?.type === "body") === images?.length - 1 ? "marketplace-cattle-2img-last" : ""}`}
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'body')])}
                        />
                    }
                   
                     {images?.find(val => val?.type === "video")?.url && (
                        <div className="marketplace-3img-col-2">
                            <video
                                src={images.find(val => val?.type === 'video')?.url}
                                controls
                                className="marketplace-3img-video market_place_animals_imgs_borderradious"
                                onClick={() => handleShowImagesModal([images.find(val => val?.type === 'video')])}
                            />
                        </div>
                    )}
                </div>
            }
            {images?.length === 1 &&
                <div className="d-flex marketplace-1img-main-div">
                    {images.find(val => val?.type === 'front')?.url &&
                        <img
                            src={images.find(val => val?.type === 'front')?.url || noimg}
                            alt=""
                            className={`marketplace-1img-front market_place_animals_imgs_borderradious`}
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'front')])}
                        />
                    }
                    {images?.find(val => val?.type === "side")?.url &&
                        <img
                            src={images?.find(val => val?.type === "side")?.url || noimg}
                            alt=""
                            className={`marketplace-1img-front market_place_animals_imgs_borderradious`}
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'side')])}
                        />
                    }
                    {images?.find(val => val?.type === "body")?.url &&
                        <img
                            src={images?.find(val => val?.type === "body")?.url || noimg}
                            alt=""
                            className={`marketplace-1img-front market_place_animals_imgs_borderradious`}
                            onClick={() => handleShowImagesModal([images.find(val => val?.type === 'body')])}
                        />
                    }
                   
                     {images?.find(val => val?.type === "video")?.url && (
                        <div className="marketplace-3img-col-2">
                            <video
                                src={images.find(val => val?.type === 'video')?.url}
                                controls
                                className="marketplace-3img-video market_place_animals_imgs_borderradious"
                                onClick={() => handleShowImagesModal([images.find(val => val?.type === 'video')])}
                            />
                        </div>
                    )}
                </div>
            }
            {images?.length === 0 &&
                <div className="d-flex marketplace-1img-main-div">
                    <img src={noimg} alt="" className="marketplace-1img-front market_place_animals_imgs_borderradious" />
                </div>
            }
        </div>
    )
}