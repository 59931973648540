import axios from "axios";
import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";
import { API_URL } from "../../utils/constant";
import { EncryptFunction } from "../../utils/cryptoFunction";
import commonActions from "../common/actions";
import actions from "./action";
import checkoutActions from "../checkout/actions";

const HomeSaga = function* () {
  yield all([
    yield takeEvery(actions.SEND_OTP, LoginValidate),
    yield takeEvery(actions.VERIFY_OTP, VerifyOTP),
    yield takeEvery(actions.GET_USER_PROFILE, UserProfile),
    yield takeEvery(actions.GET_PRODUCTS_HOME, ProductsHome),
    yield takeEvery(actions.GET_HOME_CONTENTS, getHomeContent),
    // yield takeEvery(actions.GET_USER_PROFILE, UserFarmPlace),
    yield takeEvery(actions.GET_USER_UPDATE_PROFILE, UpdateUserProfile),
    yield takeEvery(actions.GET_HOME_MARKETPLACE_CATTLE_LIST, getHomeMarketplaceCattleList),
    yield takeEvery(actions.GET_PRIVACY_POLICY,Privacy_Policy),
  ]);
};

const LoginValidate = function* (data) {
  const { payload } = data;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/v1/login/otp`, payload)
    );
    const id = result.data.data.id;
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({ type: actions.HOME_RESPONSE, payload: result?.data });
    yield put({
      type: actions.STORE_ID_ACTION,
      payload: { id },
    });
    // yield put({ type: 'STORE_ID_ACTION', payload: { id } });

  } catch (error) {
    console.log("Error in catch", error);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const VerifyOTP = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/v1/login/verify_otp`, payload)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.code === 200 ? "success" : "failed",
        message: result?.data?.msg,
      },
    });
    if (result?.data?.code === 200) {
      var stoken = result?.headers?.authorization;
      localStorage.setItem("token", stoken.replace("Bearer ", ""));
      sessionStorage.removeItem('temp_Token');
      // setAuthToken(`Bearer ${localStorage.getItem("token")}`); // Assuming setAuthToken is defined elsewhere
      const user = EncryptFunction(result?.data?.data?.id);
      localStorage.setItem("auth_user", user);
      const mobileNumber = EncryptFunction(result?.data?.data?.mobile_no);
      localStorage.setItem('auth_user_no', mobileNumber);
      const provider_id = EncryptFunction(result?.data?.data?.provider_id);
      localStorage.setItem('auth_user_provider_id', provider_id);
      yield put({ type: commonActions.SET_SHOW_LOGIN_MODAL, payload: false });
      //login before add to cart
      if (sessionStorage.getItem('addtocart') === 'Login') {
        const { userAddress } = yield select(state => state.userReducer);
        if (userAddress?.length === 0) {
          yield put({ type: commonActions.SET_SHOW_USER_ADDRESS_MODAL, payload: true });
        }
        sessionStorage.setItem('addtocart', 'Address');
      };
      window.location.reload();
      yield put({ type: actions.HOME_RESPONSE, payload: null });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { show: false, message: null, status: null },
    });
  } catch (error) {
    console.log("Error in catch", error);
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
  }
};

const UserProfile = function* (data) {
  const { payload } = data;
  const { UserProfile } = yield select(state => state.AuthReducer);
  if ((UserProfile?.length === 0 && !payload?.type) || payload?.type) {
    yield put({ type: commonActions.PAGE_LOADER, payload: true });
    try {
      const result = yield call(() =>
        axios.get(
          `${API_URL}/v1/users/${payload?.id}`
        )
      );
      yield put({ type: commonActions.PAGE_LOADER, payload: false });
      yield put({ type: actions.SET_USER_PROFILE, payload: result?.data?.data });
    } catch (error) {
      console.log("Error in catch", error);
    }
  }
  yield put({ type: commonActions.PAGE_LOADER, payload: false });
};

const UpdateUserProfile = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/v1/users/${payload}`, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`,
        // },
      })
    );
    yield put({
      type: actions.SET_USER_UPDATE_PROFILE,
      payload: result?.data
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
};


const ProductsHome = function* (data) {
  const { payload } = data;
  try {
    yield call(() =>
      axios.post(`${API_URL}/v1/users/${payload}`)
    );
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const getHomeContent = function* () {
  const { homeContent } = yield select(state => state.AuthReducer);
  if (homeContent?.length === 0) {
    yield put({ type: commonActions.PAGE_LOADER, payload: true });
    try {
      const result = yield call(() =>
        axios.get(`${API_URL}/v1/resources`)

      );
      if (result?.data?.code === 200) {
        // let finalResult = [];
        // result?.data?.data?.map(val => {
        //   if(finalResult?.)
        // })
        yield put({ type: actions.SET_HOME_CONTENTS, payload: result?.data?.data });
        //eslint-disable-next-line
        let paymentypelist = result?.data?.data?.filter(val => {
          if (val?.type === 'payment') {
            return val;
          }
        });
        //eslint-disable-next-line
        let pilot_no = result?.data?.data?.filter(val => {
          if (val.type === 'pilot_no') {
            return val;
          }
        });
        if (pilot_no?.length > 0 && !sessionStorage.getItem('pilot_no')) {
          sessionStorage.setItem('pilot_no', EncryptFunction(pilot_no[0]?.link));
        }
        yield put({ type: checkoutActions.SET_PAYMENT_TYPE_LIST, payload: paymentypelist });
      }
    } catch (err) {
      console.log(err);
    };
  }
  yield put({ type: commonActions.PAGE_LOADER, payload: false });
};



const Privacy_Policy = function* () {
  try{
    const result = yield call (()=>
      axios.get (`${API_URL}/v1/admin/cms`)
    );
    yield put({
      type: actions.SET_PRIVACY_POLICY,
      payload: result?.data
  });

    
  } catch (err) {
    console.log(err);
  };
}

const getHomeMarketplaceCattleList = function* () {

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/admin/cattle_listings/all`)
    );
    if (result?.data?.data?.length > 0) {
      yield put({ type: actions.SET_HOME_MARKETPLACE_CATTLE_LIST, payload: result?.data?.data?.slice(0, 10) });
    }
  } catch (err) {
    console.log(err);
  };
};

export default HomeSaga;
