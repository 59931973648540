import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import farmCowImg from '../../assets/images/farm-cow.webp';
import farmBuffaloImg from '../../assets/images/farm-buffalo.png';
import farmSheepImg from '../../assets/images/farm-sheep.jpg';
import farmGoatImg from '../../assets/images/farm-goat.jpg';
import farmPoultryImg from '../../assets/images/farm-poultry.jpg';
import farmPrtsImg from '../../assets/images/farm-pets.jpg';
import SelectAddressModal from "./selectAddressModal";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DecryptFunction } from "../../utils/cryptoFunction";
import userActions from "../../Redux/user/actions";
import { useTranslation } from 'react-i18next';
import { LogEvent } from "../../utils/firebase";
import { AlphabetsValidation, handleEmailValidation } from "../../utils/validation";

export default function FarmDetails() {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { CattleFarmDeatils } = useSelector((state) => state.userReducer);
    const { userAddress } = useSelector(state => state.userReducer);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [showAddressModal, setShowAddressModal] = useState(false);
    const [farmAddress, setFarmAddress] = useState();
    const [showUpdate, setShowUpdate] = useState(false);
    const [addressErr, setAddressErr] = useState(false);

    useEffect(() => {
        if (userAddress?.length > 0) {
            if (userAddress?.find(val => val?.default_address === 1)) {
                setFarmAddress(userAddress?.find(val => val?.default_address === 1));
            } else {
                setFarmAddress(userAddress[0]);
            }
        };
    }, [userAddress]);

    useEffect(() => {
        handleUpdateFarm()
    }, [])

    const handleShowAddressModal = () => {
        setShowAddressModal(true);
    };

    const handleAddress = (address) => {
        setFarmAddress(address);
        setShowAddressModal(false);
    };

    const handleUpdateFarm = () => {
        LogEvent('Profile_UpdateYourFarm_Clicked')
        setShowUpdate(true);
        if (CattleFarmDeatils?.length > 0) {
            reset({ ...CattleFarmDeatils[0] });
            if (CattleFarmDeatils[0]?.address_line1) {
                setFarmAddress({
                    address_line1: CattleFarmDeatils[0]?.address_line1,
                    address_line2: CattleFarmDeatils[0]?.address_line2,
                    address_line3: CattleFarmDeatils[0]?.address_line3,
                    city: CattleFarmDeatils[0]?.city,
                    pincode: CattleFarmDeatils[0]?.pincode,
                    state: CattleFarmDeatils[0]?.state,
                    country: CattleFarmDeatils[0]?.country,
                });
            }
        } else {
            reset({
                cows: 0, cow_calf: 0, cow_heifer: 0, milching_cows: 0, dry_cows: 0, buffalo: 0,
                baffalo_calf: 0, sheeps: 0, goats: 0, poultry: 0, pets: 0, total_land_size: 0
            });
        }
    };


    const handleSaveFarm = (data) => {
        if (farmAddress?.address_line1) {
            setAddressErr(false);
            const user_id = DecryptFunction(localStorage.getItem('auth_user'));
            if (user_id) {
                let formData = { ...data, user_id: user_id }, finalInput = {};
                let reduceObj = Object.entries(formData)?.reduce((acc, [k, v]) => v ? { ...acc, [k]: v } : acc, {})
                if (farmAddress) {
                    finalInput = { ...reduceObj, ...farmAddress }
                } else {
                    finalInput = { ...reduceObj };
                };
                if (CattleFarmDeatils?.length === 0) {
                    dispatch({ type: userActions.CREATE_USER_FARM, payload: finalInput });
                } else {
                    dispatch({ type: userActions.UPDATE_USER_FARM, payload: { id: CattleFarmDeatils[0]?.id, data: finalInput } })
                };
            }
            setTimeout(() => {
                setShowUpdate(false);
            }, 1000);
        } else {
            setAddressErr(true);
        };
    };

    return (
        <>
            <div>
                <div className="row container d-flex align-items-center">
                    <div className="col page_heading_farm-deatils">
                        {t('Farm Details')}
                    </div>
                    <div className="col button-container-userprofile">
                        <Button onClick={handleSubmit(handleSaveFarm)}>{t("Save Changes")}</Button>
                    </div>
                </div>
                <>
                   
                    <Row className="user-farmdeatil-tab1-details row-padding-remover">
                        <div className="col-lg-12">
                            <div className="row">
                                <div class="col-md-6 my-3">
                                    <div class="col-12 mb-4">
                                        <div class="col-md-10 mb-4">
                                            <label className="form-label form-label-custom-style">{t("Farm Name")}</label>
                                            <input
                                                className="form-control input-bg-none farm-head-input-box"
                                                {...register("name")}
                                                onInput={AlphabetsValidation}
                                            />
                                        </div>
                                        <div class="col-md-10">
                                            <label className="form-label form-label-custom-style">{t("Land Size(In Acres)")}</label>
                                            <input
                                                className="form-control input-bg-none farm-head-input-box"
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                {...register("total_land_size", {
                                                    valueAsNumber: true,
                                                    pattern: {
                                                        value: /^(0|[1-9]\d*)(\.\d+)?$/
                                                    },
                                                })}
                                            />                    </div>

                                    </div>
                                </div>
                                <div class="col-md-6 mb-4 my-3 user-profile-cattle-row">
                                    <label className="form-label form-label-custom-style">{t("Address")}</label>
                                    <div>
                                        {farmAddress &&
                                            <div className="farm-address-book-address mb-2 text-success">
                                                <div>{`${farmAddress?.address_line1}, ${farmAddress?.address_line2}, ${farmAddress?.address_line3},`}</div>
                                                <div>{`${farmAddress?.city} - ${farmAddress?.pincode}.`}</div>
                                                <div>{`${farmAddress?.state}, ${farmAddress?.country}`}</div>
                                            </div>
                                        }
                                        <div className="update-farmdetail-profile-text" onClick={handleShowAddressModal}>
                                            {farmAddress ? t("Change Address >") : t("Select Address")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Row>
                    <Row className="user-farmdeatil-tab1-details row-padding-remover mt-3">
                        <Col md={12}>
                           
                            <Row className="user-farmdeatil-tab1-details row-padding-remover">
      <Col lg={12}>
        <Row className="my-2">
          <Col lg={12} md={12} sm={12} className="mb-3 d-flex align-items-center">
            <div className="user-profile-cattle-title">{t("Animal Count")}</div>
          </Col>

          {[
            { label: t("Cow"), name: "cows", error: errors?.cows },
            { label: t("Cow calf"), name: "cow_calf", error: errors?.cow_calf },
            { label: t("Cow heifer"), name: "cow_heifer", error: errors?.cow_heifer },
            { label: t("Milching cows"), name: "milching_cows", error: errors?.milching_cows },
            { label: t("Dry cows"), name: "dry_cows", error: errors?.dry_cows },
          ].map((item, index) => (
            <Col lg={6} md={12} className="mb-3 d-flex align-items-center" key={index}>
              <div className="form-group cow-count-container">
                <label className="form-label form-label-Animal-custom-style">{item.label}</label>
                <input
                  className="form-control ifarm-detail-box farm-input-box uniform-input"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  {...register(item.name, {
                    maxLength: {
                      value: 3,
                      message: "Enter a valid count",
                    },
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter a valid count",
                    },
                  })}
                />
                <small className="text-danger mt-1">{item.error?.message}</small>
              </div>
            </Col>
          ))}

          <Col lg={12} >
            <hr />
          </Col>

          {[
            { label: t("Buffalo"), name: "buffalo", error: errors?.buffalo },
            { label: t("Buffalo calf"), name: "baffalo_calf", error: errors?.baffalo_calf },
          ].map((item, index) => (
            <Col lg={6} md={12} className="mb-3 d-flex align-items-center" key={index}>
              <div className="form-group cow-count-container">
                <label className="form-label form-label-Animal-custom-style">{item.label}</label>
                <input
                  className="form-control ifarm-detail-box farm-input-box uniform-input"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  {...register(item.name, {
                    maxLength: {
                      value: 3,
                      message: "Enter a valid count",
                    },
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter a valid count",
                    },
                  })}
                />
                <small className="text-danger mt-1">{item.error?.message}</small>
              </div>
            </Col>
          ))}

          <Col lg={12} >
            <hr />
          </Col>

          {[
            { label: t("Sheeps"), name: "sheeps", error: errors?.sheeps },
            { label: t("Goats"), name: "goats", error: errors?.goats },
          
          ].map((item, index) => (
            <Col lg={6} md={12} className="mb-3 d-flex align-items-center" key={index}>
              <div className="form-group cow-count-container">
                <label className="form-label form-label-Animal-custom-style">{item.label}</label>
                <input
                  className="form-control ifarm-detail-box farm-input-box uniform-input"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  {...register(item.name, {
                    maxLength: {
                      value: 3,
                      message: "Enter a valid count",
                    },
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter a valid count",
                    },
                  })}
                />
                <small className="text-danger mt-1">{item.error?.message}</small>
              </div>
            </Col>
          ))}

<Col lg={12} >
            <hr />
          </Col>
          {[
              { label: t("Poultry"), name: "poultry", error: errors?.poultry },
              { label: t("Pets"), name: "pets", error: errors?.pets },
          ].map((item, index) => (
            <Col lg={6} md={12} className="mb-3 d-flex align-items-center" key={index}>
              <div className="form-group cow-count-container">
                <label className="form-label form-label-Animal-custom-style">{item.label}</label>
                <input
                  className="form-control ifarm-detail-box farm-input-box uniform-input"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  {...register(item.name, {
                    maxLength: {
                      value: 3,
                      message: "Enter a valid count",
                    },
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Enter a valid count",
                    },
                  })}
                />
                <small className="text-danger mt-1">{item.error?.message}</small>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
                        </Col>
                    </Row>
                    {addressErr &&
                        <p className="text-danger">{t("Farm address is required")}</p>
                    }
                </>
                <SelectAddressModal
                    showModal={showAddressModal}
                    closeModal={() => setShowAddressModal(false)}
                    handleAddress={handleAddress}
                />
            </div>
        </>
    )
}