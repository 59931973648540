import React from 'react'
import TopNavbar from '../commonComponents/Navbar/Navbar'
import AddAnimalForm from './addanimalform'
import Footer from '../commonComponents/footer'

const UsertoSeller = () => {
  return (
    <>
        <TopNavbar/>
        <div className='container mt-2'>
            <AddAnimalForm/>
        </div>
        <Footer/>
    </>
  )
}

export default UsertoSeller
