import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import marketActions from '../../Redux/marketPlace/action';
import { DecryptFunction } from "../../utils/cryptoFunction";
import Profile from "./profile";
import List from "./list";

export default function MarketplaceSellerIndex() {

    const [searchParams] = useSearchParams();
    const id = searchParams.get('seller');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
            const sellerId = DecryptFunction(id);
        if (sellerId) {
            if (sellerId > 0) {
                dispatch({
                    type: marketActions.GET_SELLER_PROFILE,
                    payload: sellerId,
                });
                dispatch({
                    type: marketActions.GET_LISTING_DETAILS,
                    payload: sellerId,
                });
            }
        } else {
            navigate('/Animals');
        };
        //eslint-disable-next-line
    }, [id]);

    return (
        <Container>
            <Profile />
            <List />
        </Container>
    )
}