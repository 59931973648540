import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import checkoutActions from "../../Redux/checkout/actions";

export default function CheckoutResponse() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const response = searchParams.get('encresponse');

    const { decoderResponse } = useSelector(state => state.checkoutReducer);

    useEffect(() => {
        if (response) {
            dispatch({ type: checkoutActions.PAYMENT_DECODER, payload: { encResp: response } });
        }
        //eslint-disable-next-line
    }, [response]);


    useEffect(() => {
        if (decoderResponse) {
            if (decoderResponse?.order_status?.toLowerCase() === 'success') {
                if (sessionStorage.getItem('response_page') && sessionStorage.getItem('response_page') === 'subscription') {
                    navigate('/PaymentSuccess?page=subscription');
                    sessionStorage.removeItem('response_page');
                } else {
                    navigate('/PaymentSuccess');
                }
            } else {
                if (sessionStorage.getItem('response_page') && sessionStorage.getItem('response_page') === 'subscription') {
                    navigate('/PaymentFailed?page=subscription');
                    sessionStorage.removeItem('response_page');
                } else {
                    navigate('/PaymentFailed');
                }
            };
            // navigate('/checkout-status');
            // dispatch({ type: checkoutActions.SHOW_ORDER_SUCCESS_MODAL, payload: { isOpen: true, status: (decoderResponse?.order_status)?.toLowerCase() } });
        }
        //eslint-disable-next-line
    }, [decoderResponse]);

    return (
        <div></div>
    )
}