import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import Blog from "../components/BlogPage/blog";
// import VideosBlog from '../components/videoBlog';
// import ContactUs from "../components/ContactUsPage/contactUsPage";
// import ProductListView from "../components/ProductPage/Productlisting";
// import CartView from "../components/ProductPage/cart";
// import Invoice from "../components/ProductPage/invoice";
// import OrdersView from "../components/ProductPage/orders";
// import ProductDetailView from "../components/ProductPage/productDetail";
// import WishlistView from "../components/ProductPage/wishlist";
// import LoginPage from "../components/UserProfile/loginpage";
// import Profile from "../components/UserProfile/profile";
// import UserProfilePage from "../components/UserProfile/userprofilepage";
import UserProfile from "../pages/user-Profile";
// import UserProfilePage from "../components/UserProfile/userprofilepage";
import AnimalSellerForm from "../components/SellerProfile/sellerProfileform";
import AddAnimalForm from "../components/SellerProfile/addanimalform";
import EditAnimalForm from "../components/SellerProfile/editanimalform"
// import HomePage from "../components/home/HomePage";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../Redux/common/actions";
import cartActions from "../Redux/cart/actions";

import ToastMessage from "../common/toastMessage";
import DocListPage from "../components/AboutUs/OurDoctor/index";
import TeamListPage from "../components/AboutUs/OurTeam";
import OurTeam from "../components/AboutUs/OurTeam/index";
// import MarketPlace from "../components/Marketplace/others/MarketPlace";

import LoginModal from "../components/modal/LoginModal";
import UserAddressModal from "../components/modal/userAddressModal";
import Blog from "../pages/blog";
// import BlogList from "../components/blog";
import Cart from "../pages/cart";
import Checkout from "../pages/checkout";
import Home from "../pages/home";
import ProductDetails from "../pages/productDetails";
import Products from "../pages/products";
import Treatments from "../pages/treatments";
import Consultation from "../pages/consultation";
// import Animals from "../components/Marketplace/Animals/Animals";
import VideoBlog from "../pages/videoBlog";
// import VideoList from "../components/videoBlog";
// import OrderSuccessModal from "../components/modal/orderSuccessModal";
// import IndividualAnimalPage from "../components/Marketplace/Animals/individualpage";
// import Seller from "../components/Marketplace/Animals/seller";
import LogoutModal from "../components/modal/logoutModal";
import CCAvenue from "../components/checkout/ccavenue";
import { useTranslation } from 'react-i18next';

import UsertoSeller from "../components/SellerProfile/UsertoSeller";
import Sellingprofile from "../components/SellerProfile/Selling-profile";

import MarketplaceLiveStock from "../pages/marketplaceLiveStock";
import MarketplaceLiveStockSingle from "../pages/marketplaceLiveStockSingle";
import MarketplaceSeller from "../pages/marketplaceSeller";
import CheckoutResponse from "../components/checkout/checkoutResponse";
import MarketplaceOtherServices from "../pages/marketplaceOtherServices";
// import SubscriptionComponent from "../components/user-Profile/subscription"
import PaymentFailed from "../components/checkout/paymentFailed";
import PaymentSuccess from "../components/checkout/paymentSuccessfull";
import ScrollToTop from "../utils/scrollToTop";
import CallFloatingBtn from "../common/callFloatingBtn";
import NotFound from "../common/notFound";
import Privacy from "../components/commonComponents/privacyPolicy/PrivacyPolicy";

const Routers = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { showUserAddressModal } = useSelector(state => state.commonReducer);

  useEffect(() => {
    if (!sessionStorage.getItem("YFLanguage")) {
      sessionStorage.setItem("YFLanguage", "en");
      i18n.changeLanguage("en");
      dispatch({ type: commonActions.CURRENT_LANGUAGE, payload: "en" });
    }
    dispatch({ type: commonActions.GET_WEBSITE_LANGUAGES });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("token") &&
      !sessionStorage.getItem("temp_Token")
    ) {
      dispatch({ type: commonActions.GET_COMMON_TOKEN });
    }
    return () => {
      sessionStorage.removeItem("addtocart");
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('auth_user')) {
      dispatch({
        type: cartActions.GET_CART_PRODUCT_LIST,
        payload: {id: localStorage.getItem('auth_user')}
      });
    };
    // if (!sessionStorage.getItem('active_menu')) {
    //   sessionStorage.setItem('active_menu', 'Home');
    // }
    //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (
  //     !sessionStorage.getItem("loginModal") &&
  //     !localStorage.getItem("token")
  //   ) {
  //     dispatch({ type: commonActions.SET_SHOW_LOGIN_MODAL, payload: true });
  //   }
  //   //eslint-disable-next-line
  // }, []);

  return (
    <>
      <LoginModal />
      {showUserAddressModal &&
        <UserAddressModal />
      }
      <LogoutModal />
      <ToastMessage />
      {/* {htmlPayload &&
        <div dangerouslySetInnerHTML={{ __html: htmlPayload }} />
      } */}
      <Router>
        <ScrollToTop />
        <CallFloatingBtn />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment-gateway" element={<CCAvenue />} />
          {/* <Route path="/checkout-status" element={<OrderSuccessModal />} /> */}

          {/* <Route path="/login" element={<LoginPage />} /> */}
          {/* <Route path="/login" element={<Profile />} /> */}
          <Route path="/blog" element={<Blog />} />
          {/* <Route path="/contact" element={<ContactUs />} /> */}
          <Route path="/videos" element={<VideoBlog />} />
          <Route path="/user-profile" element={<UserProfile />} />

          {/* Product */}
          {/* <Route path="/cartView" element={<CartView />} /> */}
          {/* <Route path="/invoice" element={<Invoice />} /> */}
          {/* <Route path="/products" element={<ProductListView />} /> */}
          {/* <Route path="/wishlist" element={<WishlistView />} /> */}
          {/* <Route path="/orders" element={<OrdersView />} /> */}
          {/* <Route path="/productdetail" element={<ProductDetailView />} /> */}
          {/* <Route path="product-listing" element={<ProductListView />} /> */}
          <Route path="/consultation" element={<Consultation />} />
          <Route path="/treatments" element={<Treatments />} />

          {/* MarketPlace */}
          <Route path="/ourdoctors" element={<DocListPage />} />
          <Route path="/ourteam" element={<OurTeam />} />

          {/*Animals */}  
          <Route path="/Animals" element={<MarketplaceLiveStock />} />
          {/* <Route path="/Animals" element={<Animals />} /> */}
          {/* <Route path="/AnimalIndividual" element={<IndividualAnimalPage />} /> */}
          {/* <Route path="/sellerprofile" element={<Seller />} /> */}
          <Route path="/service-providers" element={<MarketplaceOtherServices />} />

          <Route path="/Seller-Reg" element={<AnimalSellerForm />} />
          <Route path="/Animal-add" element={<AddAnimalForm />} />
          <Route path="/user-to-seller" element={<UsertoSeller />} />
          <Route path="/sellingprofile" element={<Sellingprofile />} />
          <Route path="/marketplace-seller" element={<MarketplaceSeller />} />
          <Route path="/marketplace-single" element={<MarketplaceLiveStockSingle />} />
          <Route path="/checkout-response" element={<CheckoutResponse />} />
          <Route path="/Animal-edit" element={<EditAnimalForm />} />
          {/* <Route path="/user-to-seller" element={<UsertoSeller />} /> */}
          {/* <Route path="/sellingprofile" element={<Sellingprofile />} /> */}
          <Route path="/PaymentFailed" element={<PaymentFailed />} />
          <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
          <Route path="/privacy.html" element={<Privacy />} />
          <Route path="/*" element={<NotFound />} />

        </Routes>
      </Router>
    </>
  );
};
export default Routers;
