import Actions from "./action";
import { takeEvery, put, call, all } from "redux-saga/effects";
import { API_URL } from "../../utils/constant";
import axios from "axios";

const addressSagas = function* () {
    yield all([
        yield takeEvery(Actions.GET_ADDRESS_LIST, AddressList)
    ])
};



const AddressList = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/useraddress/${payload}`)
        )
        yield put({
            type: Actions.SET_ADDRESS_LIST,
            payload: result?.data
        });
    } catch (error) {
        console.log("Error in catch", error)
    }
}

export default addressSagas;
