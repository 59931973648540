import commonActions from "./actions";

const initialState = {
  showLoginModal: false,
  buttonLoader: false,
  alert: {
    show: false,
    message: null,
    status: null,
  },
  websiteLanguages: [],
  currentLanguage: null,
  stateList: [],
  showUserAddressModal: false,
  pageLoader: false,
  currentLocation: {
    Address: null,
    Coordinates: null,
    type: null
  },
  showLogoutModal: false,
  Addressloglat: {},
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonActions.SET_SHOW_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: action.payload,
      };
    case commonActions.SET_BUTTON_LOADER:
      return {
        ...state,
        buttonLoader: action.payload,
      };
    case commonActions.SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case commonActions.SET_WEBSITE_LANGUAGES:
      return {
        ...state,
        websiteLanguages: action.payload,
      };
    case commonActions.CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case commonActions.SET_STATES:
      return {
        ...state,
        stateList: action.payload,
      };
    case commonActions.SET_SHOW_USER_ADDRESS_MODAL:
      return {
        ...state,
        showUserAddressModal: action.payload,
      };
    case commonActions.PAGE_LOADER:
      return {
        ...state,
        pageLoader: action.payload,
      };
    case commonActions.SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };
    case commonActions.SHOW_LOGOUT_MODAL:
      return {
        ...state,
        showLogoutModal: action.payload,
      }

      case commonActions.SET_SELLER_PROFILE_SERVICES_SELECTED_LOCATION:
        return {
            ...state,
            Addressloglat:action.payload
        }

    default:
      return state;
  }
};

export default commonReducer;
