import actions from './action';
import { takeEvery, all, call, put } from 'redux-saga/effects'
import axios from 'axios'
import { API_URL } from '../../utils/constant';



const LiveStockSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_LIVE_STOCK, ListByLiveStock),
    ]);



    function* ListByLiveStock() {
        try {
            const response = yield call(axios.get, `${API_URL}/v1/admin/cattle_listings/all`);
            yield put(actions.getLiveStockSuccess(response.data));
        } catch (error) {
            yield put(actions.getLiveStockFailure(error));
            console.error(error);
        }
    }


}

export default LiveStockSaga;


