import React from "react";
import Navbar from '../components/commonComponents/Navbar/Navbar'
import ProductsIndex from "../components/products/index";
import Footer from "../components/commonComponents/footer";
import { useSelector } from "react-redux";
import PageLoader from "../common/pageLoader";

export default function Products() {

    const { pageLoader } = useSelector(state => state.commonReducer);

    return (
        <div className={`${pageLoader && "body-overflow-hide"}`}>
            {/* <TopNavbar /> */}
            {pageLoader &&
                <PageLoader />
            }
            <Navbar />
            <ProductsIndex />
            <Footer />
        </div>
    )
}