import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import marketActions from '../../Redux/marketPlace/action';
import { BiWorld } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { PiEye, PiShareFatFill, PiPhoneFill } from 'react-icons/pi';
import { Rating } from 'react-simple-star-rating';
import { RWebShare } from 'react-web-share';
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import { FRONTEND_URL } from "../../utils/constant";
import AnimalImageGrid from "../marketplaceLiveStock/animalImageGrid";
import ImageModal from "../marketplaceLiveStock/imageModal";
import DisclaimerModal from "../modal/disclaimerModal";
import noprofileimg from '../../assets/images/noprofile.png';
import moment from "moment-timezone";

export default function MarketplaceLiveStockSingleIndex() {

    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    const id = searchParams.get('cattle');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { individuallivestock } = useSelector(state => state.MarketPlaceReducer);
    const { currentLanguage, pageLoader } = useSelector(state => state.commonReducer);

    const [showImage, setShowImage] = useState({ isOpen: false, data: null });
    const [fetchedData, setFetchedData] = useState([]);
    const [contactViewable, setContactViewable] = useState(false);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState({ isOpen: false, data: null });
    const { recentDisease } = useSelector(state => state.farmTypeReducer);

    useEffect(() => {
        if(id){
        const cattleId = DecryptFunction(id);
        if (cattleId) {
            dispatch({
                type: marketActions.GET_INDIVIDUAL_LIVESTOCK_FROM_THEIR_ID,
                payload: cattleId,
            });
        } else {
            navigate('/Animals');
        };
    }
        //eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        const list = individuallivestock?.map((val) => {
            let images = [
                { url: val?.cattle?.image_front_url || null, type: 'front' },
                { url: val?.cattle?.image_side_url || null, type: 'side' },
                { url: val?.cattle?.image_body_url || null, type: 'body' },
                { url: val?.cattle?.video_url || null, type: 'video' },
            ];
            //eslint-disable-next-line
            let cattleImages = images.filter((img) => {
                if (img.url) {
                    return img;
                }
            });
            let data = { ...val, images: cattleImages }
            return data;
        });
        setFetchedData(list);
    }, [individuallivestock]);

    const handleShowImagesModal = () => {
        if (fetchedData[0]?.images?.length > 0) {
            setShowImage({ isOpen: true, data: fetchedData[0]?.images });
        }
    };

    const handleViewSeller = (id) => {
        const encryptedId = EncryptFunction(id);
        if (encryptedId) {
            navigate(`/marketplace-seller?seller=${encryptedId}`);
        };
    };

    const handleViewAll = () => {
        navigate('/Animals');
    };

    return (
        <Container className="my-4">
            <div className="page_heading_div_cls">
                {t("Livestock Buy/Sell")}
            </div>
            {fetchedData?.length > 0 ?
                <>
                    {fetchedData?.map((val, i) =>
                        <div key={i} className="marketplace-cattle-card-div mt-3">
                            <div className="d-flex justify-content-between maretplace-cattle-card-head">
                                <div className="marketplace-cattle-card-col1">
                                    <div className="marketplace-cattle-card-title">{val?.listing?.title} <span className="cattle_list_milk_productions_span">{val?.cattle?.milk_production_litres > 0 && `(${val.cattle.milk_production_litres} ltr milk /day)`}</span></div>
                                    <div className="marketplace-cattle-card-place"><FaLocationDot className="pb-1" size={16} /> {val?.listing?.place || "N/A"}</div>
                                    <div className="marketplace-cattle-card-postedby"><BiWorld /> {val?.listing?.published_at ? moment(val?.listing?.published_at).fromNow() : "N/A"}</div>
                                </div>
                                <div className="marketplace-cattle-rate-and-view">
                                    <div className="marketplace-cattle-card-rate">₹{val?.listing?.rate}</div>
                                    <div className="maretplace-cattle-view"><PiEye className="marketplace-cattle-eye-icon" /> {val?.listing?.viewed} views</div>
                                </div>
                            </div>
                            <AnimalImageGrid
                                images={val?.images || []}
                                handleShowImagesModal={handleShowImagesModal}
                            />
                            <div className="my-3 marketplace-cattle-age-main-div-cls">
                                {val?.cattle?.age > 0 &&
                                    <div className="marketplace-cattle-age-div">
                                        <div className="marketplace-cattle-age-header">{t("Age")}</div>
                                        <div className="marketplace-cattle-age-value">{val?.cattle?.age} {val?.cattle?.age === 1 ? "yr" : "yrs"} old</div>
                                    </div>
                                }
                                {val?.cattle?.gender &&
                                    <div className="marketplace-cattle-age-div">
                                        <div className="marketplace-cattle-age-header">{t("Gender")}</div>
                                        <div className="marketplace-cattle-age-value">{val?.cattle?.gender}</div>
                                    </div>
                                }
                                {val?.cattle?.last_calving_date &&
                                    <div className="marketplace-cattle-age-div">
                                        <div className="marketplace-cattle-age-header">{t("Last Calving date")}</div>
                                        <div className="marketplace-cattle-age-value">{moment(val?.cattle?.last_calving_date).format('DD/MM/YYYY')}</div>
                                    </div>
                                }
                                {/* {val?.cattle?.disease_history?.length > 0 &&
                                    <div className="marketplace-cattle-age-div">
                                        <div className="marketplace-cattle-age-header">{t("Disease")}</div>
                                        <div className="marketplace-cattle-age-value">{val?.cattle?.disease_history?.join(", ")}</div>
                                    </div>
                                } */}
                                
                                {val.cattle.disease_history.map(diseaseId => {
                                        const disease = recentDisease.find(d => `${d.id}` === `${diseaseId}`);
                                        if (disease) {
                                            const languageDisease = disease.language.find(lang => lang.language_code === currentLanguage)?.disease;
                                            return (
                                                <div className="marketplace-cattle-age-div" key={diseaseId}>
                                                                                                <div className="marketplace-cattle-age-header">{t("Diseases")}</div>

                                                    {languageDisease || t(disease.name)}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    }).filter(Boolean)}
                            </div>
                            <div>
                                <div className="marketplace-cattle-listing-decription">{val?.listing?.description}</div>
                            </div>
                            <div className="my-3 marketplace_cattle_grid_btn_main_div">
                                <Row className="marketplace_cattle_grid_btn_row">
                                    <Col md={6} sm={12} className="marketplace-cattle-provider-col cursor-pointer" onClick={() => handleViewSeller(val?.listing?.provider_id)}>
                                        <img src={val?.listing?.provider?.logo || noprofileimg} alt="" className="marketplace-cattle-list-profile-img" />
                                        <div>
                                            <div className="marketplace-cattle-card-postedby-div">{t("Posted by")} : {val?.listing?.provider?.provider_name}</div>
                                            <Rating
                                                initialValue={val?.listing?.provider?.average_rating}
                                                className="marketplace-cattle-ratings"
                                            // onClick={(rate) => handleRating(rate, val?.listing?.provider_id)}
                                            readonly
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} sm={12} className="marketplace-cattle-btn-col-cls">
                                        <Button
                                            size="sm"
                                            onClick={() => {
                                                if (!contactViewable) {
                                                    setShowDisclaimerModal({ isOpen: true, data: null });
                                                }
                                            }}
                                        >
                                            <PiPhoneFill className="marketplace-cattle-btn-icons" /> {contactViewable ? val?.listing?.provider?.mobile_no : t("Contact")}
                                        </Button>
                                        <RWebShare
                                            data={{
                                                text: "Like humans, flamingos make friends for life",
                                                url: `${FRONTEND_URL}/marketplace-single?cattle=${id}`,
                                                title: "Flamingos",
                                            }}
                                        // onClick={() => handleShareClick(val?.cattle?.id)}
                                        >
                                            <Button size="sm"><PiShareFatFill className="marketplace-cattle-btn-icons" />{t("Share")}</Button>
                                        </RWebShare>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )}
                </> :
                <div className="no-data-found-div-cls">{t("No Data Found")}</div>
            }
            <ImageModal
                showModal={showImage?.isOpen}
                closeModal={() => setShowImage(false)}
                images={showImage?.data}
            />
            <DisclaimerModal
                showModal={showDisclaimerModal?.isOpen}
                closeModal={() => setShowDisclaimerModal({ isOpen: false, data: null })}
                agreeClick={() => {
                    setContactViewable(true);
                    setShowDisclaimerModal({ isOpen: false, data: null });
                }}
            />
            <div className="d-flex justify-content-center mt-3">
                <Button onClick={handleViewAll}>{t("View All")}</Button>
            </div>
        </Container>
    )
}