import actions from './action';
import { takeEvery, all, call, put, delay } from 'redux-saga/effects'
import axios from 'axios'
import { API_URL } from '../../utils/constant';
import commonActions from '../common/actions';
import cartActions from '../cart/actions';


const OrderSaga = function* () {
    yield all([
        yield takeEvery(actions.GET_ORDER_LIST, OrderList),
        yield takeEvery(actions.GET_ORDER_DETAILS, getOrderDetails),
        yield takeEvery(actions.RE_ORDER, reOrder),

    ]);

}

const OrderList = function* (data) {
    const { payload } = data;
   
    try {

        const result = yield call(() =>
            // axios.get(`${API_URL}/v1/user/myorders/​${payload}`)
            axios.get(`${API_URL}/v1/user/myorders/${payload}`)
        )
        yield put({
            type: actions.SET_ORDER_LIST,
            payload: result?.data?.data
        });

    } catch (error) {
        console.log("Error in catch", error)
    }
}

const getOrderDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/myorder/${payload}`)
        )
        yield put({
            type: actions.SET_ORDER_DETAILS,
            payload: result?.data?.data
        });

    } catch (error) {
        console.log("Error in catch", error)
    }
}

const reOrder = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/user/reorder`, payload?.data)
        );
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                show: true,
                status: result?.data?.code === 200 ? "success" : "failed",
                message: result?.data?.code === 200 ? "Product added to cart" : "Failed",
            },
        });
        if (result?.data?.code === 200) {
            yield put({ type: cartActions.GET_CART_PRODUCT_LIST, payload: {id: localStorage.getItem('auth_user'), type: 'updated'} });
            navigate('/cart')
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, message: null, status: null },
        });
    } catch (err) {
        console.log(err);
    };
};

export default OrderSaga;