const actions = {
  GET_MARKET_PLACE_CATTLE_TYPE: "GET_MARKET_PLACE_CATTLE_TYPE",
  SET_MARKET_PLACE_CATTLE_TYPE: "SET_MARKET_PLACE_CATTLE_TYPE",

  GET_ALL_CATTLE_PRODUCTS: "GET_ALL_CATTLE_PRODUCTS",
  SET_ALL_CATTLE_PRODUCTS: "SET_ALL_CATTLE_PRODUCTS",

  GET_SERVICE_PROVIDER_BY_CATEGORY: "GET_SERVICE_PROVIDER_BY_CATEGORY",
  SET_SERVICE_PROVIDER_BY_CATEGORY: "SET_SERVICE_PROVIDER_BY_CATEGORY",

  GET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID:
    "GET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID",
  SET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID:
    "SET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID",

  GET_INDIVIDUAL_SERVICE_PROVIDER_FROM_THEIR_ID:
    "GET_INDIVIDUAL_SERVICE_PROVIDER_FROM_THEIR_ID",
  SET_INDIVIDUAL_SERVICE_PROVIDER_FROM_THEIR_ID:
    "SET_INDIVIDUAL_SERVICE_PROVIDER_FROM_THEIR_ID",

  GET_DIARY_CATTLE_CATEGORY: "GET_DIARY_CATTLE_CATEGORY",
  SET_DIARY_CATTLE_CATEGORY: "SET_DIARY_CATTLE_CATEGORY",
  SET_CATTLE_PARENT_CATEGRY: "SET_CATTLE_PARENT_CATEGRY",

  MARKETPLACE_SERVICES_SELECTED_LOCATION:
    "MARKETPLACE_SERVICES_SELECTED_LOCATION",

  GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE:
    "GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE",

  SET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE:
    "SET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE",

  SET_CATEGORYWISE_CATTLE_LISTINGS_ALL: "SET_CATEGORYWISE_CATTLE_LISTINGS_ALL",

  SET_CATEGORY_WISE_CATTLE_LISTTING_WITH_IMGS: "SET_CATEGORY_WISE_CATTLE_LISTTING_WITH_IMGS",

  SET_SELECTED_ANIMAL_CATEGORY: "SET_SELECTED_ANIMAL_CATEGORY",

  GET_INDIVIDUAL_LIVESTOCK_FROM_THEIR_ID:
    "GET_INDIVIDUAL_LIVESTOCK_FROM_THEIR_ID",
  SET_INDIVIDUAL_LIVESTOCK_FROM_THEIR_ID:
    "SET_INDIVIDUAL_LIVESTOCK_FROM_THEIR_ID",

  GET_SELLER_PROFILE: "GET_SELLER_PROFILE",   //provider_id
  SET_SELLER_PROFILE: "SET_SELLER_PROFILE",

  GET_SELLER_LISTINGS: "GET_SELLER_LISTINGS",
  SET_SELLER_LISTINGS: "SET_SELLER_LISTINGS",

  GET_CATTLE_LISTING_BY_ID: "GET_CATTLE_LISTING_BY_ID",
  SET_CATTLE_LISTING_BY_ID: "SET_CATTLE_LISTING_BY_ID",

  GET_LISTING_DETAILS: 'GET_LISTING_DETAILS',
  SET_LISTING_DETAILS: 'SET_LISTING_DETAILS',

  UPDATE_CATTLE_LISTINGS: 'UPDATE_CATTLE_LISTINGS',

  SHOW_SELLER_REGISTRATION_MODAL: 'SHOW_SELLER_REGISTRATION_MODAL',

  UPDATE_SELLER_PROFILE: 'UPDATE_SELLER_PROFILE',

  GET_LISTING_STATUS: 'GET_LISTING_STATUS',
  SET_LISTING_STATUS: 'SET_LISTING_STATUS',

  SERVICE_PROVIDER_RATINGS: "SERVICE_PROVIDER_RATINGS",

  CATTLE_LISTINGS_FILTER: "CATTLE_LISTINGS_FILTER",
  // CATTLE_LISTINGS_SET_MIN_MAX_PRICE: "CATTLE_LISTINGS_SET_MIN_MAX_PRICE",
  CATTLE_LISTINGS_SELECTED_FILTERS: "CATTLE_LISTINGS_SELECTED_FILTERS",
};
export default actions;
