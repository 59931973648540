import React from "react";
import TopNavbar from "../components/commonComponents/Navbar/Navbar";
import BlogPage from "../components/blog/index";
import Footer from "../components/commonComponents/footer";
import { useSelector } from "react-redux";
import PageLoader from "../common/pageLoader";

export default function Blog() {

    const { pageLoader } = useSelector(state => state.commonReducer);

    return (
        <div className={`${pageLoader && "body-overflow-hide"}`}>
            {pageLoader &&
                <PageLoader />
            }
            <TopNavbar />
            <BlogPage />
            <Footer />
        </div>
    )
}