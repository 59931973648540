// export default SubscriptionPage;
import React, { useEffect, useState } from 'react';
import { TbDiscount2 } from "react-icons/tb";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import userActions from '../../Redux/user/actions';
import couponActions from "../../Redux/coupon/actions";
import subscriptionActions from '../../Redux/subscription/actions';
import { MdOutlineCurrencyRupee } from 'react-icons/md';
import { DecryptFunction } from '../../utils/cryptoFunction';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

function SubscriptionPage(props) {

  const { data, handleDisableSubscription } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { SubscriptionPlan } = useSelector((state) => state.userReducer);

  const { sellerprofile } = useSelector((state) => state.MarketPlaceReducer);

  const { couponList } = useSelector(state => state.couponReducer);

  const { subscriptionValues } = useSelector(state => state.subscriptionReducer);

  let [selectedPlan, setSelectedPlan] = useState(null);
  let [copiedCoupon, setCopiedCoupon] = useState({
    code: null, index: null
  });
  let [validcoupons, setValidcoupons] = useState([]);

  useEffect(() => {
    dispatch({ type: userActions.GET_PAYMENT_PLAN });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localStorage.getItem('auth_user')) {
      dispatch({
        type: couponActions.GET_COUPON_BY_MOBILENUMBER,
        payload: localStorage.getItem('auth_user_no')
      });
    }
    //eslint-disable-next-line
  }, []);

  const handleSubscriptionChange = (planId, item) => {
    setSelectedPlan(planId);
    //eslint-disable-next-line
    const validCoupons = couponList?.filter((val) => {
      if (moment(val?.expires_at).diff(moment()) > 0 && parseInt(item?.amount) > parseInt(val?.minimum_amount)) {
        return val;
      }
    });
    setValidcoupons(validCoupons);
    dispatch({
      type: subscriptionActions.SUBSCRIPTION_VALUES, payload: {
        ...subscriptionValues,
        selectedPlanId: planId,
        selectedPlan: item,
        total: item?.amount,
        amount: item?.amount,
        DeliveryAddress: sellerprofile?.data[0]?.address,
        provider_id: sellerprofile?.data[0]?.id,
        cattlelistings: data?.value
      }
    })
  };

  const handleCouponApply = (code, index) => {
    setCopiedCoupon(copiedCoupon = { code: code, index: index, applied_Coupon: code });
    let couponValues = couponList?.find((val) => val?.code === copiedCoupon?.code);
    let pricedetails = { ...subscriptionValues?.selectedPlan };
    if (couponValues) {
      let copied = { ...copiedCoupon };
      copied["Error"] = '';
      let coupon_discount = 0, finalTotal;
      if (couponValues?.discount_type === 1) {
        coupon_discount = (parseFloat(pricedetails?.amount) * parseFloat(couponValues?.discount)) / 100;
      } else {
        coupon_discount = parseFloat(couponValues?.discount);
      };
      finalTotal = parseFloat(pricedetails?.amount) - parseFloat(coupon_discount);
      pricedetails['coupon_discount'] = parseFloat(coupon_discount)?.toFixed(2);
      if (parseInt(finalTotal) < 0) {
        pricedetails['total'] = parseFloat(0)?.toFixed(2);
      } else {
        pricedetails['total'] = parseFloat(finalTotal)?.toFixed(2);
      }
      // setPriceDetails(pricedetails);
      dispatch({
        type: subscriptionActions.SUBSCRIPTION_VALUES,
        payload: {
          ...subscriptionValues,
          ...pricedetails,
          applied_Coupon: couponValues,
        }
      })
    } else {
      let copied = { ...copiedCoupon };
      copied["Error"] = "Invalid Coupon code";
      setCopiedCoupon(copied);
    }
  };

  const handlePlaceOrder = () => {
    let userid = DecryptFunction(localStorage.getItem('auth_user'));
    if (userid) {
      dispatch({
        type: subscriptionActions.UPDATE_SUBSCRIPTION_PLAN, payload: {
          data: {
            "provider_id": subscriptionValues?.provider_id,
            "listing_id": data?.id,
            "payment_plan_id": subscriptionValues?.selectedPlan?.id,
            "order": {
              "user_id": userid,
              "subtotal": (subscriptionValues?.amount || 0),
              "discount": 0,
              "coupon_discount": (subscriptionValues?.coupon_discount || 0),
              "gst": (subscriptionValues?.gst || 0),
              "total_amount": (subscriptionValues?.total || 0),
              "location_id": subscriptionValues?.DeliveryAddress?.id
            }
          },
          navigate: navigate,
        }
      });
    };
  };

  return (
    <div className="container" style={{ backgroundColor: "white", padding: "20px" }}>
      <h4 style={{ color: "rgba(35, 44, 99, 1)" }}>Subscription</h4>
      <p style={{ color: "grey" }}>Select your subscription plan to unlock the full potential of posting pictures</p>
      {SubscriptionPlan?.data?.map((item, i) => (
        <div key={i}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <input
              type="radio"
              id={item.id}
              name="subscriptionPlan"
              value={item.id}
              onChange={(e) => handleSubscriptionChange(e.target.value, item)}
            />
            <label htmlFor={item.id} style={{ marginLeft: '5px' }}>{item.title}</label>
          </div>
          {(selectedPlan && item?.id === parseInt(selectedPlan)) &&
            <div className='seller-profile-subs-desc'>{item?.description}</div>
          }
        </div>
      ))}
      {selectedPlan &&
        <div>
          {subscriptionValues?.selectedPlan?.amount > 0 &&
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <h4><TbDiscount2 /> {t("My Coupons")}</h4>
              <p style={{ color: "grey" }}>Don't miss out on savings! Enter your coupon code at checkout for exclusive discounts</p>
              <div>
                {validcoupons?.length > 0 ?
                  <div className="checkout-coupon-code-maind-cls">
                    {validcoupons?.map((val, i) =>
                      <div className="discount-code">
                        <div className="offer-container">
                          <span className="offer-text">{val?.discount_type === 1 ? `${val?.discount}% offer on this order` : `₹${val?.discount} offer on this order`}</span>
                          <div className="code-container">
                            <span className="code-text">CODE : {val?.code}</span>
                            {i !== copiedCoupon?.index ? (
                              <div className="apply-button" onClick={() => handleCouponApply(val?.code, i, val)}>
                                TAP TO APPLY
                              </div>
                            ) : (
                              <div className="applied-text">APPLIED</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div> :
                  <div className="text-center mt-3">
                    <h6>{t("No Coupons")}</h6>
                  </div>
                }
                <small className="text-danger fw-600 ms-1">{copiedCoupon?.Error}</small>
              </div>

            </div>
          }
          <label className="form-label checkout-step1-label my-3">{t("Price Details")}</label>
          <dl className="d-flex justify-content-between my-2">
            <dt className="checkout-page-price-details-label-1">{t("Subtotal")}</dt>
            <dd className="cart-product-subtotal"><MdOutlineCurrencyRupee /> {parseFloat(subscriptionValues?.amount || 0)?.toFixed(2)}</dd>
          </dl>
          {(subscriptionValues?.coupon_discount > 0) &&
            <dl className="d-flex justify-content-between my-2">
              <dt className="checkout-page-price-details-label-1">{t("Coupon Discount")}</dt>
              <dd className="cart-product-dis-dd">- <MdOutlineCurrencyRupee /> {parseFloat(subscriptionValues?.coupon_discount || 0)?.toFixed(2)}</dd>
            </dl>
          }
          <div className="checkout-page-hr-cls" />
          <dl className="d-flex justify-content-between">
            <dt>{t("Total price")}</dt>
            <dd className="cart-product-final-price-dd">
              <strong><MdOutlineCurrencyRupee /> {parseFloat(subscriptionValues?.total || 0)?.toFixed(2)}</strong>
            </dd>
          </dl>
          <div className="custom-buttons">
            <button className="custom-button Subcall-button" onClick={() => handleDisableSubscription()}>Cancel</button>
            <button className="custom-button Subexplore-button" onClick={() => handlePlaceOrder()}>Place order</button>
          </div>
        </div>
      }
    </div>

  );
}

export default SubscriptionPage;
