import cartActions from "./actions";

const initialState = {
    cartProducts: [],
    CurrentAddingCartProduct: null,
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {

        case cartActions.SET_CART_PRODUCT_LIST:
            return {
                ...state,
                cartProducts: action.payload
            }
        case cartActions.CURRENT_ADDING_CART_PRODUCT:
            return {
                ...state,
                CurrentAddingCartProduct: action.payload
            }
        default:
            return state;
    }
};

export default cartReducer;