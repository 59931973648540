import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Rating from 'react-rating-stars-component';
import productActions from '../../Redux/Products/action';
import { DecryptFunction } from "../../utils/cryptoFunction";

function Ratings({ showModal, onCancel, onSave, student }) {
    const dispatch = useDispatch(); 
    const { productDetails } = useSelector(state => state.ProductListReducer);
    const { sellerprofile } = useSelector(state => state.MarketPlaceReducer);
    const [rating, setRating] = useState(0); 
    const [review_comments, setComment] = useState(''); 
    const [ratingError, setRatingError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (rating === 0) {
            setRatingError('Please select a rating');
            return;
        }
        setRatingError('');
        const auth_user = localStorage.getItem('auth_user');
        let user_id = auth_user ? parseInt(DecryptFunction(auth_user)) : null;
        dispatch({
            type: productActions.POST_RATEING_PRODUCTS, 
            payload: { 
                rating,
                review_comments,
                ref_id: productDetails?.id,
                user_id,
                ref_type: "product"
            } 
        });
    
        onCancel();
    };
    
   
    return (
        <Modal show={showModal} onHide={onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Ratings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please rate this Product:</p>
                <Rating
                    count={5} 
                    value={rating} 
                    onChange={(value) => setRating(value)}
                    size={42} 
                    activeColor="#ffd700"
                />
                {ratingError && <p style={{ color: 'red' }}>{ratingError}</p>}
                <Form.Group controlId="comment">
                    <Form.Label>Comment:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={review_comments}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Ratings;
