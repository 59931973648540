const commonActions = {
  SET_SHOW_LOGIN_MODAL: "SET_SHOW_LOGIN_MODAL",
  SET_BUTTON_LOADER: "SET_BUTTON_LOADER",
  SET_ALERT: "SET_ALERT",
  SET_SHOW_USER_ADDRESS_MODAL: 'SET_SHOW_USER_ADDRESS_MODAL',

  GET_WEBSITE_LANGUAGES: 'GET_WEBSITE_LANGUAGES',
  SET_WEBSITE_LANGUAGES: 'SET_WEBSITE_LANGUAGES',
  CURRENT_LANGUAGE: 'CURRENT_LANGUAGE',
  GET_COMMON_TOKEN: 'GET_COMMON_TOKEN',

  GET_STATES: 'GET_STATES',
  SET_STATES: 'SET_STATES',

  GET_ADDRESS_COORDINATES: 'GET_ADDRESS_COORDINATES',
  GET_CURRENT_LOCATION: 'GET_CURRENT_LOCATION',
  SET_CURRENT_LOCATION: 'SET_CURRENT_LOCATION',

  PAGE_LOADER: 'PAGE_LOADER',

  SHOW_LOGOUT_MODAL: 'SHOW_LOGOUT_MODAL',

  SELLER_PROFILE_SERVICES_SELECTED_LOCATION: 'SELLER_PROFILE_SERVICES_SELECTED_LOCATION',
  SET_SELLER_PROFILE_SERVICES_SELECTED_LOCATION: 'SET_SELLER_PROFILE_SERVICES_SELECTED_LOCATION',
};

export default commonActions;
