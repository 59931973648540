import reducers from "./Reducer";
import { combineReducers } from "redux";
import reduxSaga from "redux-saga";
import rootSaga from "./Sagas";
import { configureStore } from "@reduxjs/toolkit";

const reduxSagaMiddleware = reduxSaga();
const rootReducers = combineReducers({ ...reducers });

const store = configureStore({
  reducer: rootReducers,
  middleware: [reduxSagaMiddleware],
});

reduxSagaMiddleware.run(rootSaga);

export { store };