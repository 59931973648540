import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";
import TopNavbar from "../commonComponents/Navbar/Navbar";
import Footer from "../commonComponents/footer";
import StepperComponent from "./stepper";

export default function PaymentSuccess() {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');

    return (
        <div>
            <TopNavbar />
            <Container className="my-5">
                <Row className="my-4">
                    {page && page === 'subscription' ?
                        <Col md={12} className="center-text">
                            <StepperComponent currentStep={3} />
                            <FiCheckCircle className="success-icon" style={{ marginTop: "60px" }} />
                            <p className="success-message">{t("Order Successful")}</p>
                            <p className="grey-text">{t("Your subscription is successfully update. Your listing is under review and it will be shown after the review")}</p>
                            <button className="checkout-response-page-btn-cls" onClick={() => navigate('/user-profile?page=Seller')}>Back to order</button>
                        </Col> :
                        <Col md={12} className="center-text">
                            <StepperComponent currentStep={3} />
                            <FiCheckCircle className="success-icon" style={{ marginTop: "60px" }} />
                            <p className="success-message">{t("Order Successful")}</p>
                            <p>{t("Your order has been placed")}</p>
                            <p className="grey-text">{t("It’s our pleasure to offer you our products")}</p>
                            <button className="checkout-response-page-btn-cls" onClick={() => navigate('/user-profile?page=Order')}>Done</button>
                        </Col>
                    }
                </Row>
            </Container>
            <Footer />
        </div>
    );
}
