import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { FaEye } from "react-icons/fa";
import { FaDownload, FaVanShuttle } from "react-icons/fa6";
import { PiTruck } from "react-icons/pi";

import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import Pagination from "../../common/pagination";
import { PiArrowCounterClockwise } from "react-icons/pi";
import OrderDetailsModal from "./orderDetailsModal";
import orderActions from '../../Redux/order/action';
import { DecryptFunction } from "../../utils/cryptoFunction";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { LogEvent } from "../../utils/firebase";
// import { TbFileDownload } from "react-icons/tb";
// import { FaDownload } from "react-icons/fa";


export default function MyOrders() {

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { Orderlist } = useSelector((state) => state.OrderReducer);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);


    const endOffset = itemOffset + itemsPerPage;
    const currentItems = Orderlist?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(Orderlist?.length / itemsPerPage);

    const handlePageChange = (event) => {
        const newOffset = (event.selected * itemsPerPage) % Orderlist?.length;
        setItemOffset(newOffset);
        setCurrentPage(event?.selected);
        window.scrollTo({ top: 0 })
    };

    const handleViewDetails = (id) => {
        LogEvent('MyOrder_Details_Viewed')
        setShowDetailsModal(true);
        dispatch({ type: orderActions.GET_ORDER_DETAILS, payload: id });
    };

    const handleReorder = (value) => {
        LogEvent('MyOrder_ReOrder_Clicked')
        const user_id = DecryptFunction(localStorage.getItem('auth_user'));

        if (user_id) {
            dispatch({
                type: orderActions.RE_ORDER, payload: {
                    data: {
                        id: value?.id,
                        user_id: parseInt(user_id),
                        location_id: value?.location_id
                    },
                    navigate: navigate
                }
            });
        };
    };


   
    // const staticOrders = [
    //     {
    //         id: 1123,
    //         order_status: 1,
    //         order_date: "2023-04-20",
    //         total_amount: 100,
    //         discount: 10,
    //         coupon_discount: 5,
    //         shipping_Charges: 98,
    //         invoice_url: "https:www.google.com",
    //         tracking_no: "https://example.com/tracking1"
    //     },
    //     {
    //         id: 223,
    //         order_status: 8,
    //         order_date: "2024-04-25",
    //         total_amount: 150,
    //         discount: 15,
    //         coupon_discount: 0,
    //         shipping_Charges: 98,
    //         invoice_url: "https://example.com/invoice2",
    //         tracking_no: "https://example.com/tracking2"
    //     }, {
    //         id: 223,
    //         order_status: 9,
    //         order_date: "2024-04-25",
    //         total_amount: 150,
    //         discount: 15,
    //         shipping_Charges: 98,
    //         coupon_discount: 100,
    //         invoice_url: "https://example.com/invoice2",
    //         tracking_no: "https://example.com/tracking2"
    //     }
    // ];

    const currentDate = moment(); 
    // const  sortorders = [...staticOrders].sort((a,b)=>`${moment(b.order_date).format('DD-MM-YYYY')}`.localeCompare(moment(a.order_date).format('DD-MM-YYYY')))    

    return (
        <>
            {/* <div className="page_heading_div_cls">{t("My Orders")}</div> */}
            {Orderlist?.length > 0 ?
                <Row className="d-flex row-padding-remover">
                    {Orderlist.sort((a,b)=>`${moment(b.order_date).format('DD-MM-YYYY')}`.localeCompare(moment(a.order_date).format('DD-MM-YYYY'))).map((val, i) =>
                        <Col xl={5} lg={12} md={12} sm={12} className="user-profile-coupon-card-col" key={i}>
                            <div className="user-profile-coupon-card-div">
                                <div className="profile-order-status d-flex justify-content-between">
                                {val.invoice_url ? (
    <a
        className="new-user-profile-order-icons-div"
        href={val.invoice_url}
        target="_blank"
        rel="noreferrer"
        onClick={() => LogEvent('MyOrder_Download_Clicked')}
    >
        <div>
            <span className="tb-file-download"><FaDownload/> Invoice</span>
        </div>
    </a>
) : (
    <div style={{ width: '100px', height: '20px' }}></div> // Adjust width and height as needed
)}


                                    <span className={(val.order_status === 1) ? "my-order-Pending" : (val.order_status === 2) ? "my-order-On-the-way" : (val.order_status === 10) ? "my-order-return-in-transit" :
                                        (val.order_status === 3 || val.order_status === 4 || val.order_status === 6) ? "my-order-Delivered" : (val.order_status === 5 || val.order_status === 7) ? "my-order-Rejected" :
                                            (val.order_status === 8 || val.order_status === 9) ? "my-order-return-Returned" : ""}>
                                        {val.order_status === 1 ? "Pending" : val.order_status === 2 ?
                                            "On the way" : val.order_status === 3 ? "Delivered" : val.order_status === 4 ?
                                                "Dispatched" : val.order_status === 5 ? "Rejected" : val.order_status === 6 ?
                                                    "Verified" : val.order_status === 7 ? "Cancelled" : val.order_status === 8 ? "Returned" :
                                                        val.order_status === 9 ? "Refunded" : "Return in transit"}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="my-order-order-id">ID: #{val.id}</span>
                                    <span className="my-order-date">{moment(val.order_date).format('DD-MM-YYYY')}</span>
                                </div>
                                <div className="container my-order-price-container">
                                    <div className="d-flex justify-content-between">
                                        <span className="my-order-price-label">{t("Subtotal")}:</span>
                                        <span className="my-order-price">₹{parseFloat(parseFloat(val.total_amount) + parseFloat(val.discount) + parseFloat(val.coupon_discount)).toFixed(2)}</span>
                                    </div>
                                    
                                    <div className="d-flex justify-content-between">
                                        <span className="my-order-price-label">{t("Discount")}:</span>
                                        <span className="my-order-price-discount">- ₹{parseFloat(val.coupon_discount + val.discount).toFixed(2)}</span>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <span className="my-order-price-label">{t("Shipping Charges")}:</span>
                                        <span className="my-order-price">₹{parseFloat(val.shipping_charges).toFixed(2)}</span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className="my-order-price-label">{t("Total")}:</span>
                                        <span className="my-order-price-total">₹{parseFloat(val.total_amount).toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="user-profile-order-icons-div1" onClick={() => handleViewDetails(val.id)}>
                                        <FaEye /> View
                                    </div>
                                  
                                    {(val.order_status === 3 || val.order_status === 4 || val.order_status === 6) &&
                                        <div className="user-profile-order-icons-div1" onClick={() => handleReorder(val)}>
                                            <PiArrowCounterClockwise style={{ fontSize: "22px" }} /> Reorder
                                        </div>
                                    }
                                    {(val.tracking_no && val.order_status === 2) &&
                                        <a className="user-profile-order-icons-div" href={val.tracking_no} target="_blank" rel="noreferrer" onClick={() => LogEvent('MyOrder_Tracking_Clicked')}>
                                            <PiTruck style={{ fontSize: "24px" }} /> Track Now
                                        </a>
                                    }
                                </div>
                            </div>
                        </Col>
                    )}
                </Row> :
                <div className="nocoupons">
                    {t("No Data Found")}
                </div>
            }
            <OrderDetailsModal
                showModal={showDetailsModal}
                closeModal={() => setShowDetailsModal(false)}
            />
        </>
    );
}
