import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import noprofile from '../../assets/images/noprofile.png';
import { FaLocationDot } from "react-icons/fa6";
import moment from "moment-timezone";
import { Rating } from 'react-simple-star-rating';
import { useTranslation } from 'react-i18next';
import { DecryptFunction } from "../../utils/cryptoFunction";
import marketActions from '../../Redux/marketPlace/action';
import RatingsUser from "../modal/rateingUserModal";

export default function Profile() {
    const [showReviewModal, setShowReviewModal] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { sellerprofile } = useSelector((state) => state.MarketPlaceReducer);

    const openReviewModal = (sellerId) => { 
        setShowReviewModal(true);
    };

    const handleCloseReviewModal = () => {
        setShowReviewModal(false);
    };

    const handleRating = (rate, provider_id) => {
        const user_id = DecryptFunction(localStorage.getItem('auth_user'));
        if (user_id) {
            dispatch({
                type: marketActions.SERVICE_PROVIDER_RATINGS,
                payload: { provider_id: provider_id, user_id: user_id, rating: rate }
            });
        };
    };

    return (
        <div className="marketplace_seller_main_div">
            {sellerprofile?.data?.length > 0 &&
                <>
                    {sellerprofile?.data?.map((val, i) =>
                        <div key={i} className="d-flex align-items-center marketplace_seller_card_div">
                            <img src={val?.logo || noprofile} alt="" className="marketplace_seller_profile_img" />
                            <div className="marketplace_seller_profile_details">
                                <div className="marketplace-cattle-card-title mb-1">{val?.provider_name}</div>
                                <div className="marketplace-cattle-card-place-view-profile mb-1"><FaLocationDot /> {val?.address?.city || "N/A"}</div>
                                <div className="marketplace-cattle-card-postedby-iew-profile mb-1">{t("Created On")}: {moment(val?.created_at).format('DD/MM/YYYY')}</div>
                                {/* <div className="d-flex align-items-center">
                                    <Rating
                                        initialValue={val?.average_rating}
                                        className="marketplace-cattle-ratings"
                                        onClick={(rate) => handleRating(rate, val?.id)}
                                        readonly
                                    />
                                    <div className="product-details-ratings-text ml-2" onClick={() => openReviewModal(val?.id)} style={{ cursor: 'pointer' }}> {average_rating > 0 ? t("Share your review") : t("Be the first to review")}
                                    </div>
                                </div> */}
                                <div className="d-flex align-items-center">
            <Rating
                initialValue={val?.average_rating}
                className="marketplace-cattle-ratings"
                onClick={(rate) => handleRating(rate, val?.id)}
                readonly
            />
            <div className="product-details-ratings-text ml-2 m-2" onClick={() => openReviewModal(val?.id)} style={{ cursor: 'pointer' }}>
                {val?.average_rating > 0 ? t("Share your review") : t("Be the first to review")}
            </div>
        </div>
                            </div>
                        </div>
                    )}
                </>
            }
            {showReviewModal && (
                <RatingsUser
                    showModal={showReviewModal}
                    onCancel={() => setShowReviewModal(false)}
                />
              
            )}
        </div>
    );
};