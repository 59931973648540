import actions from "./action";

const initState = {
    LiveStock: [],

}

const LiveStockReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_LIVE_STOCK:
            {
                return {
                    ...state,
                    LiveStock: action.payload
                }
            }
        default: return state;
    }
}
export default LiveStockReducer;