import React from "react";
// import { Spinner } from "react-bootstrap";

export default function PageLoader() {

    return (
        // <Spinner />
        <div className="loading-screen">
            <div className="loading-spinner"></div>
        </div>
    )
}