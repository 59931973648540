import userActions from "./actions";
import commonActions from "../common/actions";
import cartActions from "../cart/actions";
import homeActions from '../Home/action';
import farmActions from '../sellerProfile/action';
import marketActions from '../marketPlace/action'
import { takeEvery, call, all, put, delay, select } from "redux-saga/effects";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import { DecryptFunction } from "../../utils/cryptoFunction";
// import { useHistory } from "react-router-dom";

const userSagas = function* () {

    yield all([
        yield takeEvery(userActions.GET_USER_ADDRESS, getUserAddress),
        yield takeEvery(userActions.CREATE_USER_ADDRESS, createUserAddress),
        yield takeEvery(userActions.GET_CATTLE_FARM_DEATILS, getCattleFarmDeatils),
        yield takeEvery(userActions.LOG_OUT_PROFILE, logoutUserProfile),
        yield takeEvery(userActions.CREATE_FARM_DEATILS, CreateCattleList),
        yield takeEvery(userActions.UPDATE_USER_PROFILE, updateUserProfile),
        yield takeEvery(userActions.UPDATE_USER_ADDRESS, updateUserAddress),
        yield takeEvery(userActions.REMOVE_USER_ADDRESS, removeUserAddress),
        yield takeEvery(userActions.CREATE_USER_FARM, createUserFarm),
        yield takeEvery(userActions.UPDATE_USER_FARM, updateUserFarm),
        yield takeEvery(userActions.UPLOAD_IMAGE_TO_AWS, uploadImageToAWS),
        yield takeEvery(userActions.GET_PAYMENT_PLAN, subscriptionPayment),
    ])
};

const subscriptionPayment = function* () {
    yield put({ type: commonActions.PAGE_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/payment_plans`)
        )
        yield put({
            type: userActions.SET_PAYMENT_PLAN,
            payload: result?.data
        });

    } catch (error) {
        console.log("Error in catch", error)
    }

    yield put({ type: commonActions.PAGE_LOADER, payload: false });
}



const getUserAddress = function* (data) {

    const { payload } = data;
    const { userAddress } = yield select(state => state.userReducer);
    if ((userAddress?.length === 0 && !payload?.type) || payload?.type) {
        try {
            const result = yield call(() =>
                axios.get(`${API_URL}/v1/user/useraddress/${payload?.id}`)
            );
            if (result?.data?.code === 200) {
                yield put({ type: userActions.SET_USER_ADDRESS, payload: result?.data?.data });
            };
        } catch (err) {
            console.log(err);
        };
    }
};

const createUserAddress = function* (data) {

    const { payload } = data;

    try {
        const userid = DecryptFunction(localStorage.getItem('auth_user'));
        if (userid) {
            let payloadData = { ...payload?.data, user_id: userid };
            const result = yield call(() =>
                axios.post(`${API_URL}/v1/user/useraddress`, payloadData)
            );
            if (result?.data?.code === 200) {
                yield put({ type: userActions.GET_USER_ADDRESS, payload: { id: userid, type: "updated" } });
                if (sessionStorage.getItem('addtocart') === 'Address') {
                    sessionStorage.removeItem('addtocart');
                    yield put({ type: cartActions.ADD_PRODUCT_TO_CART });
                };
            }
        };
        yield put({ type: commonActions.SET_SHOW_USER_ADDRESS_MODAL, payload: false });
    } catch (err) {
        console.log(err);
    };
};

const getCattleFarmDeatils = function* (data) {

    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/user/farm/${payload}`)
        );
        if (result?.data?.code === 200) {
            yield put({
                type: userActions.SET_CATTLE_FARM_DEATILS,
                payload: result?.data?.data
            });
        };
    } catch (err) {
        console.log(err);
    };
};


const logoutUserProfile = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.delete(`${API_URL}/v1/userlogout/${payload}`)
        );
        localStorage.removeItem('token');
        localStorage.removeItem('auth_user_no');
        localStorage.removeItem('auth_user');
        localStorage.removeItem('auth_user_provider_id');
        window.location.href = '/';
        yield put({ type: commonActions.SHOW_LOGOUT_MODAL, payload: false });
        yield put({ type: commonActions.GET_COMMON_TOKEN });
    } catch (err) {
        console.log(err);
    }
}



const CreateCattleList = function* (data) {
    const { payload } = data;

    // Include the user_id in the payload
    const updatedPayload = {
        ...payload,
        // user_id: user.id,
    };

    try {
        yield call(() =>
            axios.post(`${API_URL}/v1/user/farm`, updatedPayload)
        );
    } catch (err) {
        console.log(err);
    };
};

const updateUserProfile = function* (data) {

    const { payload } = data;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/v1/users/${payload.id}`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                show: true,
                status: result?.data?.code === 200 ? "success" : "failed",
                message: result?.data?.message,
            },
        });
        yield put({ type: homeActions.GET_USER_PROFILE, payload: { id: payload?.id, type: 'updated' } });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, message: null, status: null },
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateUserAddress = function* (data) {

    const { payload } = data;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });    

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/v1/user/useraddress/${payload.id}`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                show: true,
                status: result?.data?.code === 200 ? "success" : "failed",
                message: result?.data?.code === 200 ? "Address Updated Successfully" : result?.data?.message,
            },
        });
        yield put({ type: userActions.GET_USER_ADDRESS, payload: { id: payload?.data?.user_id, type: "updated" } });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, message: null, status: null },
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const removeUserAddress = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.delete(`${API_URL}/v1/user/useraddress/${payload?.id}`)
        );
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                show: true,
                status: result?.data?.code === 200 ? "success" : "failed",
                message: result?.data?.message,
            },
        });
        if (payload?.value?.default_address === 1) {
            const { userAddress } = yield select(state => state.userReducer);
            if (userAddress?.length > 1) {
                yield put({
                    type: userActions.UPDATE_USER_ADDRESS, payload: {
                        id: userAddress[1]?.id,
                        data: {
                            "address_line1": userAddress[1]?.address_line1,
                            "address_line2": userAddress[1]?.address_line2,
                            "address_line3": userAddress[1]?.address_line3,
                            "city": userAddress[1]?.city,
                            "state": userAddress[1]?.state,
                            "country": userAddress[1]?.country,
                            "other_address_details": userAddress[1]?.other_address_details,
                            "user_id": payload?.user_id,
                            "pincode": userAddress[1]?.pincode,
                            "address_type": userAddress[1]?.address_type,
                            "default_address": 1
                        }
                    }
                });
            }
        }
        yield put({ type: userActions.GET_USER_ADDRESS, payload: { id: payload?.user_id, type: "updated" } });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, message: null, status: null },
        });
    } catch (err) {
        console.log(err);
    };
};

const createUserFarm = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/user/farm`, payload)
        );
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                show: true,
                status: result?.data?.code === 200 ? "success" : "failed",
                message: result?.data?.message,
            },
        });
        if (result?.data?.code === 200) {
            yield put({ type: userActions.GET_CATTLE_FARM_DEATILS, payload: payload?.user_id });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, message: null, status: null },
        });
    } catch (err) {
        console.log(err);
    };
};

const updateUserFarm = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/v1/user/farm/${payload?.id}`, payload?.data)
        );
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                show: true,
                status: result?.data?.code === 200 ? "success" : "failed",
                message: result?.data?.message,
            },
        });
        if (result?.data?.code === 200) {
            yield put({ type: userActions.GET_CATTLE_FARM_DEATILS, payload: payload?.data?.user_id });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { show: false, message: null, status: null },
        });
    } catch (err) {
        console.log(err);
    };
};

const uploadImageToAWS = function* (data) {
    const { payload } = data;

    try {
        const formData = new FormData();
        formData.append('files', payload?.files);

        const result = yield call(() =>
            axios.post(`${API_URL}/v1/user/uploadimage`, formData)
        );
        if (result?.data?.code === 200) {
            if (payload?.type === 'Profile') {
                yield put({
                    type: userActions.UPDATE_USER_PROFILE,
                    payload: { data: { img_url: result?.data?.data[0]?.path }, id: payload?.data?.id }
                });
            } else if (payload?.type === 'SellerRegistration') {
                let data = { ...payload?.data?.formData, logo: result?.data?.data[0]?.path };
                yield put({
                    type: farmActions.CREATE_SELLER_USER,
                    payload: { data: data, page: payload?.data?.page, navigate: payload?.data?.navigate },
                });
            } else if (payload?.type === 'sellerProfileUpdate') {
                let data = { ...payload?.data?.formData, logo: result?.data?.data[0]?.path };
                yield put({
                    type: marketActions.UPDATE_SELLER_PROFILE,
                    payload: { data: data, id: payload?.data?.id },
                });
            }
        }
    } catch (err) {
        console.log(err);
    };
};


export default userSagas;