import { Col, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { FiShoppingCart } from "react-icons/fi";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { priceRoundOff } from "../../utils/validation";
import { useSelector } from "react-redux";

const ProductGridView = ({ Products, handleViewDetails, handleAddToCart, handleQtyValue }) => {

  const { t } = useTranslation();
  const { cartProducts } = useSelector(state => state.cartReducer);

  // const qtyList = Array.from({ length: 10 }, (_, index) => index + 1);

  return (
    <>
      {Products?.length > 0 ?
        <>
          {Products?.map((val, i) =>
            <Col xxl={4} xl={6} md={6} sm={12} xs={12} key={i} className="card-grid-view-col-cls">
              <div className="product-grid-view-card">
                <div className="d-flex justify-content-center">
                  <img src={val.image_url} className="card_grid_view_img cursor-pointer" alt="..." onClick={(e) => handleViewDetails(e, val?.id, val)} />
                </div>
                {val?.discount_status === 1 &&
                  <>
                    {(val.discount_amount > 0) && (
                      <span className="product-discount-span"
                      >
                        <>
                          {val?.discount_type === 1 ?
                            `${val.discount_amount}% OFF` :
                            `- ₹${val.discount_amount} OFF`
                          }
                        </>
                      </span>
                    )}
                  </>
                }
                <div className="product_grid_view_cls_for_details_main">
                  <h6 className="product-grid-view-name cursor-pointer" onClick={(e) => handleViewDetails(e, val?.id, val)}>
                    {val?.finalLanguage?.medicine_name || val?.medicine_name}
                  </h6>
                  <h6 className="card-subtitle product-grid-view-desc product-grid-disc-height mb-2 cursor-pointer" onClick={(e) => handleViewDetails(e, val?.id, val)}>
                    {val?.finalLanguage?.discription || val.discription}
                  </h6>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="product_page_price_main_div_cls">
                      {(val?.discount_status === 1) ?
                        <>
                          <span className="product-list-view-product-amount-del-tag me-1"><MdOutlineCurrencyRupee /><del >{val.amount}</del></span>
                          {(val?.discount_type === 1) ?
                            <span className="product-final-price ms-1"> <MdOutlineCurrencyRupee />{priceRoundOff(val?.amount - (val?.amount * val?.discount_amount) / 100)}/-</span> :
                            <span className="product-final-price ms-1"><MdOutlineCurrencyRupee />{priceRoundOff(val?.amount - val?.discount_amount)}/-</span>
                          }
                        </> :
                        <span className="product-final-price">₹{val?.amount}/-</span>
                      }
                    </div>
                    <select className="product_list_card_qty_select_cls" onChange={(e) => handleQtyValue(e, val, i)}>
                                                {Array.from({ length: val.max_qty - val.min_qty + 1 }, (_, index) => val.min_qty + index).map((qty, ind) =>
                                                    <option key={ind} selected={qty === (cartProducts?.order_items?.find((item) => item?.product_id === val?.id)?.quantity)}>
                                                        {qty}
                                                    </option>
                                                )}
                                            </select>
                  </div>
                  <div className="product-grid-view-btn-div mt-3">
                    <Button
                      type="button"
                      className="product-listview-addtocart"
                      title="Add to cart"
                      onClick={(e) => handleAddToCart(e, val)}
                    >
                      <FiShoppingCart size={16} /> <span className="ms-2">{t("Add To Cart")}</span>
                    </Button>
                    {/* } */}
                  </div>
                </div>
              </div>
            </Col>
          )}
        </> :
        <Col md={12} className="product-notfound-cls">
          <h4>{t("No Products Found")}</h4>
        </Col>
      }
    </>
  );
};

export default ProductGridView;
