import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Button } from "react-bootstrap";
import { FaLocationDot } from "react-icons/fa6";
import AnimalImageGrid from "../marketplaceLiveStock/animalImageGrid";
import moment from "moment-timezone";
import { PiEye, PiPhoneFill, PiShareFatFill } from "react-icons/pi";
import { Rating } from 'react-simple-star-rating';
import { RWebShare } from 'react-web-share';
import ImageModal from "../marketplaceLiveStock/imageModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import marketActions from '../../Redux/marketPlace/action';
import { EncryptFunction } from "../../utils/cryptoFunction";
import { FRONTEND_URL } from "../../utils/constant";
import DisclaimerModal from "./disclaimerModal";
import noprofileimg from '../../assets/images/noprofile.png';
import { BiWorld } from "react-icons/bi";

export default function CattleDetailsModal(props) {

    const { showModal, handleImageClose, cattleId, page,closeModal } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { individuallivestock } = useSelector(state => state.MarketPlaceReducer);

    const [showImage, setShowImage] = useState({ isOpen: false, data: null });
    const [fetchedData, setFetchedData] = useState([]);
    const [contactViewable, setContactViewable] = useState(false);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState({ isOpen: false, data: null });
    const { currentLanguage, pageLoader } = useSelector(state => state.commonReducer);
    const { recentDisease } = useSelector(state => state.farmTypeReducer);

    useEffect(() => {
        if (cattleId) {
            dispatch({
                type: marketActions.GET_INDIVIDUAL_LIVESTOCK_FROM_THEIR_ID,
                payload: cattleId,
            });
        };
        //eslint-disable-next-line
    }, [cattleId]);

    useEffect(() => {
        const list = individuallivestock?.map((val) => {
            let images = [
                { url: val?.cattle?.image_front_url || null, type: 'front' },
                { url: val?.cattle?.image_side_url || null, type: 'side' },
                { url: val?.cattle?.image_body_url || null, type: 'body' },
                { url: val?.cattle?.video_url || null, type: 'video' },
            ];
            //eslint-disable-next-line
            let cattleImages = images.filter((img) => {
                if (img.url) {
                    return img;
                }
            });
            let data = { ...val, images: cattleImages }
            return data;
        });
        setFetchedData(list);
    }, [individuallivestock]);

    const handleShowImagesModal = () => {
        if (fetchedData[0]?.images?.length > 0) {
            setShowImage({ isOpen: true, data: fetchedData[0]?.images });
        }
    };

    const handleViewSeller = (id) => {
        const encryptedId = EncryptFunction(id);
        if (encryptedId) {
            navigate(`/marketplace-seller?seller=${encryptedId}`);
        };
    };

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
        >
            <div className="modal_close_icons" onClick={() => {
                closeModal();
            }}>
            
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
            </div>
            <Container className="my-4">
                {fetchedData?.length > 0 ?
                    <>
                        {fetchedData?.map((val, i) =>
                            <div key={i} className="marketplace-cattle-card-div">
                                <div className="d-flex justify-content-between maretplace-cattle-card-head">
                                    <div className="marketplace-cattle-card-col1">
                                        <div className="marketplace-cattle-card-title">{val?.listing?.title} <span className="cattle_list_milk_productions_span">{`${val?.cattle?.milk_production_litres > 0 ? `(${val?.cattle?.milk_production_litres} ltr milk/day)` : ""}`}</span></div>
                                        <div className="marketplace-cattle-card-place"><FaLocationDot className="pb-1" size={16} /> {val?.listing?.place || "N/A"}</div>
                                        <div className="marketplace-cattle-card-postedby"><BiWorld /> {val?.listing?.published_at ? moment(val?.listing?.published_at).fromNow() : "N/A"}</div>
                                    </div>
                                    <div className="marketplace-cattle-rate-and-view mt-2">
                                        <div className="marketplace-cattle-card-rate">₹{val?.listing?.rate}</div>
                                        <div className="maretplace-cattle-view"><PiEye className="marketplace-cattle-eye-icon" /> {val?.listing?.viewed} views</div>
                                    </div>
                                </div>
                                <AnimalImageGrid
                                    images={val?.images || []}
                                    handleShowImagesModal={handleShowImagesModal}
                                />
                                <div className="my-3 marketplace-cattle-age-main-div-cls">
                                    {val?.cattle?.age > 0 &&
                                        <div className="marketplace-cattle-age-div">
                                            <div className="marketplace-cattle-age-header">{t("Age")}</div>
                                            <div className="marketplace-cattle-age-value">{val?.cattle?.age} {val?.cattle?.age === 1 ? "yr" : "yrs"} old</div>
                                        </div>
                                    }
                                    {val?.cattle?.gender &&
                                        <div className="marketplace-cattle-age-div">
                                            <div className="marketplace-cattle-age-header">{t("Gender")}</div>
                                            <div className="marketplace-cattle-age-value">{val?.cattle?.gender}</div>
                                        </div>
                                    }
                                    {val?.cattle?.last_calving_date &&
                                        <div className="marketplace-cattle-age-div">
                                            <div className="marketplace-cattle-age-header">{t("Last Calving date")}</div>
                                            <div className="marketplace-cattle-age-value">{moment(val?.cattle?.last_calving_date).format('DD/MM/YYYY')}</div>
                                        </div>
                                    }
                                    {/* {val?.cattle?.disease_history?.length > 0 &&
                                        <div className="marketplace-cattle-age-div">
                                            <div className="marketplace-cattle-age-header">{t("Disease")}</div>
                                            <div className="marketplace-cattle-age-value">{val?.cattle?.disease_history?.join(", ")}</div>
                                        </div>
                                    } */}
                                       {val.cattle.disease_history.map(diseaseId => {
                                        const disease = recentDisease.find(d => `${d.id}` === `${diseaseId}`);
                                        if (disease) {
                                            const languageDisease = disease.language.find(lang => lang.language_code === currentLanguage)?.disease;
                                            return (
                                                <div className="marketplace-cattle-age-div" key={diseaseId}>
                                                                                                <div className="marketplace-cattle-age-header">{t("Diseases")}</div>

                                                    {languageDisease || t(disease.name)}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    }).filter(Boolean)}
                                </div>
                                <div>
                                    <div className="marketplace-cattle-listing-decription">{val?.listing?.description}</div>
                                </div>
                                {page === 'view' &&
                                    <div className="my-3 marketplace_cattle_grid_btn_main_div">
                                        <Row className="marketplace_cattle_grid_btn_row">
                                            <Col md={6} sm={12} className="marketplace-cattle-provider-col cursor-pointer" onClick={() => handleViewSeller(val?.listing?.provider_id)}>
                                                <img src={val?.listing?.provider?.logo || noprofileimg} alt="" className="marketplace-cattle-list-profile-img" />
                                                <div>
                                                    <div className="marketplace-cattle-card-postedby-div">{t("Posted by")} : {val?.listing?.provider?.provider_name}</div>
                                                    <Rating
                                                        initialValue={val?.listing?.provider?.average_rating}
                                                        className="marketplace-cattle-ratings"
                                                    // onClick={(rate) => handleRating(rate, val?.listing?.provider_id)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6} sm={12} className="marketplace-cattle-btn-col-cls">
                                                <Button
                                                    size="sm"
                                                    onClick={() => {
                                                        if (!contactViewable) {
                                                            setShowDisclaimerModal({ isOpen: true, data: null });
                                                        }
                                                    }}
                                                >
                                                    <PiPhoneFill className="marketplace-cattle-btn-icons" /> {contactViewable ? val?.listing?.provider?.mobile_no : t("Contact")}
                                                </Button>
                                                <RWebShare
                                                    data={{
                                                        text: "Like humans, flamingos make friends for life",
                                                        url: `${FRONTEND_URL}/marketplace-single?cattle=${cattleId}`,
                                                        title: "Flamingos",
                                                    }}
                                                // onClick={() => handleShareClick(val?.cattle?.id)}
                                                >
                                                    <Button size="sm"><PiShareFatFill className="marketplace-cattle-btn-icons" />{t("Share")}</Button>
                                                </RWebShare>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </div>
                        )}
                    </> :
                    <div className="no-data-found-div-cls">{t("No Data Found")}</div>
                }
                <ImageModal
                    showModal={showImage?.isOpen}
                    closeModal={() => setShowImage(false)}
                    images={showImage?.data}
                />
                <DisclaimerModal
                    showModal={showDisclaimerModal?.isOpen}
                    closeModal={() => setShowDisclaimerModal({ isOpen: false, data: null })}
                    agreeClick={() => {
                        setContactViewable(true);
                        setShowDisclaimerModal({ isOpen: false, data: null });
                    }}
                />
            </Container>
        </Modal>
    )
}