import React, { useEffect, useState } from "react";
import { Modal, Col, Container, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import marketActions from '../../Redux/marketPlace/action';
import farmActions from '../../Redux/sellerProfile/action';
import userActions from "../../Redux/user/actions";
import { DecryptFunction } from "../../utils/cryptoFunction";
import SellerSelectAddressModal from "../SellerProfile/SellerSelectAddressModal";
import commonActions from "../../Redux/common/actions";
import { useTranslation } from 'react-i18next';
import { PiCameraBold } from 'react-icons/pi';
import noprofile from '../../assets/images/noprofile.png';
import { AlphabetsValidation, handleEmailValidation } from "../../utils/validation";
// import { AlphabetsValidation, handleEmailValidation } from "../../utils/validation";
import { LogEvent } from "../../utils/firebase";

export default function SellerProfileUpdateModal(props) {

    const { showModal, closeModal } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { sellerprofile } = useSelector(state => state.MarketPlaceReducer);

    const { farmList } = useSelector((state) => state.farmTypeReducer);

    const { Addressloglat, buttonLoader } = useSelector(state => state.commonReducer);

    const { handleSubmit, register, reset, formState: { errors } } = useForm();
    const [selleraddress, setSelleraddress] = useState();
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [addressErr, setAddressErr] = useState(false);
    const [logo, setLogo] = useState({ file: null, url: null, type: null });

    useEffect(() => {
        dispatch({ type: farmActions.GET_FARM_TYPES });

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const user = DecryptFunction(localStorage.getItem("auth_user"));
        if (user) {
            dispatch({ type: userActions.GET_USER_ADDRESS, payload: { id: user } });
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const mobile_no = DecryptFunction(localStorage?.getItem('auth_user_no'));
        const user_id = DecryptFunction(localStorage?.getItem('auth_user'));
        if (mobile_no && user_id) {
            reset({
                "mobile_no": mobile_no,
                "user_id": user_id
            });
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sellerprofile?.code === 200) {
            reset({
                mobile_no: sellerprofile?.data[0]?.mobile_no,
                provider_name: sellerprofile?.data[0]?.provider_name,
                farm_type_id: sellerprofile?.data[0]?.farm_type_id,
                email: sellerprofile?.data[0]?.email
            });
            setLogo({ file: null, url: sellerprofile?.data[0]?.logo, type: "old" });
            setSelleraddress(sellerprofile?.data[0]?.address);
            dispatch({
                type: commonActions.SET_SELLER_PROFILE_SERVICES_SELECTED_LOCATION,
                payload: {
                    Coordinates: { latitude: sellerprofile?.data[0]?.latitude, longitude: sellerprofile?.data[0]?.longitude },
                }
            });
        }
        //eslint-disable-next-line
    }, [sellerprofile]);

    const handleShowAddressModal = () => {
        setShowAddressModal(true);
    };

    const handleAddress = (Address) => {
        setSelleraddress(Address);
        dispatch({
            type: commonActions.GET_ADDRESS_COORDINATES,
            payload: Address,
        });
        setShowAddressModal(false);
    };

    const onSubmit = (data) => {
        LogEvent('Profile_Edit_SellerProfile_Clicked')
        if (!selleraddress?.address_line1) {
            setAddressErr(true);
        } else {
            setAddressErr(false);
            const finalData = {
                ...data,
                latitude: Addressloglat?.Coordinates?.latitude,
                longitude: Addressloglat?.Coordinates?.longitude,
                address: {
                    address_line1: selleraddress?.address_line1,
                    address_line2: selleraddress?.address_line2,
                    address_line3: selleraddress?.address_line3,
                    city: selleraddress?.city,
                    state: selleraddress?.state,
                    country: selleraddress?.country,
                    pincode: selleraddress?.pincode,
                    other_address_details: selleraddress?.other_address_details
                }
            };
            if (logo?.file) {
                dispatch({
                    type: userActions.UPLOAD_IMAGE_TO_AWS,
                    payload: {
                        files: logo?.file, type: 'sellerProfileUpdate',
                        data: { formData: finalData, id: sellerprofile?.data[0]?.id }
                    }
                });
            } else {
                dispatch({
                    type: marketActions.UPDATE_SELLER_PROFILE,
                    payload: { data: finalData, id: sellerprofile?.data[0]?.id },
                });
            }
        };
        setTimeout(() => {
            closeModal();
        }, 1000);
    };

    const handleImageChange = (e) => {
        if (e.target.files?.length > 0) {
            setLogo({ file: e.target.files[0], url: URL.createObjectURL(e.target.files[0]), type: 'new' });
        };
    };

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            size="md"
        >
            <div className="modal_close_icons"
                onClick={closeModal}
            >
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
            </div>
            <Container className="modal-container-cls">
                <h5 className=" d-flex justify-content-center">Update Seller Information</h5>
                <Row className="row-padding-remover">
                <Row className="mb-4 d-flex justify-content-between">
            <Col xs={6} xl={6} className="mb-3 mb-xl-0">
            {/* <Col xs={12} xl={6} className="d-flex justify-content-between mb-3 mb-xl-0"> */}
                <div className="user-profile-page-profile-img d-flex align-items-center">
                    <img src={logo?.url || noprofile} alt="" className="user-profile-page-profile" />
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        onChange={handleImageChange}
                    />
                    <label htmlFor="contained-button-file">
                        <div className="user-profile-edit-icon">{t("Edit")}</div>
                    </label>
                </div>
            </Col>
            <Col xs={6} xl={6} className="button-profile d-flex justify-content-end mt-4">
      <button
        className="btn btn-primary mb-4"
        type="submit"
        onClick={handleSubmit(onSubmit)}
      >
        {buttonLoader ? <Spinner variant="light" size="sm" /> : t("Save Changes")}
      </button>
    </Col>
        </Row>
                    <Col xl={12} md={12} className="pb-4">
                        <div className="form-group">
                            <label className="form-label form-label-custom-style">{t("Name")}</label>
                            <input
                                className="form-control input-bg-none"
                                {...register('provider_name', 
                                { 
                                    pattern: {
                                        value: /^[a-zA-Z ]*$/,
                                        message: "Name should only contain alphabets",
                                    }
                                }
                            )}
                                onInput={AlphabetsValidation}
                            />
                        </div>
                    </Col>
                    <Col xl={12} md={12} className="pb-4">
                        <div className="form-group">
                            <label className="form-label form-label-custom-style">{t("Farm Type")} <span className="fw-bold text-danger">*</span></label>
                            <select
                                className="form-control input-bg-none ms-0"
                                {...register('farm_type_id', {
                                    required: "*required"
                                })}
                                defaultValue={""}
                            >
                                <option value={""} disabled>--Select--</option>
                                {farmList?.map((farm, i) =>
                                    <option value={farm?.id} key={i}>{farm?.title}</option>
                                )}
                            </select>
                            {errors?.farm_type_id &&
                                <small className="text-danger">{errors?.farm_type_id?.message}</small>
                            }
                        </div>
                    </Col>
                    <Col xl={12} className="pb-4">
                        <div className="form-group">
                            <label className="form-label form-label-custom-style">{t("Email")}</label>
                            <input
                                className="form-control input-bg-none"
                                {...register('email', {
                                    validate: (value) => {
                                        if (!value) return true;
                                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                        let validation = emailRegex.test(value);
                                        if (!validation) {
                                            return 'Enter valid Email id';
                                        } else {
                                            return;
                                        }
                                    }
                                })}
                            />
                            {errors?.email &&
                                <small className="text-danger">{errors?.email?.message}</small>
                            }
                        </div>
                    </Col>
                    <Col xl={12} className="pb-4">
    <label className="form-label form-label-custom-style">
        {t("Address")} <span className="fw-bold text-danger">*</span>
    </label>
    <div className="ms-4">
        {selleraddress && (
            <div className="Farm-Details-book-address mb-2 text-success">
                <div>{`${selleraddress?.address_line1}, ${selleraddress?.address_line2}, ${selleraddress?.address_line3},`}</div>
                <div>{`${selleraddress?.city} - ${selleraddress?.pincode}.`}</div>
                <div>{`${selleraddress?.state}, ${selleraddress?.country}`}</div>
            </div>
        )}
        
                                            <div className="update-seller-profile-text ml-2" onClick={handleShowAddressModal} style={{ cursor: 'pointer' }}> {selleraddress ? "Change Address >" : "Select Address"}</div>

    </div>
</Col>

                    {addressErr &&
                        <p className="text-danger my-2">Address is required</p>
                    }
                    {/* <Col xl={12} className="pt-2">
                        <button
                            className="btn btn-primary mb-4 me-2"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {buttonLoader ? <Spinner variant="light" size="sm" /> : t("Save Changes")}
                        </button>
                    </Col> */}
                </Row>
            </Container>
            <SellerSelectAddressModal
                showModal={showAddressModal}
                closeModal={() => setShowAddressModal(false)}
                handleAddress={handleAddress}
            />
        </Modal>
    )
};