import React from "react";
import CheckoutIndex from "../components/checkout";
import Footer from "../components/commonComponents/footer";
import Navbar from '../components/commonComponents/Navbar/Navbar';

export default function Checkout(){

    return(
        <>
            {/* <TopNavbar /> */}
            <Navbar />
            <CheckoutIndex />
            <Footer />
        </>
    )
}