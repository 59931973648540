import actions from "./action";

const initState = {
    FarmEquipments: []

}

const FarmEquipmentsReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_FARM_EQUIPMENTS:
            {
                return {
                    ...state,
                    FarmEquipments: action.payload
                }
            }
        default: return state;
    }
}
export default FarmEquipmentsReducer;