const actions = {
    GET_PRODUCTS_LIST: "GET_PRODUCTS_LIST",
    SET_PRODUCTS_LIST: "SET_PRODUCTS_LIST",
    GET_PRODUCT_LIST_BY_CATEGORY: 'GET_PRODUCT_LIST_BY_CATEGORY',
    SET_PRODUCT_LIST_BY_CATEGORY: 'SET_PRODUCT_LIST_BY_CATEGORY',
    SET_ALL_PRODUCT_LIST: 'SET_ALL_PRODUCT_LIST',
    SET_PRODUCT_FILTER_LIST: 'SET_PRODUCT_FILTER_LIST',
    SELECTED_PRODUCT_FILTER: 'SELECTED_PRODUCT_FILTER',
    PRODUCT_FILTER: 'PRODUCT_FILTER',
    GET_PRODUCT_DETAILS: 'GET_PRODUCT_DETAILS',
    SET_PRODUCT_DETAILS: 'SET_PRODUCT_DETAILS',
    SET_RELATED_PRODUCTS: 'SET_RELATED_PRODUCTS',
    POST_RATEING_PRODUCTS: 'POST_RATEING_PRODUCTS',
    GET_PRODUCTS_RATEING: 'GET_PRODUCTS_RATEING',
    SET_PRODUCTS_RATEING: 'SET_PRODUCTS_RATEING',
    GET_PRODUCT_FILTER: 'GET_PRODUCT_FILTER',
    SET_PRODUCT_FILTER: 'SET_PRODUCT_FILTER',
}
export default actions;