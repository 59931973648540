import React from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export default function CattleAgreeModal(props) {

    const { showModal, closeModal } = props;

    const { t } = useTranslation();

    return (
        <Modal
            show={showModal}
            onHide={closeModal}
            centered
            size="md"
        >
            <div className="modal_close_icons"
                onClick={closeModal}
            >
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
            </div>
            <Container className="my-4">
                <h4 className="mb-4 text-center">{t("Terms & Conditions")}</h4>
                <div className="disclaimer-modal-content-div">
                    <div>1 : {t("Definition for Seller: A person who owns an live dairy cow or cows or buffalo or any combinations and he/she wishes to trade the animal(s).")}</div>
                    <div>2 : {t("No person other than owner of the animal (Dairy cow or buffalo) has the right to list the animal for trading.")}</div>
                    <div>3 : {t("Only sound, healthy and vaccinated (FMD/ SD etc) animals should be listed for trading.")}</div>
                    <div>4 : {t("The information provided by the owner about the animal should be true to his knowledge and it should not mislead the buyers.")}</div>
                    <div>5 : {t("It is the duty of the owner to inform Your Farm about the status of the animal once it is sold out.")}</div>
                    <div>6 : {t("Your Farm is only a platform to list and create visibility to buyers. Owner cannot claim or force Your Farm to compulsory sellinmg of his/her animal(s).")}</div>
                    <div>7 : {t("If any party (buyer) shows interested or willing to buy the animal it is the responsibility of the owner to make necessary arrangements toallow the party to visit his farm and the animal(s).")}</div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <Button className="me-2" onClick={closeModal}>{t("Close")}</Button>
                </div>
            </Container>
        </Modal>
    )
}