import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LogEvent } from "../../utils/firebase";
import QrCode from "../../assets/images/static YourfarmQR.png"
import Instagram from "../../assets/images/socailmedia/instagram.png"
import Facebook from "../../assets/images/socailmedia/facebook.png"
import Youtube from "../../assets/images/socailmedia/youtube.png"
import LinkedIn from "../../assets/images/socailmedia/linkedin.png"
import HomeActions from "../../Redux/Home/action"

const Footer = () => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { websiteLanguages, currentLanguage } = useSelector((state) => state.commonReducer);
  const { PrivacyPolicy } = useSelector((state) => state.AuthReducer);

// console.log("check-001",PrivacyPolicy.data.map(privacyPolicy,i)=>(
//   {privacyPolicy?.language?.find((lang)=>lang?.language_code ==== currentLanguage)?.content}
// ))  


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);


useEffect(() => {
  if (localStorage.getItem("token") && localStorage.getItem("auth_user")) {
      dispatch({ type: HomeActions.GET_PRIVACY_POLICY });
  }
}, [dispatch]); 




  return (
    <>
      <div className="container-fluid footer-section" >
        <Row>
          <hr />
        </Row>
        <div className="footer-row-container container-fluid">
          <Row className="justify-content-around">
            <Col xs={12} sm={12} md={4} lg={5}>
              <div className="d-flex flex-column">
                <div className="address-container"> 
                  <h3 className="footer-col-header">{t("Animeta Agritech Private Limited")}</h3>
                  <div className="footer-col-content">
                    <p style={{ marginTop: "20px" }}>
                      {t("11/4,Pooja Garden")},<br />
                      {t("Kalapatti Main Road")},<br />
                      {t("Civil Aerodrome Post")},<br />
                      {t("Coimbatore, Tamil Nadu-641 014")}<br />
                      {t("India")}<br />
                    </p>
                  </div>
                </div>
              </div>

            </Col>
            <Col xs={12} sm={12} md={3} lg={2} className="mt-4 mt-md-0">
              <h3 className="footer-col-header">{t("Popular Products")}</h3>
              <ul className="footer-col-content footer-col2-section">
                <li
                  onClick={() => {
                    LogEvent('Footer_HerbalProducts')
                    navigate('/products?category=herbal')
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("Herbal Products")}
                </li>
                <li
                  onClick={() => {
                    LogEvent('Footer_Feeds_Supplements')
                    navigate('/products?category=feedsandsupplement')
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("Feeds Supplements")}
                </li>
                <li
                  onClick={() => {
                    LogEvent('Footer_Marketplace')
                    navigate('/service-providers')
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("MarketPlace")}
                </li>
                <li
                  onClick={() => {
                    LogEvent('Blogs')
                    navigate('/blog')
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("Blogs")}
                </li>
                <li
                  onClick={() => {
                    LogEvent('Videos')
                    navigate('/videos')
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("Videos")}
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={6} md={3} lg={3} className="mt-4 mt-md-0">
              <h3 className="footer-col-header">{t("Contact us")}</h3>
              <div className="footer-col-content">
                <p>
                  {t("Customer Care")}: +91 63837 17150
                  <br />
                  {t("Email")}: info@yourfarm.co.in
                  <hr className="footer-contactus-hr" />
                  <section className="mb-3 footer-social-icon-section">
                    <a href="https://www.facebook.com/digitalveterinary/"
                      role="button"
                      title="Follow Us On Instagram"
                      target="_blank"
                      onClick={() => LogEvent('Footer_Instagram_Clicked')}
                      class="image-link">
                      <img src={Facebook} alt="Instagram QR Code" />
                    </a>

                    <a href="https://www.youtube.com/@yourfarm4270/videos"
                      role="button"
                      title="Follow Us On Instagram"
                      onClick={() => LogEvent('Footer_Instagram_Clicked')}
                      target="_blank"
                      class="image-link">
                      <img src={Youtube} alt="Instagram QR Code" />
                    </a>

                    <a href="https://www.instagram.com/yourfarm_veterinary_services"
                      role="button"
                      title="Follow Us On Instagram"
                      onClick={() => LogEvent('Footer_Instagram_Clicked')}
                      target="_blank"
                      class="image-link">
                      <img src={Instagram} alt="Instagram QR Code" />
                    </a>

                    <a href="https://www.linkedin.com/company/yourfarm-agritech/"
                      role="button"
                      title="Follow Us On Instagram"
                      onClick={() => LogEvent('Footer_Instagram_Clicked')}
                      target="_blank"
                      class="image-link">
                      <img src={LinkedIn} alt="Instagram QR Code" />
                    </a>
                  </section>
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={2} lg={2}>
              <h3 className="footer-col-header">{t("Download")} <br /> {t("Our Apps")}</h3>
              <ul className="footer-col-content footer-col2-section"
              >
                <li
                  className="text-decoration-none text-white cursor-pointer"
                  onClick={() => {
                    LogEvent('Footer_GooglePlay_Link')
                  }}
                >
                  <img src={QrCode} className='app-download-img-google' alt="Google Playstore" />
                </li>
              </ul>
            </Col>
            
          </Row>
        </div>
      </div>
      <Container fluid className="footer-btm-img-container">
         <div className="footer-content ">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate("/privacy.html");  
          }}
          className="footer-privacy-link"
        >
          {t('Privacy Policy')}
        </a>  
        <p className="footer-copyright-text">
          &copy; {currentYear} Animeta Agritech. All rights reserved.
        </p>
      </div>
      </Container>

    </>
  );
};

export default Footer;
