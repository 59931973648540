import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import farmActions from "../../Redux/sellerProfile/action";
import userActions from "../../Redux/user/actions";
import commonActions from "../../Redux/common/actions";
import { useForm, Controller } from "react-hook-form";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { DecryptFunction } from "../../utils/cryptoFunction";
import SellerSelectAddressModal from "./SellerSelectAddressModal";
import Navbar from "../commonComponents/Navbar/Navbar";
import Footer from "../commonComponents/footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AnimalSellerForm() {
  const { control, handleSubmit, reset, formState: { errors } } = useForm();
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [user_id, setUserProfile] = useState({})
  const dispatch = useDispatch();
  // const { control, handleSubmit,reset  } = useForm();
  const [selectedImage, setSelectedImage] = useState(null);

  const { farmList, CurrentselectedAddress } = useSelector((state) => state.farmTypeReducer);
  const { Addressloglat } = useSelector((state) => state.commonReducer);

  const handleAddressButtonClick = () => {
    setShowAddressModal(true);
  };

  const handleShowAddressModal = () => {
    setShowAddressModal(true);
  };

  useEffect(() => {
    dispatch({
      type: farmActions.GET_FARM_TYPES,
    });
  }, [dispatch]);

  useEffect(() => {
    const storedAddress = JSON.parse(localStorage.getItem("selectedAddress"));
    setSelectedAddress(storedAddress || CurrentselectedAddress);
  }, [CurrentselectedAddress]);
  



  useEffect(() => {
    if (localStorage.getItem("auth_user")) {
      const user = DecryptFunction(localStorage.getItem("auth_user"));
      dispatch({ type: userActions.GET_USER_ADDRESS, payload: {id: user} });
    }
  }, []);

  const handleAddress = (Address) => {
    setSelectedAddress(Address);
    dispatch({
      type: farmActions.SET_SELECTED_ADDRESS,
      payload: Address,
    });

    dispatch({
      type: commonActions.GET_ADDRESS_COORDINATES,
      payload: selectedAddress,
    });
    localStorage.setItem("selectedAddress", JSON.stringify(Address));
    setShowAddressModal(false);
  };


  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleClearImage = () => {
    setSelectedImage(null);
    const fileInput = document.getElementById("imageUpload");
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const onSubmit = async (data) => {
    if (localStorage.getItem("auth_user")) {
      const user = DecryptFunction(localStorage.getItem("auth_user"));
      setUserProfile(user);

      const payload = {
        user_id: user_id,
        provider_name: data.firstName,
        mobile_no: data.phoneNumber,
        farm_type_id: data.selectedFarm,
        email: data.email,
        latitude: Addressloglat?.Coordinates?.latitude,
        longitude: Addressloglat?.Coordinates?.longitude,
        address_type: selectedAddress?.address_type,
        default_address: selectedAddress?.default_address,
        address: {
          address_line1: selectedAddress.address_line1,
          address_line2: selectedAddress.address_line2,
          address_line3: selectedAddress.address_line3,
          city: selectedAddress.city,
          state: selectedAddress.state,
          country: selectedAddress.country,
          pincode: selectedAddress?.pincode,
          other_address_details: selectedAddress?.other_address_details
        }
      };

      try {
        await dispatch({
          type: farmActions.CREATE_SELLER_USER,
          payload: payload,
        });

        // Check for status code 200 and show toast
        toast.success('Form submitted successfully!');
        reset();
      } catch (error) {
        // Handle errors, if any
        console.error('Error submitting form:', error);
        toast.error('Form submission failed. Please try again.');
      }
    }
  };


  return (
    <>
      <Navbar />
      <div className="row justify-content-center align-items-center">
        <div className="card-body px-4">
          <h3 className="fw-bold mb-4 pb-2 pb-md-0 mb-md-5 customTopPadding">
            Seller Registration Form
          </h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 mb-4">
                <label htmlFor="form5" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="form5"
                  {...control.register("phoneNumber", { required: true })}
                // {...control.register("phoneNumber")}
                />
                {errors.phoneNumber && (
                  <span className="text-danger">Phone Number is required</span>
                )}
              </div>
              <div className="col-md-6 mb-4">
                <label htmlFor="form1" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="form1"
                  {...control.register("firstName", { required: true })}
                /> {errors.firstName && (
                  <span className="text-danger">Name is required</span>
                )}
              </div>


            </div>
            <div className="row">
              <div className="col-md-6 mb-4">
                <label htmlFor="chooseOption" className="form-label">
                  Select Farm
                </label>
                <Controller
                  name="selectedFarm"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <select
                      className="form-select form-control"
                      id="chooseOption"
                      {...field}
                    >
                      <option value="" disabled>
                        Select Farm
                      </option>
                      {farmList.map((farm) => (
                        <option key={farm.title} value={farm.id}>
                          {farm.title}
                        </option>
                      ))}
                    </select>
                  )}
                  rules={{ required: true }}
                />
                {errors.selectedFarm && (
                  <span className="text-danger">Select Farm is required</span>
                )}
              </div>
              <div className="col-md-6 mb-4">
                <button
                  className="btn btn-primary mb-4 me-2"
                  type="button"
                  onClick={handleAddressButtonClick}
                >
                  Show Address
                </button>
                <Col md={12} className="mb-3">
                  <label className="form-label">Address</label>
                  <div className="ms-4">
                    {selectedAddress &&
                      <div className="address-book-address mb-2 text-success">
                        <div>{`${selectedAddress?.address_line1}, ${selectedAddress?.address_line2}, ${selectedAddress?.address_line3},`}</div>
                        <div>{`${selectedAddress?.city} - ${selectedAddress?.pincode}.`}</div>
                        <div>{`${selectedAddress?.state}, ${selectedAddress?.country}`}</div>
                      </div>
                    }

                    <div className="user-rofile-select-address" onClick={handleShowAddressModal}>{selectedAddress ? "Change Address" : "Select Address"}</div>
                  </div>
                </Col>
              </div>

            </div>


            <div className="row">
              <div className="col-md-6 mb-4">
                <label htmlFor="form2" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="form4"
                  {...control.register("email", { required: true })}
                />
                {errors.email && (
                  <span className="text-danger">Email is required</span>
                )}
              </div>


              <div className="col-md-6 mb-4">
                <label htmlFor="imageUpload" className="form-label">
                  Upload Image
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="imageUpload"
                  onChange={handleImageChange}
                />
              </div>
              {selectedImage && (
                <div className="col-md-6 mb-4">
                  <label className="form-label">Chosen Image:</label>
                  <div style={{ position: 'relative' }}>
                    <img src={selectedImage} alt="Chosen" style={{ maxWidth: '100%' }} />
                    <button className="clear-button" onClick={handleClearImage}>
                      <span role="img" aria-label="clear-icon">
                        ❌
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="center text-center">
              <button
                className="btn btn-primary mb-4 me-2"
                type="submit"
              >
                Submit{handleSubmit(onSubmit)}
              </button>
              <button className="btn btn-secondary mb-4" type="button">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>

      <SellerSelectAddressModal
        showModal={showAddressModal}
        closeModal={() => setShowAddressModal(false)}
        handleAddress={handleAddress}
      />

      <Footer />
    </>
  );
}

export default AnimalSellerForm;
