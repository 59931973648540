import React from "react";
import ReactPaginate from 'react-paginate';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";

export default function Pagination({ pageCount, onPageChange, currentPage }) {

    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel={<MdOutlineArrowForwardIos />}
            onPageChange={onPageChange}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={<MdOutlineArrowBackIos />}
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            forcePage={currentPage}
            containerClassName="pagination-container"
            pageClassName="pagination-item"
            activeClassName="active"
            previousClassName="pagination-previous"
            nextClassName="pagination-next"
        />

    )
}