import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import marketActions from '../../Redux/marketPlace/action';
import { BiWorld } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { PiEye, PiShareFatFill, PiPhoneFill } from 'react-icons/pi';
import { FiFilter } from "react-icons/fi";
import { Rating } from 'react-simple-star-rating';
import { RWebShare } from 'react-web-share';
import Slider from "react-slider";
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import { FRONTEND_URL } from "../../utils/constant";
import AnimalImageGrid from "./animalImageGrid";
import ImageModal from "./imageModal";
import DisclaimerModal from "../modal/disclaimerModal";
import noprofileimg from '../../assets/images/noprofile.png';
import moment from "moment-timezone";
import { LogEvent } from "../../utils/firebase";
import farmActions from "../../Redux/sellerProfile/action";
import { logEvent } from "firebase/analytics";

export default function List() {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentLanguage, pageLoader } = useSelector(state => state.commonReducer);

    const { cattleListingByCategory, cattleListingsSelectedFilters, cattleParentCatgory } = useSelector((state) => state.MarketPlaceReducer);
    // console.log("test-001", cattleListingByCategory)
    const { recentDisease } = useSelector(state => state.farmTypeReducer);
    const [showImage, setShowImage] = useState({ isOpen: false, data: null });
    const [showDisclaimerModal, setShowDisclaimerModal] = useState({ isOpen: false, data: null });
    const [contactViewable, setContactViewable] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    var [currentimageindex, setcurrentimageindex] = useState(0)     
        
    const handleShowImagesModal = (images) => {

        if (images?.length > 0) {
            setShowImage({ isOpen: true, data: images });
        }
    };


    const handleImageClose = () => {
        setShowImage({ isOpen: false, data: null });

    }


    const handleViewSeller = (id) => {
        LogEvent('LiveStock_SellerProfile_Clicked')
        const encryptedId = EncryptFunction(id);
        if (encryptedId) {
            navigate(`/marketplace-seller?seller=${encryptedId}`);
        };
    };

    const handleRating = (rate, provider_id) => {
        const user_id = DecryptFunction(localStorage.getItem('auth_user'));
        if (user_id) {
            dispatch({
                type: marketActions.SERVICE_PROVIDER_RATINGS,
                payload: { provider_id: provider_id, user_id: user_id, rating: rate }
            });
        };
    };

    const handleAgeSliderChange = (data) => {
        let marketplace = { ...cattleListingsSelectedFilters };
        marketplace['Age'] = data;
        dispatch({ type: marketActions.CATTLE_LISTINGS_SELECTED_FILTERS, payload: marketplace });
        dispatch({ type: marketActions.CATTLE_LISTINGS_FILTER });
    };

    const handlePriceSliderChange = (data) => {
        let marketplace = { ...cattleListingsSelectedFilters };
        marketplace['PriceFilter'] = {
            Min: data[0],
            Max: data[1]
        };
        dispatch({ type: marketActions.CATTLE_LISTINGS_SELECTED_FILTERS, payload: marketplace });
        dispatch({ type: marketActions.CATTLE_LISTINGS_FILTER });
    };

    const handleClear = () => {
        dispatch({ type: marketActions.CATTLE_LISTINGS_FILTER, payload: { type: 'clear' } });
    };

    return (
        <div>
            <div className="mb-4">
                <div className="d-flex justify-content-between">
                    <div className="small-screen-filter-modal market_place_smal_screen_filter" onClick={() => setShowFilterModal(true)}>
                        <FiFilter size={20} />
                    </div>
                </div>
                {cattleListingByCategory?.length > 0 ?
                    <>
                        {cattleListingByCategory?.map((val, i) =>
                            <div key={i} className="marketplace-cattle-card-div">
                                <div className="d-flex justify-content-between maretplace-cattle-card-head">
                                    <div className="marketplace-cattle-card-col1">
                                        {/* <div className="marketplace-cattle-card-title">{val?.listing?.title} <span className="cattle_list_milk_productions_span">{`${val?.cattle?.milk_production_litres > 0 ? "(" + (val?.cattle?.milk_production_litres) + " ltr milk /day" + ")" : ""}`}</span></div> */}
                                        <div className="marketplace-cattle-card-title">{val?.listing?.title} <span
                                            className="cattle_list_milk_productions_span"
                                            style={{
                                                backgroundColor: val?.cattle?.milk_production_litres > 0 ? '#FFF9C4' : 'transparent', // light yellow background if milk production is greater than 0
                                                color: val?.cattle?.milk_production_litres > 0 ? '#dc7f92' : 'inherit' // dark grey text if milk production is greater than 0
                                            }}
                                        >
                                            {val?.cattle?.milk_production_litres > 0 && `${val?.cattle?.milk_production_litres} ltr milk /day`}
                                        </span>
                                        </div>
                                        {/* <div className="marketplace-cattle-card-title">{val?.listing?.title} <span className="cattle_list_milk_productions_span">{val?.cattle?.milk_production_litres > 0 && `${val?.cattle?.milk_production_litres} ltr milk /day`}</span></div> */}
                                        <div className="marketplace-cattle-card-place"><FaLocationDot className="pb-1" size={16} /> {val?.listing?.place || "N/A"}</div>
                                        <div className="marketplace-cattle-card-postedby"><BiWorld /> {val?.listing?.published_at ? moment(val?.listing?.published_at).fromNow() : "N/A"}</div>
                                    </div>
                                    <div className="marketplace-cattle-rate-and-view">
                                        <div className="marketplace-cattle-card-rate">₹{val?.listing?.rate}</div>
                                        <div className="maretplace-cattle-view"><PiEye className="marketplace-cattle-eye-icon" /> {val?.listing?.viewed} views</div>
                                    </div>
                                </div>
                                <AnimalImageGrid
                                    images={val?.images || []}
                                    handleShowImagesModal={handleShowImagesModal}
                                // handleShowImagesModal={() => handleShowImagesModal(val?.images, i)}
                                />
                                <div className="my-3 marketplace-cattle-age-main-div-cls">
                                    {val?.cattle?.age > 0 &&
                                        <div className="marketplace-cattle-age-div">
                                            <div className="marketplace-cattle-age-header">{t("Age")}</div>
                                            <div className="marketplace-cattle-age-value">{val?.cattle?.age} {val?.cattle?.age === 1 ? "yr" : "yrs"} old</div>
                                        </div>
                                    }
                                    {val?.cattle?.gender &&
                                        <div className="marketplace-cattle-age-div">
                                            <div className="marketplace-cattle-age-header">{t("Gender")}</div>
                                            <div className="marketplace-cattle-age-value">{val?.cattle?.gender}</div>
                                        </div>
                                    }
                                    {val?.cattle?.last_calving_date && val?.cattle?.cattle_type_id !== '11' && val?.cattle?.cattle_type_id !== '16'&& val?.cattle?.cattle_type_id !== '17'&&
                                        <div className="marketplace-cattle-age-div">
                                            <div className="marketplace-cattle-age-header">{t("Last Calving date")}</div>
                                            <div className="marketplace-cattle-age-value">{moment(val?.cattle?.last_calving_date).format('DD/MM/YYYY')}</div>
                                        </div>
                                    }


                                    {val.cattle.disease_history.map(diseaseId => {
                                        const disease = recentDisease.find(d => `${d.id}` === `${diseaseId}`);
                                        if (disease) {
                                            const languageDisease = disease.language.find(lang => lang.language_code === currentLanguage)?.disease;
                                            return (
                                                <div className="marketplace-cattle-age-div" key={diseaseId}>
                                                    <div className="marketplace-cattle-age-header">{t("Diseases")}</div>

                                                    {languageDisease || t(disease.name)}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    }).filter(Boolean)}


                                </div>
                                <div>
                                    <div className="marketplace-cattle-listing-decription">{val?.listing?.description}</div>
                                </div>
                                <div className="my-3 marketplace_cattle_grid_btn_main_div">
                                    <Row className="marketplace_cattle_grid_btn_row">
                                        <Col md={6} sm={12} className="marketplace-cattle-provider-col cursor-pointer" onClick={() => handleViewSeller(val?.listing?.provider_id)}>
                                            <img src={val?.listing?.provider?.logo || noprofileimg} alt="" className="marketplace-cattle-list-profile-img" />
                                            <div>
                                                <div className="marketplace-cattle-card-postedby-div">{t("Posted by")} : {val?.listing?.provider?.provider_name}</div>
                                                <Rating
                                                    initialValue={val?.listing?.provider?.average_rating}
                                                    className="marketplace-cattle-ratings"
                                                    onClick={(rate) => handleRating(rate, val?.listing?.provider_id)}
                                                    readonly
                                                    activeColor="#ffd700"

                                                />
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12} className="marketplace-cattle-btn-col-cls">
                                            <Button
                                                className="market_place_livestock_btnd"
                                                size="sm"
                                                onClick={() => {
                                                    LogEvent('LiveStock_Contact_Clicked')
                                                    if (!contactViewable) {
                                                        setShowDisclaimerModal({ isOpen: true, data: null });
                                                    }
                                                }}
                                            >
                                                <PiPhoneFill className="marketplace-cattle-btn-icons" /> {contactViewable ? val?.listing?.provider?.mobile_no : t("Contact")}
                                            </Button>
                                            <RWebShare
                                                data={{
                                                    text: "Like humans, flamingos make friends for life",
                                                    url: `${FRONTEND_URL}/marketplace-single?cattle=${EncryptFunction(val?.listing?.id)}`,
                                                    title: "Flamingos",
                                                }}
                                            >
                                                <Button size="sm" className="market_place_livestock_btnd" onClick={() => LogEvent('LiveStock_Share_Clicked')}><PiShareFatFill className="marketplace-cattle-btn-icons" />{t("Share")}</Button>
                                            </RWebShare>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}
                    </> :
                    <div className="no-data-found-div-cls">{t("No Data Found")}</div>
                }
            </div>

            <ImageModal

                showModal={showImage.isOpen}
                handleImageClose={handleImageClose}
                images={showImage.data}
            />
            <DisclaimerModal
                showModal={showDisclaimerModal?.isOpen}
                closeModal={() => setShowDisclaimerModal({ isOpen: false, data: null })}
                agreeClick={() => {
                    setContactViewable(true);
                    setShowDisclaimerModal({ isOpen: false, data: null });
                }}
            />
            <Modal
                show={showFilterModal}
                onHide={() => setShowFilterModal(false)}
                centered
                size="sm"
            >
                <div className="modal_close_icons" onClick={() => setShowFilterModal(false)}>
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </div>
                <div className="p-4">
                    <div className="d-flex justify-content-between market-animals-cate-filter-header">
                        <div>{t("Filter By")}</div>
                        <div className="marketplace-filter-clear-div cursor-pointer" onClick={handleClear}>{t("Clear all")}</div>
                    </div>
                    <div className="marketplace_filters_main_div">
                        <h5 className="products_list_page_filter_by_text d-flex my-4">{t("Age")} <div className="products_list_page_filter_by_text_sub_div">(In Year)</div></h5>
                        <Slider
                            className="slider mt-3"
                            value={cattleListingsSelectedFilters?.Age}
                            onChange={handleAgeSliderChange}
                            min={1}
                            max={15}
                        />
                        {cattleListingsSelectedFilters?.Age?.length > 0 &&
                            <div className="d-flex justify-content-between mt-3">
                                <span className="range-minandmax">{cattleListingsSelectedFilters?.Age[0]} {cattleListingsSelectedFilters?.Age[0] === 1 ? "yr" : "yrs"}</span>
                                <span className="range-minandmax">{cattleListingsSelectedFilters?.Age[1]} {cattleListingsSelectedFilters?.Age[0] === 1 ? "yr" : "yrs"}</span>
                            </div>
                        }
                        <h5 className="products_list_page_filter_by_text d-flex my-4">{t("Price")} <div className="products_list_page_filter_by_text_sub_div">(In INR)</div></h5>
                        <Slider
                            className="slider mt-3"
                            value={[cattleListingsSelectedFilters?.PriceFilter?.Min, cattleListingsSelectedFilters?.PriceFilter?.Max]}
                            onChange={handlePriceSliderChange}
                            min={cattleListingsSelectedFilters?.Min || 10000}
                            max={cattleListingsSelectedFilters?.Max || 50000}
                        />
                        <div className="d-flex justify-content-between mt-3">
                            <span className="range-minandmax">₹{cattleListingsSelectedFilters?.PriceFilter?.Min}</span>
                            <span className="range-minandmax">₹{cattleListingsSelectedFilters?.PriceFilter?.Max}</span>
                        </div>
                    </div>
                    <div className="text-center">
                        <Button size="sm" onClick={() => setShowFilterModal(false)}>{t("Submit")}</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}