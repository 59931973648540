import React from "react";
import Footer from "../components/commonComponents/footer";
import Navbar from '../components/commonComponents/Navbar/Navbar';
import FaqAccordion from "../components/treatments";
export default function TreatmentPage() {

    return(
        <>
            <Navbar />
            <FaqAccordion />
            <Footer />
        </>
    )
}