import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Modal, Offcanvas, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cartActions from "../../../Redux/cart/actions";
import commonActions from "../../../Redux/common/actions";
import { AiOutlineUser } from "react-icons/ai";
import { CgSearch } from "react-icons/cg";
import { FaUser } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { IoMdLogOut } from "react-icons/io";
import { RiMenu2Fill } from "react-icons/ri";
import { TfiWorld } from "react-icons/tfi";
import logo from "../../../assets/images/logo.png";
import { LogEvent } from "../../../utils/firebase";
import userActions from "../../../Redux/user/actions";
import { DecryptFunction } from "../../../utils/cryptoFunction"

const TopNavbar = () => {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
    const category = searchParams.get('category');

  const { websiteLanguages, currentLanguage } = useSelector((state) => state.commonReducer);

  const { cartProducts } = useSelector((state) => state.cartReducer);

  const [showHambargerMenu, setShowHambargerMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("auth_user")) {
      dispatch({
        type: cartActions.GET_CART_PRODUCT_LIST,
        payload: { id: localStorage.getItem("auth_user") },
      });
    }
    //eslint-disable-next-line
  }, []);

  const handleLanguageChange = (language) => {
    sessionStorage.setItem("YFLanguage", language);
    i18n.changeLanguage(language);
    let langName = websiteLanguages?.find((e) => e?.language_code === language)?.title;
    dispatch({ type: commonActions.CURRENT_LANGUAGE, payload: langName });
    if (language === 'en') { LogEvent('Topmenu_Language_En') } else if (language === 'tm') { LogEvent('Topmenu_Language_Tm') } else { LogEvent('Topmenu_Language_Mr') }
    window.location.reload();
  };

  const handleUserIconClick = () => {
    if (!localStorage.getItem("token")) {
      LogEvent('LoginOrSignup_Clicked');
      dispatch({
        type: commonActions.SET_SHOW_LOGIN_MODAL,
        payload: true,
      });
    } else {
      navigate("/user-profile?page=Profile");
    }
  };

  const handleCartClick = () => {
    LogEvent('Topmenu_ViewCart')
    if (!localStorage.getItem("token")) {
      dispatch({
        type: commonActions.SET_SHOW_LOGIN_MODAL,
        payload: true,
      });
    } else {
      navigate(`/cart?id=${localStorage.getItem("auth_user")}`);
    }
  };

  // const handleUserLogout = () => {
  //   dispatch({ type: commonActions.SHOW_LOGOUT_MODAL, payload: true });
  // };
  const handleUserLogout = () => {
    LogEvent('Logout_Clicked')
    const id = DecryptFunction(localStorage.getItem('auth_user'));
    if (id) {
      dispatch({ type: userActions.LOG_OUT_PROFILE, payload: id });
    };
  }

  return (
    <>
      <div className="topnav-menu1-main-cls">
        {/* menu for big screen */}
        <div className="topnav-big-screen-cls container-fluid d-flex justify-content-between align-items-center">
        <Link to="/" className="topnav-menu1-logo" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <img src={logo} alt="Logo" className="topnav-menu1-logo-img" style={{ marginBottom: '10px' }} />
  <div style={{marginLeft: '55px',marginTop:'-20px',fontSize:'small'}}>{t("Animeta Agritech")}</div>
</Link>


          <div className="topnav-menu2-row-cls">
            <div className="topnav-menu2-col-cls">
              <div className="topnav-menu2-link-div">
              </div>
              <div className="topnav-menu2-col-cls">
                <Dropdown className="topnav-menu-dropdown">
                  <Dropdown.Toggle
                    className={`topnav-menu-dropdown-toggle ${(pathname === "/products")
                      ? "active-menu-cls"
                      : ""
                      }`}
                    style={{ fontSize: '15px' }}
                  >
                    {t("Products")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="topnav-menu-dropdown-menu">
                    <Dropdown.Item
                      onClick={() => {
                        LogEvent('Topmenu_Herbal_Products')
                        navigate(`/products?category=herbal`);
                      }}
                      style={{ fontSize: '15px' }}
                    >
                      {t("Herbal Products")}
                    </Dropdown.Item>
                    
                    <Dropdown.Item
                      onClick={() => {
                        LogEvent('Topmenu_Feeds_Supplements')
                        navigate(`/products?category=feedsandsupplement`);
                      }}
                      style={{ fontSize: '15px' }}
                    >
                      {t("Feeds Supplements")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="topnav-menu2-col-cls">
  <Dropdown className="topnav-menu-dropdown">
    <Dropdown.Toggle
      className={`topnav-menu-dropdown-toggle ${pathname === '/treatments' || pathname === '/consultation' ? "active-menu-cls" : ""}`}
      style={{
        fontSize: '15px',
        color: pathname === '/treatments' || pathname === '/consultation' ? '#dc7f92 !important' : '#dc7f92 !important', // Pink text color for active item
        
      }}
    >
      {t("Services")}
    </Dropdown.Toggle>
    <Dropdown.Menu className="topnav-menu-dropdown-menu">
      <Dropdown.Item
        onClick={() => {
          LogEvent('Topmenu_Treatment')
          navigate("/treatments");
        }}
        style={{
          fontSize: '15px',
          color: pathname === '/treatments' ? '#dc7f92 !important' : '#dc7f92 !important', // Pink text color for active item
      
        }}
      >
        {t("Treatment")}
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          LogEvent('Topmenu_Consultation')
          navigate("/consultation");
        }}
        style={{
          fontSize: '15px',
          color: pathname === '/consultation' ? '#dc7f92' : '#000', // Pink text color for active item
          backgroundColor: 'transparent' // No background color change
        }}
      >
        {t("Consultation")}
      </Dropdown.Item>    
    </Dropdown.Menu>
  </Dropdown>
</div>



              <div className="topnav-menu2-col-cls">
                <Dropdown className="topnav-menu-dropdown">
                  <Dropdown.Toggle
                    className={`topnav-menu-dropdown-toggle ${(pathname === '/Animals' || pathname === '/service-providers')
                      ? "active-menu-cls"
                      : ""
                      }`}
                    style={{ fontSize: '15px' }}
                  >
                    {t("MarketPlace")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="topnav-menu-dropdown-menu">
                    <Dropdown.Item
                      onClick={() => {
                        LogEvent('Topmenu_Other_Services')
                        navigate("/service-providers");
                      }}
                      style={{ fontSize: '15px' }}
                    >
                      {t("Other Services")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="topnav-menu2-col-cls">
                <Dropdown className="topnav-menu-dropdown">
                  <Dropdown.Toggle
                    className={`topnav-menu-dropdown-toggle ${(pathname === '/blog' || pathname === '/videos')
                      ? "active-menu-cls"
                      : ""
                      }`}
                    style={{ fontSize: '15px' }}
                  >
                    {t("KnowledgeBase")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="topnav-menu-dropdown-menu">
                    <Dropdown.Item
                      onClick={() => {
                        LogEvent('Topmenu_Blogs')
                        navigate("/blog");
                      }}
                      style={{ fontSize: '15px' }}
                    >
                      {t("Blogs")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        LogEvent('Topmenu_Videos')
                        navigate("/videos");
                      }}
                      style={{ fontSize: '15px' }}
                    >
                      {t("Videos")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="parent-container">
                <div className="topnav-menu2-col-cls center-align" style={{ marginTop: '19px' }}>
                  <Link
                    to="/ourteam"
                    onClick={() => LogEvent('Topmenu_OurTeam')}
                    style={{ fontSize: '15px', textDecoration: 'none' }}
                    className={(pathname === '/ourteam' || pathname === '/ourdoctors') ? "active-menu-cls" : ""}
                  >
                    {t("Our Team")}
                  </Link>
                </div>
              </div>


            </div>
          </div>
          <div className="d-flex topnavbar-menu-col-3-main-div-big">
            <div>
              <FiShoppingCart
                className="cursor-pointer topnav-bs--icons-class"
                onClick={handleCartClick}
                style={{ color: "white" }}
              />
              {cartProducts?.order_items?.length > 0 && (
                <div className="cart-count-badge">
                  {cartProducts?.order_items?.length}
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
              <TfiWorld
                style={{ color: "white" }}
                className="cursor-pointer topnav-bs--icons-class pe-1"
              />
              <Dropdown size="md" className="navbar-language-dropdowm">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="navbar-language-dropdowm-toggle"
                >
                  {websiteLanguages?.find((e) => e?.language_code === sessionStorage.getItem("YFLanguage"))?.title || sessionStorage.getItem("YFLanguage")}
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-language-dropdowm-menu">
                  {websiteLanguages?.map((val, i) => (
                    <Dropdown.Item
                      href="#"
                      key={i}
                      onClick={() => handleLanguageChange(val?.language_code)}
                    >
                      {val?.title}
                      {/* {val?.language_code} */}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              {!localStorage.getItem("token") ? (
                <div onClick={handleUserIconClick} className="Custom-User-Icon">
                  <AiOutlineUser
                    style={{ color: "white" }}
                    className={`cursor-pointer topnav-bs--icons-class ${localStorage.getItem("token") && "login-user-icon"}`}
                  />
                  <span className="navbar-login-text" style={{ color: "white", fontSize: '14px', marginLeft: '5px', whiteSpace: 'nowrap' }}>
                    {t("Login | Sign up")}
                  </span>
                </div>
              ) : (
                <Dropdown size="md" className="navbar-language-dropdowm">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="navbar-language-dropdowm-toggle"
                  >
                    <AiOutlineUser className={`cursor-pointer topnav-bs--icons-class ${localStorage.getItem("token") && "login-user-icon"}`} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="navbar-language-dropdowm-menu">
                    <Dropdown.Item href="#" onClick={handleUserIconClick}>
                      <FaUser /> {t("Profile")}
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={handleUserLogout}>
                      <IoMdLogOut /> {t("Logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
        {/* menu for mobile screen */}
        <div className="d-flex justify-content-between align-items-center topnav-small-screen-cls">
          <div className="d-flex align-items-center topnav-mobile-col1">
            <RiMenu2Fill
              className="topnav-ss-menu-icon"
              onClick={() => setShowHambargerMenu(!showHambargerMenu)}
            />
            <Link href="/" className="topnav-menu1-logo">
              <img src={logo} alt="Logo" className="topnav-menu1-logo-img" />
            </Link>
          </div>
          <div className="d-flex topnavbar-mobile-icon-column">
            <div>
              <FiShoppingCart
                className="cursor-pointer topnav-bs--icons-class"
                onClick={handleCartClick}
                style={{ color: "white" }}
              />
              {cartProducts?.order_items?.length > 0 && (
                <div className="cart-count-badge">
                  {cartProducts?.order_items?.length}
                </div>
              )}
            </div>
            <div className="d-flex">
              <TfiWorld
                style={{ color: "white" }}
                className="cursor-pointer topnav-bs--icons-class pe-1"
              />
              <Dropdown size="md" className="navbar-language-dropdowm">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="navbar-language-dropdowm-toggle"
                >
                  {currentLanguage || sessionStorage.getItem("YFLanguage")}
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-language-dropdowm-menu">
                  {websiteLanguages?.map((val, i) => (
                    <Dropdown.Item
                      href="#"
                      key={i}
                      onClick={() => handleLanguageChange(val?.language_code)}
                    >
                      {val?.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div>
              {!localStorage.getItem("token") ? (
                <div onClick={handleUserIconClick} className="Custom-User-Icon">
                  <AiOutlineUser
                    style={{ color: "white" }}
                    className={`cursor-pointer topnav-bs--icons-class ${localStorage.getItem("token") && "login-user-icon"}`}
                  />
                  <span className="navbar-login-text" style={{ color: "white", fontSize: '14px', marginLeft: '5px' }}>
                    {t("Login | Sign up")}
                  </span>
                </div>
              ) : (
                <Dropdown size="md" className="navbar-language-dropdowm">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="navbar-language-dropdowm-toggle"
                  >
                    <AiOutlineUser className={`cursor-pointer topnav-bs--icons-class ${localStorage.getItem("token") && "login-user-icon"}`} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="navbar-language-dropdowm-menu">
                    <Dropdown.Item href="#" onClick={handleUserIconClick}>
                      <FaUser /> {t("Profile")}
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={handleUserLogout}>
                      <IoMdLogOut /> {t("Logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>
      <Offcanvas
        show={showHambargerMenu}
        onHide={() => setShowHambargerMenu(!showHambargerMenu)}
        backdropClassName="navbar-offcanvas"
      >
        <Offcanvas.Header closeButton className="custom-offcanvas-header">
          <Offcanvas.Title>
            <Link href="/">
              <img src={logo} alt="Logo" className="topnav-menu1-logo-img" />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="topnav-menu2-link-div">
          </div>
          <div className="topnav-menu2-link-div">
            <Link
              className={`${(pathname === "/products")
                ? "active-menu-cls"
                : ""
                }`}
            >
              {t("Products")}
            </Link>
            <ul className="navbar-offcanvas-ul">
              <li
              style={{color: category==='herbal' ? 'blue' : ''}}
                onClick={() => {
                  LogEvent('Topmenu_Herbal_Products')
                  navigate(`/products?category=herbal`);
                }}
              >
                {t("Herbal Products")}
              </li>
              <li
                onClick={() => {
                  LogEvent('Topmenu_Feeds_Supplements')
                  navigate(`/products?category=feedsandsupplement`);
                }}
              >
                {t("Feeds Supplements")}
              </li>
            </ul>
          </div>
          <div className="topnav-menu2-link-div">
            <Link
              className={`${(pathname === '/consultation' || pathname === '/consultation')
                ? "active-menu-cls"
                : ""
                }`}
            >
              {t("Services")}
            </Link>
            <ul className="navbar-offcanvas-ul">
              <li
                onClick={() => {
                  LogEvent('Topmenu_Treatment')
                  navigate("/consultation");
                }}
              >
                {t("Treatment")}
              </li>
              <li
                onClick={() => {
                  LogEvent('Topmenu_Consultation')
                  navigate("/treatments");
                }}
              >
                {t("Consultation")}
              </li>
            </ul>
          </div>
          <div className="topnav-menu2-link-div">
            <Link
              className={`${(pathname === '/Animals' || pathname === '/service-providers')
                ? "active-menu-cls"
                : ""
                }`}
            >
              {t("MarketPlace")}
            </Link>
            <ul className="navbar-offcanvas-ul">
              {/* <li
                onClick={() => {
                  LogEvent('Topmenu_Livestock_BuyorSell')
                  navigate("/Animals");
                }}
              >
                {t("LiveStock Buy/Sell")}
              </li> */}
              <li
                onClick={() => {
                  LogEvent('Topmenu_Other_Services')
                  navigate("/service-providers");
                }}
              >
                {t("Other Services")}
              </li>
            </ul>
          </div>
          <div className="topnav-menu2-link-div">
            <Link
              className={`${(pathname === '/blog' || pathname === '/videos')
                ? "active-menu-cls"
                : ""
                }`}
            >
              {t("KnowledgeBase")}
            </Link>
            <ul className="navbar-offcanvas-ul">
              <li
                onClick={() => {
                  LogEvent('Topmenu_Blogs')
                  navigate("/blog");
                }}
              >
                {t("Blogs")}
              </li>
              <li
                onClick={() => {
                  LogEvent('Topmenu_Videos')
                  navigate("/videos");
                }}
              >
                {t("Videos")}
              </li>
            </ul>
          </div>
        
          <div className="topnav-menu2-link-div center-align-items " style={{ marginTop: '20px' }}>
            <Link
              className={`${(pathname === '/ourteam' || pathname === '/ourdoctors') ? "active-menu-cls" : ""}`}
            >
              {t("About")}
            </Link>
            <ul className="navbar-offcanvas-ul">
              <li
                onClick={() => {
                  LogEvent('Topmenu_OurTeam');
                  navigate("/ourteam");
                }}
              >
                {t("Our Team")}
              </li>
             
            </ul>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
      <Modal show={showSearch} onHide={() => setShowSearch(false)} size="md">
        <div className="modal_close_icons" onClick={() => setShowSearch(false)}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
          </svg>
        </div>
        <Row>
          <Col md={12} className="my-5 text-center">
            <div className="navbar-search-div">
              <input
                className="form-control navbar-search"
                type="text"
                name="search"
                id="srch"
                placeholder={t('Search')}
              />
              <CgSearch className="navbar-search-icon" />
            </div>
            <Button className="mt-3" onClick={() => setShowSearch(false)}>
              {t("Submit")}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default TopNavbar;
