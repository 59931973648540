import HomeReducer from './Home/reducer'
import LiveStockReducer from './LiveStock/reducer'
import AuthReducer from './Home/reducer';
// import LiveStockReducer from "./LiveStock/reducer";
import MarketPlaceReducer from "./marketPlace/reducer";
import ProductListReducer from "./Products/reducer";
import cartReducer from "./cart/reducer";
import commonReducer from "./common/reducer";
import userReducer from "./user/reducer";
import couponReducer from "./coupon/reducer";
import checkoutReducer from "./checkout/reducer";
// import MarketPlaceReducer from './marketPlace/reducer';
import FarmEquipmentsReducer from'./farmEquipments/reducer';
import ShowCouponReducer from './coupon/reducer';
import OrderReducer from './order/reducer';
import AddressReducer from './address/reducer';
import farmTypeReducer from './sellerProfile/reducer';
import subscriptionReducer from './subscription/reducer';

const reducers = {
  HomeReducer,
  LiveStockReducer,
  AuthReducer,
  ProductListReducer,
  commonReducer,
  cartReducer,
  userReducer,
  MarketPlaceReducer,
  couponReducer,
  checkoutReducer,
  FarmEquipmentsReducer,
  ShowCouponReducer,
  OrderReducer,
  AddressReducer,
  farmTypeReducer,
  subscriptionReducer,
};
export default reducers;
