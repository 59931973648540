import axios from "axios";
import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";
import { API_URL } from "../../utils/constant";
import commonActions from "../common/actions";
import actions from "./action";
import farmActions from "../sellerProfile/action";

const MarketPlaceSaga = function* () {
  yield all([
    yield takeEvery(actions.GET_MARKET_PLACE_CATTLE_TYPE, CattleType),
    yield takeEvery(actions.GET_ALL_CATTLE_PRODUCTS, ShowCattleAll),

    yield takeEvery(actions.GET_SERVICE_PROVIDER_BY_CATEGORY, servicecategory),
    yield takeEvery(
      actions.GET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID,
      serviceproviderlist
    ),
    yield takeEvery(
      actions.GET_INDIVIDUAL_SERVICE_PROVIDER_FROM_THEIR_ID,
      serviceproviderindividual
    ),

    yield takeEvery(actions.GET_DIARY_CATTLE_CATEGORY, cattlecategory),
    yield takeEvery(
      actions.GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE,
      categorywiselistings
    ),

    yield takeEvery(
      actions.GET_INDIVIDUAL_LIVESTOCK_FROM_THEIR_ID,
      individuallivestock
    ),

    yield takeEvery(actions.GET_SELLER_PROFILE, sellerprofile),

    yield takeEvery(actions.GET_SELLER_LISTINGS, sellerlisting),

    yield takeEvery(actions.GET_LISTING_DETAILS, listingdetails),

    yield takeEvery(actions.UPDATE_CATTLE_LISTINGS, updatecattlelistings),

    yield takeEvery(actions.UPDATE_SELLER_PROFILE, updatesellerprofile),

    yield takeEvery(actions.GET_CATTLE_LISTING_BY_ID, getcattlelistingbyid),

    yield takeEvery(actions.GET_LISTING_STATUS, getlistingstatus),
    yield takeEvery(actions.SERVICE_PROVIDER_RATINGS, serviceProviderRatings),
    yield takeEvery(actions.CATTLE_LISTINGS_FILTER, cattleListingsFilter),
    // yield takeEvery(actions.CATTLE_LISTINGS_SET_MIN_MAX_PRICE, cattleListingsSetMinMaxPrice),
  ]);
};

const CattleType = function* () {

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/user/cattle_types`)
    );
    yield put({
      type: actions.SET_MARKET_PLACE_CATTLE_TYPE,
      payload: result?.data,
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const ShowCattleAll = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/user/cattle_listings`)
    );
    yield put({
      type: actions.SET_ALL_CATTLE_PRODUCTS,
      payload: result?.data?.data,
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
};



const getlistingstatus = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/admin/status`)
    );
    yield put({
      type: actions.SET_LISTING_STATUS,
      payload: result?.data,
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const listingdetails = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/v1/admin/cattle_listings/provider?provider_id=${payload}`
      )
    );
    yield put({
      type: actions.SET_LISTING_DETAILS,
      payload: result?.data,
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const serviceproviderindividual = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/user/servicedetail/{id}`)
    );
    yield put({
      type: actions.SET_SERVICE_PROVIDER_BY_CATEGORY,
      payload: result?.data,
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const getcattlelistingbyid = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/user/cattle_listings/${payload}`)
    );
    yield put({
      type: actions.SET_CATTLE_LISTING_BY_ID,
      payload: result?.data,
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const sellerprofile = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/admin/serviceprovider/${payload}`)
    );
    yield put({
      type: actions.SET_SELLER_PROFILE,
      payload: result?.data,
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const updatesellerprofile = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/v1/admin/serviceprovider/${payload?.id}`, payload?.data)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.code === 200 ? "success" : "failed",
        message: result?.data?.message,
      },
    });
    if (result?.data?.code === 200) {
      yield put({ type: actions.GET_SELLER_PROFILE, payload: payload?.id });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { show: false, message: null, status: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const sellerlisting = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/v1/admin/cattle_listings/provider?provider_id=${payload}`
      )
    );
    yield put({
      type: actions.SET_SELLER_LISTINGS,
      payload: result?.data,
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const updatecattlelistings = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/v1/user/cattle_listings/${payload}`)
    );
    yield put({
      type: actions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.code === 200 ? "success" : "failed",
        message: result?.data?.message,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

const individuallivestock = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/user/cattle_listings/${payload}`)
    );
    if (result?.data?.code === 200) {
      yield put({
        type: actions.SET_INDIVIDUAL_LIVESTOCK_FROM_THEIR_ID,
        payload: result?.data?.data,
      });
    }
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const servicecategory = function* () {
  yield put({ type: commonActions.PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/user/servicecategory`)
    );
    const { marketPlaceSelectedLocation } = yield select(
      (state) => state.MarketPlaceReducer
    );
    yield put({
      type: actions.MARKETPLACE_SERVICES_SELECTED_LOCATION,
      payload: {
        ...marketPlaceSelectedLocation,
        Category: result?.data?.data[1]?.category,
      },
    });
    yield put({
      type: actions.SET_SERVICE_PROVIDER_BY_CATEGORY,
      payload: result?.data,
    });
  } catch (error) {
    console.log("Error in catch", error);
  }
  yield put({ type: commonActions.PAGE_LOADER, payload: false });
};

const serviceproviderlist = function* () {
  try {
    const { marketPlaceSelectedLocation } = yield select(
      (state) => state.MarketPlaceReducer
    );
    if (marketPlaceSelectedLocation?.Category && marketPlaceSelectedLocation?.Address) {
      yield put({ type: commonActions.PAGE_LOADER, payload: true });
      let params = {};
      params["category"] = marketPlaceSelectedLocation?.Category;
      params["latitude"] = marketPlaceSelectedLocation?.Coordinates?.latitude;
      params["longitude"] = marketPlaceSelectedLocation?.Coordinates?.longitude;
      params["distance"] = marketPlaceSelectedLocation?.Distance || 30;

      const result = yield call(() =>
        axios.get(`${API_URL}/v1/user/nearest_serviceprovider`, {
          params: params,
        })
      );
      yield put({
        type: actions.MARKETPLACE_SERVICES_SELECTED_LOCATION,
        payload: {
          ...marketPlaceSelectedLocation,
          Category: params?.category,
          Distance: params?.distance,
        },
      });
      yield put({
        type: actions.SET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID,
        payload: result?.data?.data,
      });
    }
  } catch (error) {
    console.log("Error in catch", error);
  }
  yield put({ type: commonActions.PAGE_LOADER, payload: false });
};

const categorywiselistings = function* (data) {
  // const { payload } = data;
  try {
    yield put({
      type: actions.SET_CATEGORY_WISE_CATTLE_LISTTING_WITH_IMGS,
      payload: [],
    });
    const { marketPlaceSelectedLocation } = yield select(
      (state) => state.MarketPlaceReducer
    );
    if (marketPlaceSelectedLocation?.Address && marketPlaceSelectedLocation?.Category) {
      let params = {};
      params["cattle_type_id"] = marketPlaceSelectedLocation?.Category;
      params["latitude"] = marketPlaceSelectedLocation?.Coordinates?.latitude;
      params["longitude"] = marketPlaceSelectedLocation?.Coordinates?.longitude;
      const result = yield call(() =>
        axios.get(`${API_URL}/v1/user/cattle_listings`, {
          params: params
        })
      );
      yield put({
        type: actions.SET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE,
        payload: result?.data,
      });
      if (result?.data?.code === 200) {
        const { cattleListingsSelectedFilters } = yield select((state) => state.MarketPlaceReducer);
        let amountArray = result?.data?.data?.map(val => { return val?.listing?.rate });
        let selected = {
          ...cattleListingsSelectedFilters,
          PriceFilter: {
            Min: Math.min(...amountArray),
            Max: Math.max(...amountArray),
          },
          Min: Math.min(...amountArray),
          Max: Math.max(...amountArray),
          Age: [1, 15]
        }
        yield put({ type: actions.CATTLE_LISTINGS_SELECTED_FILTERS, payload: selected });
        const list = result?.data?.data?.map((val) => {
          let images = [
            { url: val?.cattle?.image_front_url || null, type: 'front' },
            { url: val?.cattle?.image_side_url || null, type: 'side' },
            { url: val?.cattle?.image_body_url || null, type: 'body' },
            { url: val?.cattle?.video_url || null, type: 'video' },
          ];
          //eslint-disable-next-line
          let cattleImages = images.filter((img) => {
            if (img.url) {
              return img;
            }
          });
          let data = { ...val, images: cattleImages }
          return data;
        });
        yield put({
          type: actions.SET_CATEGORY_WISE_CATTLE_LISTTING_WITH_IMGS,
          payload: list,
        });
        yield put({
          type: actions.SET_CATEGORYWISE_CATTLE_LISTINGS_ALL,
          payload: list,
        });
      };
    }
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const cattlecategory = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/v1/user/cattle_types`)
    );
    yield put({
      type: actions.SET_DIARY_CATTLE_CATEGORY,
      payload: result?.data,
    });
    if (result?.data?.code === 200) {
      //eslint-disable-next-line
      const categoryList = result?.data?.data?.filter((category) => {
        if (category.parent_id === 0) {
          return category;
        }
      });
      yield put({ type: actions.SET_CATTLE_PARENT_CATEGRY, payload: categoryList });
    }
    const { marketPlaceSelectedLocation } = yield select(
      (state) => state.MarketPlaceReducer
    );
    if (result?.data?.code === 200) {
      yield put({
        type: actions.MARKETPLACE_SERVICES_SELECTED_LOCATION,
        payload: {
          ...marketPlaceSelectedLocation,
          Category: result?.data?.data[0]?.id
        },
      });
      yield put({
        type: farmActions.GET_CATTLE_DISEASE,
        payload: result?.data?.data[0]?.id,
      });
    }
  } catch (error) {
    console.log("Error in catch", error);
  }
};

const serviceProviderRatings = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/v1/user/ratings`, payload)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        show: true,
        status: result?.data?.code === 200 ? "success" : "failed",
        message: result?.data?.message,
      },
    });
    yield put({ type: actions.GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE });
  } catch (err) {
    console.log(err);
  };
};

const cattleListingsFilter = function* (data) {
  const { payload } = data;

  try {
    const { categorywiseCattleListingsAll, cattleListingsSelectedFilters } = yield select(state => state.MarketPlaceReducer);
    if (payload?.type === 'clear') {
      let FinalArray = [];
      if (cattleListingsSelectedFilters?.searchedText) {
        //eslint-disable-next-line
        let searchFilter = categorywiseCattleListingsAll?.filter(val => {
          if (val?.listing?.title?.toLowerCase()?.toLowerCase()?.includes(cattleListingsSelectedFilters?.searchedText?.toLowerCase())) {
            return val;
          }
        });
        FinalArray = [...searchFilter];
      } else {
        FinalArray = [...categorywiseCattleListingsAll];
      }
      yield put({ type: actions.SET_CATEGORY_WISE_CATTLE_LISTTING_WITH_IMGS, payload: FinalArray });
      yield put({
        type: actions.CATTLE_LISTINGS_SELECTED_FILTERS,
        payload: {
          ...cattleListingsSelectedFilters,
          Age: [1, 15],
          PriceFilter: {
            Min: cattleListingsSelectedFilters?.Min,
            Max: cattleListingsSelectedFilters?.Max,
          },
        }
      })
    } else {
      //eslint-disable-next-line
      const listWithAgeFilter = categorywiseCattleListingsAll?.filter(val => {
        if (parseInt(val?.cattle?.age) >= parseInt(cattleListingsSelectedFilters?.Age[0]) && parseInt(val?.cattle?.age) <= parseInt(cattleListingsSelectedFilters?.Age[1])) {
          return val;
        }
      });
      //eslint-disable-next-line
      const listWithPriceFilter = listWithAgeFilter?.filter(val => {
        if (parseInt(val?.listing?.rate) >= parseInt(cattleListingsSelectedFilters?.PriceFilter?.Min) && parseInt(val?.listing?.rate) <= parseInt(cattleListingsSelectedFilters?.PriceFilter?.Max)) {
          return val;
        }
      });
      let FinalArray = [...listWithPriceFilter];
      if (cattleListingsSelectedFilters?.searchedText) {
        //eslint-disable-next-line
        let searchFilter = listWithPriceFilter?.filter(val => {
          if (val?.listing?.title?.toLowerCase()?.toLowerCase()?.includes(cattleListingsSelectedFilters?.searchedText?.toLowerCase())) {
            return val;
          }
        });
        FinalArray = [...searchFilter];
      }
      yield put({ type: actions.SET_CATEGORY_WISE_CATTLE_LISTTING_WITH_IMGS, payload: FinalArray });
    }
  } catch (err) {
    console.log(err);
  };
};


export default MarketPlaceSaga;
