import actions from "./action";

const initState = {
  CattleType: [],
  ShowCattle: [],
  servicecategory: [],
  serviceproviderlist: [],
  serviceproviderindividual: [],
  cattlecategory: [],
  cattleParentCatgory: [],
  marketPlaceSelectedLocation: {
    Address: null,
    Coordinates: null,
    type: null,
    Category: null,
    Distance: 30,
  },
  cattleListingsSelectedFilters: {
    Age: [1, 15],
    PriceFilter: {
      Min: null,
      Max: null
    },
    Min: null,
    Max: null,
    searchedText: null,
  },
  categorywiselistings: [],
  cattleListingByCategory: [],
  individuallivestock: [],
  selectedAnimalCategory: null,
  sellerprofile: [],
  listingdetails: [],
  showRegistrationModal: { isOpen: false, page: null },
  getcattlelistingbyid: [],
  getlistingstatus: [],
  categorywiseCattleListingsAll: [],
};

const MarketPlaceReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_MARKET_PLACE_CATTLE_TYPE: {
      return {
        ...state,
        CattleType: action.payload,
      };
    }

    case actions.SET_ALL_CATTLE_PRODUCTS: {
      return {
        ...state,
        ShowCattle: action.payload,
      };
    }

    case actions.SET_LISTING_STATUS: {
      return {
        ...state,
        getlistingstatus: action.payload,
      };
    }

    case actions.UPDATE_SELLER_PROFILE: {
      return {
        ...state,
        updatesellerprofile: action.payload,
      }
    }

    case actions.UPDATE_CATTLE_LISTINGS: {
      return {
        ...state,
        updatecattlelistings: action.payload,
      }
    }

    case actions.GET_CATTLE_LISTING_BY_ID: {
      return {
        ...state,
        getcattlelistingbyid: action.payload,
      };
    }

    case actions.SET_LISTING_DETAILS: {
      return {
        ...state,
        listingdetails: action.payload,
      };
    }

    case actions.SET_SERVICE_PROVIDER_BY_CATEGORY: {
      return {
        ...state,
        servicecategory: action.payload,
      };
    }

    case actions.SET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID: {
      return {
        ...state,
        serviceproviderlist: action.payload,
      };
    }

    case actions.SET_INDIVIDUAL_SERVICE_PROVIDER_FROM_THEIR_ID: {
      return {
        ...state,
        serviceproviderindividual: action.payload,
      };
    }

    case actions.SET_INDIVIDUAL_LIVESTOCK_FROM_THEIR_ID: {
      return {
        ...state,
        individuallivestock: action.payload,
      };
    }

    case actions.SET_DIARY_CATTLE_CATEGORY: {
      return {
        ...state,
        cattlecategory: action.payload,
      };
    }

    case actions.SET_SELLER_PROFILE: {
      return {
        ...state,
        sellerprofile: action.payload,
      };
    }

    case actions.MARKETPLACE_SERVICES_SELECTED_LOCATION:
      return {
        ...state,
        marketPlaceSelectedLocation: action.payload,
      };

    case actions.SET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE: {
      return {
        ...state,
        categorywiselistings: action.payload,
      };
    }

    case actions.SET_SELECTED_ANIMAL_CATEGORY: {
      return {
        ...state,
        selectedAnimalCategory: action.payload,
      };
    }
    case actions.SHOW_SELLER_REGISTRATION_MODAL: {
      return {
        ...state,
        showRegistrationModal: action.payload,
      };
    }
    case actions.SET_CATTLE_PARENT_CATEGRY:
      return {
        ...state,
        cattleParentCatgory: action.payload,
      };
    case actions.SET_CATEGORY_WISE_CATTLE_LISTTING_WITH_IMGS:
      return {
        ...state,
        cattleListingByCategory: action.payload,
      };
    case actions.SET_CATEGORYWISE_CATTLE_LISTINGS_ALL:
      return {
        ...state,
        categorywiseCattleListingsAll: action.payload,
      };
    case actions.CATTLE_LISTINGS_SELECTED_FILTERS:
      return {
        ...state,
        cattleListingsSelectedFilters: action.payload,
      };

    default:
      return state;
  }
};

export default MarketPlaceReducer;
