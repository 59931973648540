const cartActions = {
    GET_CART_PRODUCT_LIST: 'GET_CART_PRODUCT_LIST',
    SET_CART_PRODUCT_LIST: 'SET_CART_PRODUCT_LIST',
    ADD_PRODUCT_TO_CART: 'ADD_PRODUCT_TO_CART',
    REMOVE_PRODUCT_FROM_CART: 'REMOVE_PRODUCT_FROM_CART',
    CURRENT_ADDING_CART_PRODUCT: 'CURRENT_ADDING_CART_PRODUCT',
    EMPTY_CART:'EMPTY_CART',
    BUY_NOW_ACTION: 'BUY_NOW_ACTION',
};

export default cartActions;