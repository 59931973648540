import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaCirclePlay } from "react-icons/fa6";
import noimg from '../../assets/images/noImage.jpg';

export default function ImageModal(props) {
    const { showModal, handleImageClose,closeModal, images, index } = props;



    return (
        <Modal
            show={showModal}
            centered
            size="lg"
        >

           
             <div className="modal_close_icons" onClick={() => {
                handleImageClose();
            }}>

                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
            </div>

            {images?.map((img, i) => (
                <div key={i} className="marketplace-img-modal-div my-4">
                    {img.type === 'video' ? (
                        <video
                            src={img.url || noimg}
                            className="marketplace-modal-videos"
                            controls
                            style={ {width : "100%",
                                height: "20rem",
                                objectFit: "contain"}}
                        >
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img
                            src={img.url || noimg}
                            alt="Blog Image"
                            className="marketplace-modal-images"
                        />
                    )}
                </div>
            ))}
        </Modal>

    );
}