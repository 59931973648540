import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CCAvenue = () => {

  const navigate = useNavigate();

  const { htmlPayload } = useSelector(state => state.checkoutReducer);

  useEffect(() => {
    if (htmlPayload) {
      document.getElementById('f').submit();
    } else {
      navigate('/checkout');
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div dangerouslySetInnerHTML={{
      __html: htmlPayload,
    }} ></div>
  );
};

export default CCAvenue;