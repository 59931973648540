import userActions from "./actions";

const initialState = {
    userAddress: [],
    CattleFarmDeatils:[],
    uploadedImage: null,
    SubscriptionPlan:[],
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userActions.SET_USER_ADDRESS:
            return {
                ...state,
                userAddress: action.payload
            }
        case userActions.SET_CATTLE_FARM_DEATILS:
            return {
                ...state,
                CattleFarmDeatils: action.payload
            }
        case userActions.SET_UPLOADED_IMAGE_URL:
            return {
                ...state,
                uploadedImage: action.payload
            }
            case userActions.SET_PAYMENT_PLAN:
            return {
                ...state,
                SubscriptionPlan: action.payload
            }
        default:
            return state;
    }
};

export default userReducer;